import {memo, useMemo, useState} from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {BtnSettings} from "./BtnSettings";
import { BubbleSettings } from "./BubbleSettings";
import {LanguageSettings} from "./LanguageSettings";
import {PositionSettings} from "./PositionSettings";
import {SizeSettings} from "./SizeSettings";
import {Tooltip} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { updateModelSettings } from "redux/models";


const ChatBot = ({handleChanges}) => {

    const dispatch = useDispatch();

    const selectedModel = useSelector((state) => state.models.current);
    const oldSettings = selectedModel?.settings;
    const tarrifPlan = useSelector((state) => state.auth.user.tariff_plan);
    const isPremium = tarrifPlan.id > 1;

    const [alignment, setAlignment] = useState('bubble');
    const [watermarkEnabled, setWatermarkEnabled] = useState(
        selectedModel?.settings?.watermark_enabled ? true : false
    );

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        handleChanges({type: newAlignment});
    };

    const handleWatermarkToggle = async (e) => {
        const newValue = e.target.checked;
        setWatermarkEnabled(newValue);
        // Optionally show a loading indicator
        try {
            const params = {
                modelId: oldSettings.model_id,
                gptModel: oldSettings.gpt_model,
                contextWindowSize: oldSettings.contextWindowSizePercent,
                watermark_enabled: newValue ? 1 : 0,
            };
            // Dispatch the redux thunk similar to Monetization screen
            await dispatch(updateModelSettings(params));
            // Reflect the change in the parent state as well.
            handleChanges({ watermark: newValue });
        } catch (error) {
            console.error("Error updating watermark setting:", error);
        }
    };

    const isBtnNeeded = alignment !== 'bubble';
    const isBubbleNeeded = alignment !== 'button';

    return (
        <Grid container py={0} spacing={4}>
            <Grid item xs={12}>
                <Typography variant="describe_text" sx={{fontWeight: 500}}>Styles:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
                <ToggleButtonGroup
                    fullWidth
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <Tooltip value="bubble"
                             title="The bubble icon is the default option, positioned in the lower-right corner of your website."><ToggleButton
                        value="bubble">Bubble</ToggleButton></Tooltip>
                    <Tooltip value="button"
                             title="The additional button offers another call-to-action option for users."><ToggleButton
                        value="button">Button</ToggleButton></Tooltip>
                    <Tooltip value="both"
                             title="This setting combines the features of both the bubble icon and the additional button."><ToggleButton
                        value="both">Both</ToggleButton></Tooltip>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
                <LanguageSettings handleChanges={handleChanges}/>
            </Grid>
            {isBtnNeeded &&
                <Grid item xs={12} md={12}>
                    <BtnSettings handleChanges={handleChanges}/>
                </Grid>
            }
            {isBubbleNeeded &&
                <>
                    <Grid item xs={12}>
                        <SizeSettings handleChanges={handleChanges}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PositionSettings handleChanges={handleChanges}/>
                    </Grid>
                    <Grid item xs={12}>
                        <BubbleSettings handleChanges={handleChanges}/>
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Tooltip title={!isPremium ? "Watermark can only be disabled with a paid subscription." : ""}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={watermarkEnabled}
                                onChange={handleWatermarkToggle}
                                disabled={!isPremium}
                            />
                        }
                        label="Watermark"
                    />
                </Tooltip>
            </Grid>
        </Grid>
    )
}


export default memo(ChatBot);