import React, { useState } from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    Typography, 
    Collapse, 
    IconButton, 
    List,
    ListItem,
    ListItemText,
    Link,
    Divider,
    Paper,
    Chip,
    Grid,
    Avatar,
    Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import CodeIcon from '@mui/icons-material/Code';
import CalculateIcon from '@mui/icons-material/Calculate';
import StorageIcon from '@mui/icons-material/Storage';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import MarkdownComponent from 'components/MarkdownComponent';
import ImageIcon from '@mui/icons-material/Image';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Styles for the component
const styles = {
    toolCard: {
        marginTop: 1,
        marginBottom: 1,
        borderLeft: '4px solid',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    toolHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: '8px 16px',
    },
    toolContent: {
        padding: '0 16px 16px 16px',
    },
    searchResult: {
        marginBottom: 2,
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 1,
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }
    },
    searchTitle: {
        fontWeight: 'bold',
        color: '#1a0dab',
        fontSize: '1.05rem',
        marginBottom: 0.5
    },
    searchSnippet: {
        fontSize: '0.95rem',
        color: '#4d5156',
        marginBottom: 0.5
    },
    searchLink: {
        fontSize: '0.85rem',
        color: '#006621',
        display: 'flex',
        alignItems: 'center'
    },
    searchStats: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 2,
        padding: '8px 12px',
        borderRadius: 1,
        backgroundColor: 'rgba(0,0,0,0.03)'
    }
};

// Helper function to get tool-specific icon and color
const getToolProperties = (toolName) => {
    switch(toolName) {
        case 'generate_image':
            return { icon: <ImageIcon />, color: '#FF7043', title: 'Generated Image' };
        case 'google_search':
            return { icon: <SearchIcon />, color: '#4285F4', title: 'Google Search sources' };
        case 'calculator':
            return { icon: <CalculateIcon />, color: '#34A853', title: 'Calculator' };
        case 'code_execution':
            return { icon: <CodeIcon />, color: '#EA4335', title: 'Code Execution' };
        case 'link_content_scraper':
            return { icon: <DescriptionIcon />, color: '#FBBC05', title: 'Web Content source content' };
        case 'send_email':
            return { icon: <EmailIcon />, color: '#8E24AA', title: 'Email Confirmation' };
        default:
            return { icon: <StorageIcon />, color: '#9E9E9E', title: toolName ? toolName.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Tool Execution' };
    }
};

// Specific renderer for Google Search results
// Update the GoogleSearchResults component only
const GoogleSearchResults = ({ data }) => {
    try {
        // Handle different string formats that might come back from the API
        let parsedData;
        
        if (typeof data === 'string') {
            try {
                // First attempt: try to parse it directly (if it's already valid JSON)
                parsedData = JSON.parse(data);
            } catch (e) {
                try {
                    // Second attempt: replace single quotes with double quotes
                    const step1 = data.replace(/'/g, '"');
                    
                    // Third attempt: fix escaped slashes in URLs
                    const step2 = step1.replace(/\\"/g, '\\"')
                                       .replace(/\\\//g, '/');
                    
                    // Fourth attempt: handle unicode escape sequences
                    const step3 = step2.replace(/\\xa0/g, ' ')
                                       .replace(/\\u([0-9a-fA-F]{4})/g, 
                                           (match, code) => String.fromCharCode(parseInt(code, 16)));
                    
                    parsedData = JSON.parse(step3);
                } catch (e2) {
                    // If all parsing attempts fail, show a formatted version of the raw data
                    console.error("Failed to parse search results JSON:", e2);
                    return <WeatherSearchFallback data={data} />;
                }
            }
        } else {
            parsedData = data;
        }

        const items = parsedData.items || [];
        const query = parsedData.queries?.request?.[0]?.searchTerms || 'Unknown search';
        const totalResults = parsedData.queries?.request?.[0]?.totalResults || '0';

        if (items.length === 0) {
            return <Typography color="text.secondary">No search results found.</Typography>;
        }

        // For weather searches, use a specialized display
        if (query.toLowerCase().includes('weather')) {
            return <WeatherSearchResults items={items} query={query} totalResults={totalResults} />;
        }

        // Regular search results display
        return (
            <Box>
                <Box sx={styles.searchStats}>
                    <Typography variant="subtitle2">
                        Search query: <strong>{query}</strong>
                    </Typography>
                    <Chip 
                        size="small" 
                        label={`${parseInt(totalResults).toLocaleString()} results`} 
                        color="primary" 
                        variant="outlined"
                    />
                </Box>
                
                <Box sx={{ mt: 2 }}>
                    {items.map((item, index) => (
                        <Paper key={index} sx={styles.searchResult} elevation={1}>
                            <Link 
                                href={item.link} 
                                target="_blank" 
                                underline="hover" 
                                sx={styles.searchTitle}
                            >
                                {item.title}
                            </Link>
                            
                            <Typography sx={styles.searchSnippet}>
                                {item.snippet}
                            </Typography>
                            
                            <Box sx={styles.searchLink}>
                                <LinkIcon sx={{ fontSize: 14, mr: 0.5 }} />
                                <Typography 
                                    variant="caption" 
                                    sx={{ color: '#006621', textDecoration: 'none' }}
                                >
                                    {item.link.replace(/^https?:\/\//i, '').replace(/\\+\//g, '/')}
                                </Typography>
                            </Box>
                        </Paper>
                    ))}
                </Box>
            </Box>
        );
    } catch (error) {
        console.error("Error in GoogleSearchResults:", error);
        return <WeatherSearchFallback data={data} />;
    }
};

// Add these new components

// Specialized component for weather searches
const WeatherSearchResults = ({ items, query, totalResults }) => {
    return (
        <Box>
            <Box sx={styles.searchStats}>
                <Typography variant="subtitle2">
                    Search query used: <strong>{query}</strong>
                </Typography>
                <Chip 
                    size="small" 
                    label={`${parseInt(totalResults).toLocaleString()} results`} 
                    color="primary" 
                    variant="outlined"
                />
            </Box>
            
            <Box sx={{ mt: 2 }}>
                {items.map((item, index) => {
                    // Extract temperature from snippet if available
                    const tempMatch = item.snippet.match(/(\d+)\s*°[CF]/);
                    const temp = tempMatch ? tempMatch[0] : null;
                    
                    // Extract weather condition if available
                    const conditions = ['sunny', 'cloudy', 'rain', 'snow', 'partly', 'clear', 'fog', 'storm'];
                    const conditionMatch = conditions.find(c => 
                        item.snippet.toLowerCase().includes(c));
                    
                    return (
                        <Paper key={index} sx={{
                            ...styles.searchResult,
                            borderLeft: temp ? '4px solid #4285F4' : '1px solid #e0e0e0'
                        }} elevation={1}>
                            <Grid container spacing={2}>
                                {/* Left side: temperature if found */}
                                {temp && (
                                    <Grid item xs={2} sm={1} sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography sx={{ 
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem'
                                        }}>
                                            {temp}
                                        </Typography>
                                    </Grid>
                                )}
                                
                                {/* Right side: search result */}
                                <Grid item xs={temp ? 10 : 12} sm={temp ? 11 : 12}>
                                    <Link 
                                        href={item.link.replace(/\\+\//g, '/')} 
                                        target="_blank" 
                                        underline="hover" 
                                        sx={styles.searchTitle}
                                    >
                                        {item.title}
                                    </Link>
                                    
                                    <Typography sx={styles.searchSnippet}>
                                        {item.snippet}
                                        {conditionMatch && (
                                            <Chip 
                                                size="small"
                                                label={conditionMatch} 
                                                sx={{ ml: 1 }}
                                                color="info"
                                                variant="outlined"
                                            />
                                        )}
                                    </Typography>
                                    
                                    <Box sx={styles.searchLink}>
                                        <LinkIcon sx={{ fontSize: 14, mr: 0.5 }} />
                                        <Typography 
                                            variant="caption" 
                                            sx={{ color: '#006621', textDecoration: 'none' }}
                                        >
                                            {item.link.replace(/^https?:\/\//i, '').replace(/\\+\//g, '/')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}
            </Box>
        </Box>
    );
};

// Fallback display for when parsing fails
const WeatherSearchFallback = ({ data }) => {
    // Try to display the weather info directly from the string
    const extractInfo = (str) => {
        // Convert to string if not already
        if (typeof str !== 'string') {
            str = JSON.stringify(str);
        }
        
        // Extract useful information with regex
        const items = [];
        const titleRegex = /'title':\s*'([^']+)'/g;
        const snippetRegex = /'snippet':\s*'([^']+)'/g;
        const linkRegex = /'link':\s*'([^']+)'/g;
        
        let titleMatch;
        let snippetMatch;
        let linkMatch;
        
        while ((titleMatch = titleRegex.exec(str)) !== null) {
            items.push({ title: titleMatch[1] });
        }
        
        let i = 0;
        while ((snippetMatch = snippetRegex.exec(str)) !== null) {
            if (items[i]) {
                items[i].snippet = snippetMatch[1];
                i++;
            }
        }
        
        i = 0;
        while ((linkMatch = linkRegex.exec(str)) !== null) {
            if (items[i]) {
                items[i].link = linkMatch[1].replace(/\\+\//g, '/');
                i++;
            }
        }
        
        // Extract search terms
        const searchTermsMatch = str.match(/'searchTerms':\s*'([^']+)'/);
        const searchTerms = searchTermsMatch ? searchTermsMatch[1] : 'Unknown search';
        
        return { items, searchTerms };
    };
    
    const { items, searchTerms } = extractInfo(data);
    
    return (
        <Box>
            <Box sx={{ 
                p: 1.5, 
                mb: 2, 
                backgroundColor: '#f5f5f5',
                borderRadius: 1
            }}>
                <Typography variant="subtitle2">
                Search query used: <strong>{searchTerms}</strong>
                </Typography>
            </Box>
            
            {items.length > 0 ? (
                <Box>
                    {items.map((item, index) => (
                        <Paper key={index} sx={styles.searchResult} elevation={1}>
                            <Link href={item.link} target="_blank" sx={styles.searchLink}>
                                <Typography sx={styles.searchTitle}>
                                    {item.title}
                                </Typography>
                            </Link>
                            
                            {item.snippet && (
                                <Typography sx={styles.searchSnippet}>
                                    {item.snippet.replace(/\\xa0/g, ' ')
                                               .replace(/\\u([0-9a-fA-F]{4})/g, 
                                                   (match, code) => String.fromCharCode(parseInt(code, 16)))}
                                </Typography>
                            )}
                            
                            {item.link && (
                                <Link href={item.link} target="_blank" sx={styles.searchLink}>
                                    <LinkIcon sx={{ fontSize: 14, mr: 0.5 }} />
                                    <Typography variant="caption" sx={{ color: '#006621' }}>
                                        {item.link.replace(/^https?:\/\//i, '')}
                                    </Typography>
                                </Link>
                            )}
                        </Paper>
                    ))}
                </Box>
            ) : (
                <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
                    <Typography>Could not parse weather information properly.</Typography>
                    <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
                        Raw data contains information about {searchTerms}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

// Default renderer for unknown tool types
const DefaultToolResults = ({ data }) => {
    return <JsonDisplay data={data} />;
};

// Update the ToolResultCard component to use the new WebContentDisplay
const ToolResultCard = ({ toolCall, toolData }) => {
    const [expanded, setExpanded] = useState(false);  // Changed from true to false
    const { icon, color, title } = getToolProperties(toolCall || 'unknown');

    // Rest of the component remains the same
    const renderToolData = () => {
        if (!toolData) {
            return <Typography color="text.secondary">Processing...</Typography>;
        }
    
        switch(toolCall) {
            case 'google_search':
                return <GoogleSearchResults data={toolData} />;
            case 'link_content_scraper':
                return <WebContentDisplay data={toolData} />;
            case 'generate_image':
                return <ImageGenerationDisplay data={toolData} />;
            case 'send_email':
                return <EmailToolDisplay data={toolData} />;
            default:
                return <DefaultToolResults data={toolData} />;
        }
    };

    return (
        <Card sx={{ 
            ...styles.toolCard, 
            borderLeftColor: color,
            mt: 2,
            mb: 2,
            border: '1px solid rgba(0,0,0,0.1)'
        }}>
            <Box 
                sx={styles.toolHeader} 
                onClick={() => setExpanded(!expanded)}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: color, width: 28, height: 28 }}>
                        {icon}
                    </Avatar>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {title || 'Unknown Tool'}
                    </Typography>
                </Box>
                <IconButton size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            
            <Collapse in={expanded}>
                <CardContent sx={styles.toolContent}>
                    {renderToolData()}
                </CardContent>
            </Collapse>
        </Card>
    );
};

// Helper function to extract content from data in any format
const extractContent = (data) => {
    if (!data) return "";
    
    // If it's a string, try to extract content from it
    if (typeof data === 'string') {
        try {
            // Try to parse as JSON
            const parsed = JSON.parse(data);
            
            // If there's a result property, use that
            if (parsed && parsed.result) {
                return typeof parsed.result === 'string' 
                    ? parsed.result 
                    : JSON.stringify(parsed.result, null, 2);
            }
            
            // If no result property found, return the whole parsed object as string
            return JSON.stringify(parsed, null, 2);
        } catch (e) {
            console.log("Error parsing JSON:", e.message);
            
            // If it's not valid JSON, try more advanced extraction methods
            try {
                // Find the position of "result": or 'result':
                const resultKeyMatch = data.match(/['"]result['"][\s]*:[\s]*/);
                
                if (resultKeyMatch && resultKeyMatch.index !== undefined) {
                    // Get string starting from after "result":
                    const startPos = resultKeyMatch.index + resultKeyMatch[0].length;
                    const restOfString = data.substring(startPos);
                    
                    // Check if the next character is a quote (string value)
                    if (restOfString.charAt(0) === '"' || restOfString.charAt(0) === "'") {
                        // For string values, find the closing quote
                        const quoteChar = restOfString.charAt(0);
                        let endPos = 1;  // Start after the opening quote
                        let escaped = false;
                        
                        while (endPos < restOfString.length) {
                            if (restOfString.charAt(endPos) === '\\') {
                                escaped = !escaped;  // Toggle escape status
                                endPos++;
                                continue;
                            }
                            
                            if (restOfString.charAt(endPos) === quoteChar && !escaped) {
                                break;  // Found the closing quote
                            }
                            
                            escaped = false;  // Reset escape status if not a backslash
                            endPos++;
                        }
                        
                        // Extract the string value
                        const extractedValue = restOfString.substring(1, endPos);
                        return extractedValue.replace(/\\n/g, '\n').replace(/\\"/g, '"').replace(/\\'/g, "'");
                    }
                    // Check if the next character is { (object)
                    else if (restOfString.charAt(0) === '{') {
                        // For object values, use bracket counting to find the closing }
                        let bracketCount = 1;
                        let endPos = 1;  // Start after the opening {
                        
                        while (endPos < restOfString.length && bracketCount > 0) {
                            if (restOfString.charAt(endPos) === '{') bracketCount++;
                            if (restOfString.charAt(endPos) === '}') bracketCount--;
                            endPos++;
                        }
                        
                        if (bracketCount === 0) {
                            return restOfString.substring(0, endPos);
                        }
                    }
                }
            } catch (extractErr) {
                console.error("Error extracting result:", extractErr);
            }
            
            // If all extraction methods fail, return the original string
            return data;
        }
    }
    
    // If it's an object with a result property, use that
    if (data && data.result) {
        return typeof data.result === 'string' 
            ? data.result 
            : JSON.stringify(data.result, null, 2);
    }
    
    // Otherwise stringify the whole object
    return JSON.stringify(data, null, 2);
};

// Add this new component for web content display
const WebContentDisplay = ({ data }) => {
    try {
        // Extract content from the data regardless of format
        let content = extractContent(data);
        
        // Format and section the content if it's a weather report
        if (content.includes('Current Weather') || 
            (content.toLowerCase().includes('weather') && 
             (content.includes('°F') || content.includes('°C')))) {
            return <WeatherContentDisplay content={content} />;
        }
        
        // General web content formatting with Markdown
        return <GeneralWebContentDisplay content={content} />;
    } catch (error) {
        console.error("Error in WebContentDisplay:", error);
        return (
            <Box sx={{ p: 2, bgcolor: '#ffebee', borderRadius: 1 }}>
                <Typography color="error">Error parsing web content: {error.message}</Typography>
                <Typography variant="caption">Raw data is available but couldn't be formatted properly.</Typography>
            </Box>
        );
    }
};

// Weather-specific content display
const WeatherContentDisplay = ({ content }) => {
    // Extract key weather information using regex
    const tempRegex = /(-?\d+)°(?:C|F)/g;
    const conditionWords = ['sunny', 'cloudy', 'rain', 'snow', 'partly', 'clear', 'fog', 'storm', 'flurries'];
    
    // Extract temperature
    let tempMatch;
    const temps = [];
    while ((tempMatch = tempRegex.exec(content)) !== null) {
        temps.push(tempMatch[0]);
    }
    
    // Extract location
    const locationMatch = content.match(/([A-Za-z\s]+),\s*([A-Za-z]{2})/);
    const location = locationMatch ? locationMatch[0] : "Location unavailable";
    
    // Extract weather condition
    let condition = "Condition unavailable";
    for (const word of conditionWords) {
        if (content.toLowerCase().includes(word)) {
            // Get the sentence or phrase containing the condition
            const sentences = content.split(/\.\s+|\n+/);
            for (const sentence of sentences) {
                if (sentence.toLowerCase().includes(word)) {
                    condition = sentence.trim();
                    break;
                }
            }
            break;
        }
    }
    
    // Format content into sections
    const sections = content.split(/\n{2,}/);
    
    return (
        <Box>
            {/* Header with key information */}
            <Paper sx={{ p: 2, mb: 2, bgcolor: '#e1f5fe', borderLeft: '4px solid #29b6f6' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{location} Weather</Typography>
                {temps.length > 0 && (
                    <Typography variant="h5" sx={{ my: 1, fontWeight: 'bold' }}>
                        Temperature: {temps[0]}
                    </Typography>
                )}
                <Typography>{condition}</Typography>
            </Paper>
            
            {/* Main content in formatted sections */}
            <Box sx={{ mt: 2 }}>
                {sections.map((section, idx) => {
                    // Skip empty sections
                    if (!section.trim()) return null;
                    
                    // Check if this is a forecast section
                    const isHourlyForecast = section.includes('Hourly') || 
                                            (section.includes('pm') && section.includes('Feels'));
                    
                    // Check if this is a details section
                    const isDetails = section.includes('Observations') || 
                                      section.includes('Humidity') ||
                                      section.includes('Wind') ||
                                      section.includes('Pressure');
                    
                    // Apply different styling based on section content
                    return (
                        <Box 
                            key={idx} 
                            sx={{ 
                                mb: 2, 
                                p: 1.5, 
                                borderRadius: 1,
                                ...(isHourlyForecast ? { bgcolor: '#f1f8e9', borderLeft: '3px solid #7cb342' } : {}),
                                ...(isDetails ? { bgcolor: '#fff8e1', borderLeft: '3px solid #ffb300' } : {})
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    whiteSpace: 'pre-wrap', 
                                    fontFamily: isHourlyForecast ? 'monospace' : 'inherit',
                                    fontSize: isHourlyForecast ? '0.9rem' : 'inherit'
                                }}
                            >
                                {section}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

// Convert text content to markdown format
const formatToMarkdown = (content) => {
    if (!content) return '';
    
    // Split content into sections
    const sections = content.split(/\n{2,}/);
    let markdown = '';
    
    sections.forEach((section) => {
        const trimmedSection = section.trim();
        if (!trimmedSection) return;
        
        // Check if this looks like a heading
        if (trimmedSection.length < 100 && 
            (trimmedSection.toUpperCase() === trimmedSection || 
             !trimmedSection.includes(' ') ||
             trimmedSection.endsWith(':'))) {
            markdown += `## ${trimmedSection}\n\n`;
        } 
        // Check for list-like content
        else if (trimmedSection.split('\n').some(line => line.match(/^\s*[\d-•*]\s+/))) {
            markdown += trimmedSection.split('\n')
                .map(line => {
                    const listMatch = line.match(/^(\s*)([\d-•*]+)(\s+)(.*)$/);
                    if (listMatch) {
                        return `${listMatch[1]}* ${listMatch[4]}`;
                    }
                    return line;
                })
                .join('\n') + '\n\n';
        } 
        // Regular paragraph
        else {
            markdown += `${trimmedSection}\n\n`;
        }
    });
    
    return markdown;
};

// General web content display
const GeneralWebContentDisplay = ({ content }) => {
    console.log("Web content:", content);
    const [showRaw, setShowRaw] = useState(false);
    
    // Import ReactMarkdown and required plugins from the parent component
    const ReactMarkdown = React.lazy(() => import('react-markdown'));
    
    // Convert content to a markdown-friendly format
    const markdownContent = formatToMarkdown(content);
    
    return (
        <Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                <Button 
                    size="small" 
                    variant="text" 
                    onClick={() => setShowRaw(!showRaw)}
                    startIcon={showRaw ? <CodeIcon /> : <DescriptionIcon />}
                >
                    {showRaw ? "Show Formatted" : "Show Raw"}
                </Button>
            </Box> */}
            
            {/* {showRaw ? ( */}
                <Box sx={{ 
                    maxHeight: '500px',
                    overflow: 'auto',
                    backgroundColor: 'rgba(0,0,0,0.03)',
                    padding: 2,
                    borderRadius: 1,
                    fontFamily: 'monospace',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                }}>
                    {content}
                </Box>
            {/* ) : ( */}
                {/* <Paper 
                    elevation={0} 
                    sx={{ 
                        padding: 2, 
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: 1,
                        '& a': { color: 'primary.main' },
                        '& img': { maxWidth: '100%' },
                        '& h1, & h2, & h3, & h4, & h5, & h6': { 
                            borderBottom: '1px solid #eaecef',
                            paddingBottom: 0.5,
                            marginTop: 2,
                            marginBottom: 1,
                        },
                        '& ul, & ol': { paddingLeft: 3 },
                        '& li': { marginBottom: 0.5 },
                        '& blockquote': {
                            borderLeft: '4px solid #ddd',
                            paddingLeft: 2,
                            margin: 0,
                            color: 'text.secondary'
                        }
                    }}
                >
                    <React.Suspense fallback={<Typography>Loading content...</Typography>}>
                        <MarkdownDisplay content={markdownContent} />
                    </React.Suspense>
                </Paper> */}
            {/* )} */}
        </Box>
    );
};

// Add a component for displaying JSON in a nice format
const JsonDisplay = ({ data }) => {
    // Try to parse the data if it's a string
    let parsedData;
    try {
        if (typeof data === 'string') {
            parsedData = JSON.parse(data.replace(/'/g, '"'));
        } else {
            parsedData = data;
        }
    } catch (e) {
        // If parsing fails, use the original data
        parsedData = data;
    }

    // Format the JSON string with indentation
    const formatJson = (obj) => {
        try {
            return JSON.stringify(obj, null, 2);
        } catch (e) {
            return typeof obj === 'string' ? obj : String(obj);
        }
    };

    // Create a highlighted JSON display
    const formattedJson = formatJson(parsedData);
    
    // Add syntax highlighting
    const highlightJson = (jsonString) => {
        // Simple JSON syntax highlighting
        return jsonString
            .replace(/"([^"]+)":/g, '<span style="color: #7B1FA2">"$1"</span>:')  // Keys in purple
            .replace(/"([^"]+)"/g, '<span style="color: #1976D2">"$1"</span>')    // Strings in blue
            .replace(/\b(true|false)\b/g, '<span style="color: #E53935">$1</span>')  // Booleans in red
            .replace(/\b(\d+)\b/g, '<span style="color: #2E7D32">$1</span>');      // Numbers in green
    };

    return (
        <Box sx={{ 
            p: 2, 
            bgcolor: '#FAFAFA', 
            borderRadius: 1, 
            border: '1px solid #EEEEEE',
            overflow: 'auto',
            maxHeight: '400px'
        }}>
            <pre 
                style={{ 
                    margin: 0, 
                    fontFamily: '"Consolas", "Monaco", monospace', 
                    fontSize: '0.9rem',
                    overflow: 'visible',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    lineHeight: 1.5
                }}
                dangerouslySetInnerHTML={{ __html: highlightJson(formattedJson) }}
            />
        </Box>
    );
};

// Add a component for displaying email tool results
const EmailToolDisplay = ({ data }) => {
    let emailData;
    
    try {
        // Parse the data if needed
        if (typeof data === 'string') {
            try {
                emailData = JSON.parse(data.replace(/'/g, '"'));
            } catch (e) {
                // Try to extract using regex if JSON parsing fails
                const statusMatch = data.match(/'status':\s*'([^']+)'/);
                const messageMatch = data.match(/'message':\s*'([^']+)'/);
                
                emailData = {
                    status: statusMatch ? statusMatch[1] : 'unknown',
                    data: {
                        message: messageMatch ? messageMatch[1] : 'Email processing completed'
                    }
                };
            }
        } else {
            emailData = data;
        }
        
        // Extract status and message
        const status = emailData?.status || emailData?.result?.status || 'unknown';
        const message = emailData?.data?.message || emailData?.result?.data?.message || 'No details available';
        const isSuccess = status.toLowerCase() === 'success';
        
        // Extract message ID if available
        const msgIdMatch = message.match(/msg_id:\s*([a-zA-Z0-9-]+)/);
        const messageId = msgIdMatch ? msgIdMatch[1] : null;
        
        return (
            <Paper 
                elevation={0} 
                sx={{ 
                    p: 3, 
                    bgcolor: isSuccess ? '#E8F5E9' : '#FFEBEE',
                    borderRadius: 2,
                    border: `1px solid ${isSuccess ? '#A5D6A7' : '#FFCDD2'}`
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {isSuccess ? (
                        <CheckCircleIcon color="success" sx={{ fontSize: 36, mr: 2 }} />
                    ) : (
                        <ErrorIcon color="error" sx={{ fontSize: 36, mr: 2 }} />
                    )}
                    <Typography variant="h6">
                        {isSuccess ? 'Email Sent Successfully' : 'Email Sending Failed'}
                    </Typography>
                </Box>
                
                <Divider sx={{ my: 2 }} />
                
                <Box sx={{ pl: 1 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Status: <Chip 
                            label={status} 
                            color={isSuccess ? "success" : "error"}
                            size="small"
                            variant="outlined"
                        />
                    </Typography>
                    
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        {message}
                    </Typography>
                    
                    {messageId && (
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                bgcolor: 'rgba(0,0,0,0.04)', 
                                p: 1, 
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                mt: 2
                            }}
                        >
                            Message ID: {messageId}
                        </Typography>
                    )}
                </Box>
                
                {/* <Box sx={{ mt: 3, textAlign: 'right' }}>
                    <Button 
                        variant="text" 
                        size="small" 
                        onClick={() => 
                            // Replace the raw default data as a JSON string
                            navigator.clipboard.writeText(JSON.stringify(emailData, null, 2))
                        }
                        startIcon={<CodeIcon />}
                    >
                        View Raw Data
                    </Button>
                </Box> */}
            </Paper>
        );
    } catch (error) {
        console.error("Error in EmailToolDisplay:", error);
        return (
            <Box sx={{ p: 2, bgcolor: '#ffebee', borderRadius: 1 }}>
                <Typography color="error">Error parsing email data: {error.message}</Typography>
                <Typography variant="caption">Raw data is available but couldn't be formatted properly.</Typography>
            </Box>
        );
    }
};

// Add the ImageGenerationDisplay component that was missing
const ImageGenerationDisplay = ({ data }) => {
    try {
        // Extract the image URL from the data
        let imageUrl = '';
        let parsed;

        if (typeof data === 'string') {
            try {
                // First try to parse the string as JSON
                parsed = JSON.parse(data);
            } catch (e) {
                // If that fails, try to clean up and parse
                const cleanData = data.replace(/'/g, '"').replace(/\\n/g, '\n');
                try {
                    parsed = JSON.parse(cleanData);
                } catch (e2) {
                    // If all parsing fails, try to extract the URL directly using regex
                    const urlMatch = data.match(/(https?:\/\/[^\s"']+\.(?:jpg|jpeg|png|gif|webp)(?:[^\s"']*)?)/i);
                    if (urlMatch) {
                        imageUrl = urlMatch[0];
                    } else {
                        throw new Error("Unable to extract image URL");
                    }
                }
            }
        } else {
            parsed = data;
        }

        // If we parsed the data, extract the URL from the result field
        if (parsed && !imageUrl) {
            if (parsed.result) {
                const urlMatch = parsed.result.match(/(https?:\/\/[^\s"']+\.(?:jpg|jpeg|png|gif|webp)(?:[^\s"']*)?)/i);
                if (urlMatch) {
                    imageUrl = urlMatch[0];
                }
            } else if (parsed.image_url) {
                imageUrl = parsed.image_url;
            }
        }

        if (!imageUrl) {
            throw new Error("No image URL found in the response");
        }

        return (
            <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" color="text.secondary">
                        Generated Image
                    </Typography>
                </Box>
                
                <Paper 
                    elevation={2} 
                    sx={{ 
                        p: 1, 
                        maxWidth: '100%',
                        display: 'inline-block',
                        backgroundColor: '#f8f9fa',
                        borderRadius: 2,
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        component="img"
                        src={imageUrl}
                        alt="AI Generated Image"
                        sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            borderRadius: 1,
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                        }}
                        loading="lazy"
                    />
                </Paper>
                
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button 
                        variant="outlined" 
                        size="small"
                        href={imageUrl}
                        target="_blank"
                        rel="noopener"
                        startIcon={<OpenInNewIcon />}
                    >
                        Open Original
                    </Button>
                </Box>
            </Box>
        );
    } catch (error) {
        console.error("Error in ImageGenerationDisplay:", error);
        return (
            <Box sx={{ p: 2, bgcolor: '#fff3e0', borderRadius: 1 }}>
                <Typography color="warning.main">
                    Unable to display the generated image. Error: {error.message}
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                    Raw data: {typeof data === 'string' ? data.substring(0, 100) + '...' : JSON.stringify(data).substring(0, 100) + '...'}
                </Typography>
            </Box>
        );
    }
};

// Simple component to display markdown content
const MarkdownDisplay = ({ content }) => {
    // Use the parent project's MarkdownComponent if available, otherwise use a simpler implementation
    if (typeof MarkdownComponent !== 'undefined') {
        return <MarkdownComponent source={content} />;
    }

    // Fallback to a simpler rendering approach with basic heading and paragraph detection
    const sections = content.split('\n\n').filter(section => section.trim());
    
    return (
        <Box>
            {sections.map((section, idx) => {
                // Format headings and paragraphs
                if (section.startsWith('# ')) {
                    return <Typography key={idx} variant="h4">{section.substring(2)}</Typography>;
                } else if (section.startsWith('## ')) {
                    return <Typography key={idx} variant="h5">{section.substring(3)}</Typography>;
                } else if (section.startsWith('### ')) {
                    return <Typography key={idx} variant="h6">{section.substring(4)}</Typography>;
                } else if (section.match(/^\s*[\d-•*]\s+/)) {
                    return (
                        <Box key={idx} sx={{ pl: 2, borderLeft: '2px solid #e0e0e0', my: 1 }}>
                            {section.split('\n').map((line, i) => (
                                <Typography key={i} paragraph sx={{ mb: 0.5 }}>{line}</Typography>
                            ))}
                        </Box>
                    );
                } else {
                    return <Typography key={idx} paragraph>{section}</Typography>;
                }
            })}
        </Box>
    );
};

// Main component that handles the array of tool results
const ToolExecutionDisplay = ({ toolsStack }) => {
    console.log('ToolExecutionDisplay render with toolsStack:', toolsStack);
    
    // If we received no data or empty array
    if (!toolsStack || toolsStack.length === 0) {
        return null;
    }
    
    // Filter out items without tool_data
    const filteredToolsStack = Array.isArray(toolsStack) 
        ? toolsStack.filter(item => item.tool_data) 
        : toolsStack.tool_data ? [toolsStack] : [];
    
    if (filteredToolsStack.length === 0) {
        return null;
    }
    
    return (
        <Box>
            {filteredToolsStack.map((item, index) => (
                <ToolResultCard 
                    key={index}
                    toolCall={item.tool_call} 
                    toolData={item.tool_data} 
                />
            ))}
        </Box>
    );
};

export default ToolExecutionDisplay;