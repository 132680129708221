import classnames from 'classnames';
import GeneralTooltip from 'components/tooltip/GeneralTooltip';
import ChromeExtension from 'components/icons/ChromeExtensionIcon';
import styles from './extensionCta.module.css';

const ExtensionCta = ({ widesidebar }) => {
  const wrapperClassNames = classnames(styles.ctaWrapper, widesidebar && styles.wrapperWide);
  const ctaInClassNames = classnames(styles.ctaIn, widesidebar && styles.ctaInWide);
  const badgeClassNames = classnames(styles.badge, widesidebar && styles.badgeWide);

  return (
    <div className={wrapperClassNames}>
      <GeneralTooltip
        placement='top'
        title={'Discover our Chrome extension for an enhanced browsing experience.'}
      >

        <a 
          href="https://chrome.google.com/webstore/detail/younet-personalized-ai-to/ahabdciobjaamioehoaifaoabkimhegj"
          target="_blank"
          className={styles.cta}
          id="chrome-ext"
        >
          <span className={badgeClassNames}>New</span>
          <span className={ctaInClassNames}>
            <ChromeExtension className={styles.icon} />
            {widesidebar && (
              <span className={styles.textBlock}>
                <span className={styles.topText}>Available in the</span>
                <span className={styles.bottomText}>Chrome Web Store</span>
              </span>
            )}
          </span>
        </a>
      </GeneralTooltip>
    </div>
  )
}

export default ExtensionCta;
