import React, {useState, useEffect, useCallback} from "react";
import {
    Box,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    Skeleton,
    Button,
    MenuItem,
    Menu,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, Badge, Avatar,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {getInitials} from "utils/prepareNames";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessChecker, {PlanChecker} from "components/security/AccessChecker";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import MenuIcons from "components/icons/menuIcons";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import api from "_services/api";
import {interactWithUser} from "redux/status";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import {deleteModel, setSelectedModelType, removeHints} from "redux/models";
import {deleteAllShared} from "redux/sharedMessages";
import styles from '../../parent-menu.module.css';
import classnames from 'classnames';
import {changeModel, changeModelAction} from "_actions/modelActions";
import ChatMenu from "components/menus/helperMenu/ChatMenu";
import ShareModelListBtn from "./ShareModelListBtn";
import SharedModels from 'components/menus/SharedModels';
import brainToolsLabels from "mappings/languages/en/brainToolsLabels";
import {sendUIEvent} from "../../../../../events/analytics/google";


const navigationMenuItems = brainToolsLabels.labels.navigationMenuItems;

const ListMenu = ({
                      userId,
                      smallScreen,
                      widesidebar,
                      widesidebarChange,
                      setShareModelConfig,
                      mobilebarChange,
                  }) => {
    const [modelsMenuOpen, setModelsMenuOpen] = useState(false);
    const [brainMenuOpen, setBrainMenuOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [mobileHelperShow, setMobileHelperShow] = useState(true);
    const [loading, setLoading] = useState(false);
    //const [moreAppsOpen, setMoreAppsOpen] = useState(false);
    //   const [shareModelConfig, setShareModelConfig] = useState({
    //     isExpanded: false,
    //     model: null,
    //   });
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const modelsOpen = Boolean(anchorEl);
    const [isNewChat, setIsNewChat] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const {models} = useSelector((state) => ({
        models: state.models,
    }), shallowEqual);

    const navigate = useNavigate();

    const handleModelChange = (e, modelId) => {
        e.preventDefault();
        dispatch(setSelectedModelType({modelType: 'default', data: null, loading: false}));
        changeModelAction(dispatch, modelId);
        // dispatch(changeModel(modelId));
        let navigateLink = "/playground";

        navigate(navigateLink);
    };
    const handleModelsClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleModelsClose = () => {
        setAnchorEl(null);
    };
    const deleteActions = (closeDialog = false) => {
        handleModelsClose();
        setModelsMenuOpen(false);
        if (closeDialog) {
            setIsDialogOpen(false);
        }
    }
    const handleManageModelClick = (e) => {
        e.stopPropagation();
        navigate("/models");
        deleteActions();
    };
    const handleModelEdit = (e, id) => {
        e.stopPropagation();
        handleMobilebarChange()
        navigate(`/models/${id}`);
        deleteActions();
    };
    const handleModelDelete = (e, id) => {
        e.stopPropagation();
        handleDeleteModel(id);
    };
    const handleChangeMobileHelperShow = useCallback((e) => {
        setMobileHelperShow(e);
    }, []);
    const handleDeleteModel = async (id) => {
        setLoading(true);
        await api
            .delete(`/model/${id}`)
            .then((r) => {

                if (r.data?.message === 'model.task.delete.created') {
                    const endDate = moment(r?.data?.date?.date).local().add(1, 'months').format('YYYY-MM-DD');
                    dispatch(
                        interactWithUser({
                            message: `Model will be deleted ${endDate}`,
                            type: "info",
                        })
                    );
                } else {
                    dispatch(
                        interactWithUser({
                            message: "Model was deleted",
                            type: "info",
                        })
                    );
                }

                dispatch(deleteModel({id}));
                setLoading(false);
                deleteActions(true);
                if (location.pathname === `/models/${id}`) {
                    navigate('/playground');
                }
            })
            .catch((error) => {
                setLoading(false);
                deleteActions(true);
            });
    };

    // TODO: move to a helper
    const fetchSharedData = async (modelHash) => {
        let params = {
            modelHash,
        };
        dispatch(setSelectedModelType({modelType: 'shared', data: null, loading: true}));

        try {
            const response = await api.post('/model/share/data', params);

            if (response.code >= 400) {
                // setDataLoading(false);
                // setChatNotExist(true);
                return;
            }

            const messages = response.messages || [];
            const model = response.chat?.model || {};
            const ownerInfo = response.owner || {};
            const ownerInnitial = ownerInfo.fullName || '';

            if (ownerInnitial !== '') {
                ownerInfo.initial = getInitials(ownerInnitial);
            }

            // dispatch(addMessages({ type: 'shared', messages }));
            // setModelInitialsData({ avatar: model.avatar, name: model.name });
            const data = {
                model: model,
                messages: messages,
                info: {
                    // chat_id: null,
                    chat_id: response.chat.id,
                    guest_id: response.chat.guest_id,
                    modelName: model.name,
                    modelWebsite: model.modelWebsite,
                    modelId: model.id,
                },
                ownerInfo: ownerInfo,
            };
            dispatch(setSelectedModelType({modelType: 'shared', data: {...data, messages: []}, loading: false}));
        } catch (error) {
            // setDataLoading(false);
        }
    }

    const handleMobilebarChange = () => {
        if (smallScreen) {
            mobilebarChange();
        }
    };
    useEffect(() => {
        if (smallScreen) {
            widesidebarChange(false);
        }
        if (!widesidebar) {
            setBrainMenuOpen(false);
            //setMoreAppsOpen(false);
        }
    }, [widesidebar, smallScreen, widesidebarChange]);

    useEffect(() => {
        const pathname = location.pathname.split("/")[1];
        if (pathname === "training") {
            setBrainMenuOpen(true);
        } else setBrainMenuOpen(false);
        // apps.forEach((app) => {
        //   if (storedPage === app.exp_text) {
        //     setMoreAppsOpen(true);
        //   }
        //});
    }, [location.pathname]);

    if (models.loading || (!Boolean(models.selectedModel.data) && models.list.length === 0)) {
        return <Skeleton variant="h2"/>;
    }


    const ListModelMenu = ({mod, userId}) => {
        return (
            <Grid container>
                <MenuItem
                    divider
                    sx={{fontSize: 14}}
                    onClose={handleModelsClose}
                    onClick={(e) => {
                        handleModelChange(e, mod.id);
                        handleMobilebarChange();
                    }}
                    id="model-setting-edit"
                >
                    <Grid
                        container
                        width={280}
                        py={1}
                        className={classnames(styles.menuWrapper, !isMobile && styles.wideMenuWrapper)}
                        sx={{justifyContent: 'space-between'}}
                    >
                        <Grid
                            item
                            xs={8}
                            pr={3}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                            }}
                        >
              <span
                  style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                  }}
              >
                {mod.name}
              </span>
                        </Grid>
                        <AccessChecker
                            currentPermissions={mod.access}
                            requiredPermissions={["write"]}
                        >
                            <Grid
                                item
                                xs={4}
                                className={classnames(styles.menuItem, mod.id === models.current?.id && styles.activeMenuItem)}
                            >
                                <IconButton
                                    sx={{width: "30px", height: "30px"}}
                                    onClick={(e) => {
                                        handleModelEdit(e, mod.id);
                                    }}
                                >
                                    <SettingsIcon fontSize="small"/>
                                </IconButton>
                                <IconButton
                                    sx={{width: "30px", height: "30px"}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedModelId(mod.id);
                                        setIsDialogOpen(true);
                                    }}
                                >
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            </Grid>
                        </AccessChecker>
                        {userId !== mod.user_id && (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <Typography sx={{fontSize: 12, color: "#00000066"}}>
                                    Linked
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </MenuItem>
            </Grid>
        );
    };

    return (
        <>
            <List className={styles.list}>
                <ListItemButton
                    sx={{pl: 6, pb: 3, borderBottom: "1px solid #ededf5"}}
                    onClick={(e) => {
                        handleModelsClick(e);
                        setModelsMenuOpen(!modelsMenuOpen);
                        if (!modelsMenuOpen) {
                            sendUIEvent({name: `select_models_nav_click`});
                        }
                    }}
                    id={models.selectedModel.type === 'shared' ? "shared-model-list-selector" : "model-list-selector"}
                >
                    <ListItemIcon sx={{minWidth: "40px"}}>
                        <Avatar 
                          src={
                            models.selectedModel.type === 'monetization' ? models.selectedModel.data?.avatar
                              : models.selectedModel.type === 'shared' ? models.selectedModel.data?.model?.avatar
                              : models?.current?.avatar
                          } 
                          sx={{
                            width: {xs: '26px', md: '30px'},
                            height: {xs: '26px', md: '30px'},
                            border: '1px solid #00000011',
                            fontSize: "14px"
                        }}>
                          {models.selectedModel.type === 'monetization' && Boolean(models.selectedModel.data?.name) && getInitials(models.selectedModel.data?.name)}
                          {models.selectedModel.type === 'shared' && Boolean(models.selectedModel.data?.model?.name) && getInitials(models.selectedModel.data?.model?.name)}
                          {models.selectedModel.type === 'default' && Boolean(models?.current?.name) && getInitials(models?.current?.name)}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="one_line_ellipsis">
                                {models.selectedModel.type === 'monetization' && models.selectedModel.data?.name}
                                {models.selectedModel.type === 'shared' && models.selectedModel.data?.model?.name}
                                {models.selectedModel.type === 'default' && models?.current?.name}
                            </Typography>
                        }
                    />

                    {modelsMenuOpen ? (
                        <>
                            <ExpandLessIcon fontSize="small"/>
                            <Menu
                                id="model-menu"
                                width="100%"
                                anchorEl={anchorEl}
                                open={modelsOpen}
                                onClose={handleModelsClose}
                                PaperProps={{
                                    elevation: 3,
                                    sx: {
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                    },
                                }}
                            >
                                {models.list.length > 0 && (
                                    <Box px={5} py={2}>
                                        Select Model:
                                    </Box>
                                )}
                                {models.list.map(
                                    (mod, index) =>

                                        <ListModelMenu
                                            key={`${mod.id}-${index}`}
                                            userId={userId}
                                            mod={mod}
                                        />
                                )}
                                <Grid container width={312}>
                                    <MenuItem
                                        sx={{fontSize: 14}}
                                        onClose={handleModelsClose}
                                        onClick={() => {
                                            dispatch(removeHints())
                                            navigate("/models/create");
                                            handleMobilebarChange();
                                            sendUIEvent({name: `create_new_model_nav_click`});
                                        }}
                                    >
                                        <Grid container width={280} py={1}>
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                <NoteAddIcon sx={{color: "#5f6368"}}/>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={7}
                                                px={3}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                Create Model
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                <Button
                                                    sx={{fontSize: 14, textTransform: "none"}}
                                                    fullWidth
                                                    onClick={(e) => {
                                                        handleManageModelClick(e);
                                                        handleMobilebarChange();
                                                        sendUIEvent({name: `manage_model_nav_click`});
                                                    }}
                                                >
                                                    Manage
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Grid>
                            </Menu>
                        </>
                    ) : (
                        <ExpandMoreIcon fontSize="small"/>
                    )}
                </ListItemButton>
                {/* former model part*/}
                <SharedModels widesidebar={true} onSelect={(model) => {
                    handleMobilebarChange();
                    if (!Boolean(model?.settings?.monetization_price) && Boolean(model?.public_hash)) {
                        fetchSharedData(model?.public_hash);
                        dispatch(deleteAllShared());
                    }

                    if (Boolean(model?.settings?.monetization_price) && Boolean(model?.marketplace_card?.url_hash)) {
                        dispatch(setSelectedModelType({modelType: 'monetization', data: model, loading: false}));
                        handleMobilebarChange();
                    }
                    navigate('/playground');
                }} leftBarMenu defaultOppened={false}/>
                {/* <ListItemButton
                    id="playground"
                    sx={{pl: 6}}
                    selected={location.pathname === "/playground"}
                    onClick={() => {
                        let navigateLink =
                            models.current.id == rabbitId ? "/rabbit-ai" : "/playground";
                        navigate(navigateLink);
                        handleMobilebarChange();
                    }}
                >
                    <ListItemIcon sx={{minWidth: "40px"}}>
                        <MenuIcons icon="playground" width="20" height="20"/>
                    </ListItemIcon>
                    <ListItemText primary="Playground"/>
                </ListItemButton> */}
                {models.selectedModel.type !== 'monetization' && models.selectedModel.type !== 'shared' && (
                    <>
                        <AccessChecker
                            currentPermissions={models.current.access}
                            requiredPermissions={["write"]}
                        >
                            <ListItemButton
                                sx={{pl: 6}}
                                onClick={() => {
                                    setBrainMenuOpen(!brainMenuOpen);
                                    const label = brainMenuOpen ? 'closed' : 'opened'
                                    sendUIEvent({name: `brain_nav_click_${label}`});
                                }}
                                id="brain-model"
                            >
                                <ListItemIcon sx={{minWidth: "40px"}}>
                                    <MenuIcons icon="brain" width="20" height="20"/>
                                </ListItemIcon>
                                <ListItemText primary="Brain"/>
                                {brainMenuOpen ? (
                                    <ExpandLessIcon fontSize="small"/>
                                ) : (
                                    <ExpandMoreIcon fontSize="small"/>
                                )}
                            </ListItemButton>


                            <Collapse in={brainMenuOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    {navigationMenuItems.map((item) => (
                                        <ListItemButton
                                            key={item.value}
                                            sx={{pl: 6}}
                                            selected={location.pathname.includes(item.path)}
                                            onClick={() => {
                                                navigate(item.path);
                                                handleMobilebarChange();
                                                sendUIEvent({name: `${item.label}_nav_click`});
                                            }}
                                        >
                                            <ListItemIcon sx={{minWidth: '40px'}}/>

                                            {
                                                item.badge ?
                                                    <Badge
                                                        badgeContent={item.badge}
                                                        size="small"
                                                        color="secondary"
                                                        sx={{
                                                            '& .MuiBadge-badge': {
                                                                fontSize: '0.6rem', // Change text size
                                                                padding: '0 6px',  // Adjust padding
                                                                height: '18px',   // Change badge height
                                                                minWidth: '15px', // Change badge width
                                                            },
                                                        }}
                                                    >
                                                        <ListItemText primary={item.label}/>
                                                    </Badge>
                                                    :
                                                    <ListItemText primary={item.label}/>
                                            }
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </AccessChecker>
                        <AccessChecker
                            currentPermissions={models.current.access}
                            requiredPermissions={["write"]}
                        >
                            <ShareModelListBtn
                              onClickCallback={() => handleMobilebarChange()}
                              setShareModelConfig={setShareModelConfig}
                            />
                            {/* <ListItemButton
                            id="modelsettings"
                            sx={{pl: 6}}
                            selected={location.pathname === "/model/settings"}
                            onClick={() => {
                                navigate("/model/settings");
                                handleMobilebarChange();
                            }}
                        >
                            <ListItemIcon sx={{minWidth: "40px"}}>
                                <MenuIcons icon="setting" width="20" height="20"/>
                            </ListItemIcon>
                            <ListItemText primary="Settings"/>
                        </ListItemButton> */}
                        </AccessChecker>
                    </>
                )}
                {models.selectedModel.type !== 'shared' && models.selectedModel.type !== 'monetization' && (
                    <ChatMenu
                        mobileHelperChange={handleChangeMobileHelperShow}
                        onChatChange={(val) => {
                            setIsNewChat(val);
                            handleMobilebarChange();
                        }}
                        onInnerClick={handleMobilebarChange}
                    />
                )}
            </List>
            {/* TODO: move this dialog to 1 separate component */}
            {/* Comfirmation dialog */}
            <Dialog
                open={isDialogOpen}
                sx={{
                    '.MuiPaper-root': {
                        padding: 2,
                    },
                }}
                onClose={() => {
                    setIsDialogOpen(false);
                    setSelectedModelId(null);
                }}
            >
                <DialogContent sx={{display: 'flex', alignItems: 'top', gap: '10px'}}>
                    <ReportProblemRoundedIcon sx={{width: 50, height: 50, color: '#ff3d00'}}/>
                    <DialogContentText>
                        Are you sure you want to delete this model's data? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.ctaActions}>
                    <Button
                        variant="outlined"
                        className={styles.cta}
                        onClick={() => {
                            setIsDialogOpen(false);
                            setSelectedModelId(null);

                        }}
                    >Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        className={styles.cta}
                        loading={loading}
                        onClick={(e) => {
                            handleModelDelete(e, selectedModelId);
                            setSelectedModelId(null);
                            handleMobilebarChange();
                            sendUIEvent({name: `delete_model_nav_click`});
                        }}
                    >
                        Confirm Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ListMenu;
