import React, { useEffect, useState } from 'react';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import './MousePointerBeacon.css';

const MousePointerBeacon = ({ onClick }) => {
  const [isClicking, setIsClicking] = useState(false);
  
  useEffect(() => {
    // Animation loop: normal -> clicking -> normal
    const interval = setInterval(() => {
      setIsClicking(prevState => !prevState);
    }, 1000); // Toggle every second
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div 
      className={`mouse-pointer-beacon ${isClicking ? 'clicking' : ''}`}
      onClick={onClick}
      aria-label="Click here"
    >
      <TouchAppIcon 
        sx={{ 
          fontSize: 40, 
          color: '#ff3333',
          filter: 'drop-shadow(0 0 2px #990000)'
        }} 
      />
    </div>
  );
};

export default MousePointerBeacon;