import ParseOptionsBtn from "./components/ParseOptionsBtn";
import ParseInput from "./components/ParseInput";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DescribeText from "./components/DescribeText";
import {useCallback, useState} from "react";
import Counter from "./components/Counter";
import SubmitBtn from "./components/SubmitBtn";
import {parseWebsite} from "redux/links/linksThunk";
import {useDispatch, useSelector} from "react-redux";
import {interactWithUser} from "redux/status";
import PagesCounterLabel from "./components/PagesCounterLabel";
import {useOutletContext} from "react-router-dom";
import {changeFilter, refreshList} from "redux/links";


const ParseWebsiteContent = ({handleParse = (type) => {}}) => {

    const [parseSettings, setParseSettings] = useState({});
    const dispatch = useDispatch();
    const modelId = useSelector(state => state?.models?.current?.id)

    const handleOnChange = useCallback((data) => {
        setParseSettings(prev => ({...prev, ...data}));
    }, []);

    const counterNeeded = parseSettings.parseSource === 'website';


    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        handleParse('pending');

        const response = await dispatch(parseWebsite({
            link: parseSettings.link,
            count: counterNeeded ? parseSettings.count : 1,
            approve: 1,
            modelId: modelId
        }));

        if (response.type === 'links/parse/fulfilled') {
            const {task, pages} = response.payload;
            let msg = 'Link successfully trained';
            let type = 'done';
            let filter = 'trained';

            if (typeof task.id !== 'undefined') {
                msg = 'It can take up to 2 minutes for model to be trained. Please, be patient';
                type = 'pending';
                filter = 'pending'
            }

            dispatch(changeFilter(filter));
            dispatch(refreshList());

            dispatch(interactWithUser({
                message: msg
            }));

            handleParse(type);
        }

    }, [dispatch, parseSettings.link, parseSettings.count, handleParse, modelId]);

    return (
        <Grid container textAlign="center" spacing={2} id="website-tool-opened-page">
            <Grid item xs={12}>
                <ParseOptionsBtn value={parseSettings.parseSource} onChange={handleOnChange}/>
            </Grid>
            <Grid item xs={12}>
                <DescribeText/>
            </Grid>
            <Grid item xs={12}>
                <ParseInput onChange={handleOnChange}/>
            </Grid>
            {counterNeeded && <>
                <Grid item xs={12} pt={4}>
                    <PagesCounterLabel/>
                </Grid>
                <Grid item xs={12} pb={4} display="flex" justifyContent="center">
                    <Counter onChange={handleOnChange}/>
                </Grid>
            </>
            }
            <Grid item xs={12} py={4}>
                <SubmitBtn disabled={parseSettings.isError ?? true} onSubmit={onSubmit}/>
            </Grid>
        </Grid>
    )
}

export default ParseWebsiteContent;