import {useState, useEffect, useMemo, useCallback} from "react";
import {Typography, Box, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Chip} from "@mui/material";
import classnames from 'classnames';
import {styled} from '@mui/material/styles';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import styles from "../onboarding.module.css";
import TextTraining from "pages/protected/tools/Training/components/TextTraining";
import QuestionAndAnswerTraining from "pages/protected/tools/Training/components/QuestionAndAnswerTraining";
import FilesTraining from "pages/protected/tools/Training/components/FilesTraining";
import MailTraining from "pages/protected/tools/Training/components/EmailTraining";
import EastIcon from '@mui/icons-material/East';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {clearStatus, setBrainOnboarding, setEveryToolsStatus} from "redux/tools";
import {setIsNewModel, setNewModelFlow} from 'redux/models';
import EmailView from "pages/protected/tools/Training/components/EmailTraining/components/EmailView";
import EmailSignIn from "pages/protected/tools/Training/components/EmailTraining/components/EmailSignIn";
import {WebsiteAccordion} from "./personalization/WebsiteAccordion";
import {sendUIEvent} from "events/analytics/google";

const accordionStyles = `
  &.MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionSummary-content:before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0;
  }
  .MuiAccordionDetails-root {
    padding: 5px 0 0;
  }
`;
const CustomAccordion = styled(Accordion)`${accordionStyles}`;

const Brain = ({modelId, isNewUser, intention}) => {
    const [showContinue, setShowContinue] = useState(true);
    const status = useSelector((state) => state.tools.status, shallowEqual);
    const models = useSelector((state) => state.models.list, shallowEqual);
    const authCount = useSelector(state => state?.auth?.authCount);
    const dispatch = useDispatch();
    const isBrainOnboardingEnabled = localStorage.getItem('brainOnboarding');
    const onboardingBrainStatus = localStorage.getItem('onboardingBrainStatus');
    const emailLoginLocal = localStorage.getItem('emailLogin');
    const [queryParameters] = useSearchParams();
    const continueOnboarding = queryParameters.get('continueOnboarding');
    const accountId = useSelector(state => state.email.account.current?.id, shallowEqual);
    const isEmailVerified = useMemo(() => Boolean(accountId), [accountId]);


    const handleContinueAvailable = useCallback((e, isExpanded, eventName = '') => {
        // setShowContinue(true);
        const actionLabel = isExpanded ? 'opened' : 'closed'
        sendUIEvent({name: `${eventName}_content_personalize_click_${actionLabel}`})
    }, [])


    useEffect(() => {
        if (Boolean(continueOnboarding)) {
            localStorage.removeItem('onboardingBrainStatus');
        }
        if (onboardingBrainStatus) {
            const parsedData = JSON.parse(onboardingBrainStatus);
            if (parsedData?.status && Number(parsedData?.modelId) === Number(modelId)) {
                dispatch(setEveryToolsStatus({status: parsedData?.status}));
            }
        }
        if (models.length === 1) {
            localStorage.setItem('brainOnboarding', 1);
        }
        // if (intention !== 'assistant') {
        //     localStorage.setItem('newModelFlow', intention);
        // }
    }, []);

    useEffect(() => {
        localStorage.setItem('onboardingBrainStatus', JSON.stringify({modelId, status}));
    }, [status]);

    return (
        <>
            <Grid item sx={{width: '100%'}}>
                <Box id="onboarding-brain-tools" className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)} sx={{
                    maxWidth: '640px',
                    width: '100%',
                    margin: 'auto',
                    position: 'relative',
                    paddingBottom: '45px',
                    animationDelay: '2500ms'
                }}>
                    <>
                        <Typography
                            variant="h2"
                            sx={{
                                marginBottom: '40px',
                                textAlign: 'center',
                                width: '100%',
                                fontSize: {xs: '22px', md: '32px'},
                                fontWeight: 300,
                                minHeight: '39px',
                                position: 'relative',
                            }}
                        >
                            Add some content to personalize my knowledge
                        </Typography>
                        {/* TODO: map accordions */}
                        {/* TODO: put them into /personalization folder */}
                        <WebsiteAccordion className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)}
                                          onChange={(event, isExpanded) => handleContinueAvailable(event, isExpanded, 'website')}
                        />
                        <CustomAccordion
                            sx={{
                                border: '1px solid rgba(216,211,250, .1)',
                                borderRadius: '12px !important',
                                padding: '4px 10px 4px 14px',
                                width: '100%',
                                marginBottom: '10px',
                                justifyContent: 'left',
                                color: '#000',
                                fontSize: '14px',
                                textTransform: 'none',
                                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                                animationDelay: '3600ms',
                                minHeight: '34px'
                            }}

                            onChange={(event, isExpanded) => handleContinueAvailable(event, isExpanded, 'files')}
                            className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)}
                            id="files-tool"
                        >
                            <AccordionSummary>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Box>
                                        <AttachFileIcon
                                            sx={{fontSize: '18px', position: 'relative', top: '2px'}}/>&nbsp; Import
                                        content from files
                                    </Box>
                                    {status.files === 'done' && (
                                        <CheckCircleIcon sx={{color: '#44a047'}}/>
                                    )}
                                    {status.files === 'pending' && (
                                        <Chip label="In Progress" sx={{
                                            borderRadius: '3px',
                                            color: '#2e46fa',
                                            background: '#eef0fc',
                                            fontSize: '12px',
                                            height: 'auto'
                                        }}/>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    borderTop: '1px solid rgba(0,0,0, .1)',
                                    marginTop: '20px',
                                    paddingTop: '20px'
                                }}>
                                    <FilesTraining id={modelId}/>
                                </Box>
                            </AccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion
                            sx={{
                                marginBottom: '10px',
                                border: '1px solid rgba(216,211,250, .1)',
                                borderRadius: '12px !important',
                                padding: '4px 10px 4px 14px',
                                width: '100%',
                                justifyContent: 'left',
                                color: '#000',
                                fontSize: '14px',
                                textTransform: 'none',
                                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                                animationDelay: '3900ms',
                                minHeight: '34px'
                            }}
                            onChange={(event, isExpanded) => handleContinueAvailable(event, isExpanded, 'text')}
                            className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)}
                            id="text-tool"
                        >
                            <AccordionSummary>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Box>
                                        <ArticleIcon
                                            sx={{fontSize: '18px', position: 'relative', top: '2px'}}/>&nbsp; Add
                                        contextual information
                                    </Box>
                                    {status.text === 'done' && (
                                        <CheckCircleIcon sx={{color: '#44a047'}}/>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{paddingBottom: '10px'}}>
                                <Box sx={{
                                    borderTop: '1px solid rgba(0,0,0, .1)',
                                    marginTop: '20px',
                                    paddingTop: '20px'
                                }}>
                                    <TextTraining id={modelId}/>
                                </Box>
                            </AccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion
                            sx={{
                                border: '1px solid rgba(216,211,250, .1)',
                                borderRadius: '12px !important',
                                padding: '4px 10px 4px 14px',
                                width: '100%',
                                marginBottom: '10px',
                                justifyContent: 'left',
                                color: '#000',
                                fontSize: '14px',
                                textTransform: 'none',
                                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                                animationDelay: '4200ms',
                                minHeight: '34px'
                            }}
                            onChange={(event, isExpanded) => handleContinueAvailable(event, isExpanded, 'q&a')}
                            className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)}
                            id="qa-tool"
                        >
                            <AccordionSummary>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Box>
                                        <HelpIcon sx={{fontSize: '18px', position: 'relative', top: '2px'}}/>&nbsp; Add
                                        Questions and Answers
                                    </Box>
                                    {status.qa === 'done' && (
                                        <CheckCircleIcon sx={{color: '#44a047'}}/>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    borderTop: '1px solid rgba(0,0,0, .1)',
                                    marginTop: '20px',
                                    paddingTop: '20px'
                                }}>
                                    <QuestionAndAnswerTraining id={modelId}/>
                                </Box>
                            </AccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion
                            sx={{
                                border: '1px solid rgba(216,211,250, .1)',
                                borderRadius: '12px !important',
                                padding: '4px 10px 4px 14px',
                                width: '100%',
                                marginBottom: '10px',
                                justifyContent: 'left',
                                color: '#000',
                                fontSize: '14px',
                                textTransform: 'none',
                                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                                animationDelay: '4500ms',
                                minHeight: '34px'
                            }}
                            defaultExpanded={Boolean(isBrainOnboardingEnabled)}
                            onChange={(event, isExpanded) => handleContinueAvailable(event, isExpanded, 'email')}
                            className={classnames(!isBrainOnboardingEnabled && isNewUser && styles.button)}
                            id="email-tool"
                        >
                            <AccordionSummary>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Box>
                                        <AlternateEmailIcon
                                            sx={{fontSize: '18px', position: 'relative', top: '2px'}}/>&nbsp; Add
                                        Gmail/Outlook conversations content
                                    </Box>
                                    {status.emails === 'done' && (
                                        <CheckCircleIcon sx={{color: '#44a047'}}/>
                                    )}
                                    {status.emails === 'pending' && (
                                        <Chip label="In Progress" sx={{
                                            borderRadius: '3px',
                                            color: '#2e46fa',
                                            background: '#eef0fc',
                                            fontSize: '12px',
                                            height: 'auto'
                                        }}/>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    borderTop: '1px solid rgba(0,0,0, .1)',
                                    marginTop: '20px',
                                    paddingTop: '20px'
                                }}>
                                    {Boolean(localStorage.getItem('brainOnboarding')) ? (
                                        <MailTraining/>
                                    ) : (
                                        isEmailVerified ? (
                                            <EmailView/>
                                        ) : (
                                            <EmailSignIn accounts={[]} loading={false}/>
                                        )
                                    )}
                                </Box>
                            </AccordionDetails>
                        </CustomAccordion>
                        <Box
                            className={!isBrainOnboardingEnabled && isNewUser && styles.button}
                            sx={{
                                fontSize: '12px',
                                animationDelay: '4500ms',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center'
                            }}>
                            <Typography sx={{fontSize: '12px', color: '#9a9a9a'}}>
                              * You will be able to edit or add more later
                            </Typography>
                            <Button
                                onClick={() => {
                                    // dispatch(setIsNewModel({isNewModel: false}));
                                    // dispatch(clearStatus());
                                    localStorage.removeItem('onboardingBrainStatus');
                                    // dispatch(setNewModelFlow({flow: intention}));
                                    if (Boolean(emailLoginLocal)) {
                                        const parsedEmailData = JSON.parse(emailLoginLocal);
                                        localStorage.setItem(parsedEmailData.storageId, JSON.stringify(parsedEmailData.stringData));
                                        // TODO: improve this logic
                                        localStorage.removeItem('emailLogin');
                                    }
                                    localStorage.removeItem('brainOnboarding');

                                    if (models.length === 1
                                        && authCount === 1
                                    ) {
                                        sendUIEvent({
                                            name: 'onboarding_flow_finished_first_time',
                                            params: {
                                                once: true,
                                                event_callback: () => {

                                                }
                                            }
                                        });

                                    }

                                    const intentionMapper = {
                                      monetization: '/share/monetization',
                                      'chat-bot': '/share/chat-bot',
                                      'customer-support': '/share/speak-to-product',
                                    }
                                    const intentionLink = intentionMapper[intention] ? intentionMapper[intention] : '';
                                    // assistant, monetization, chat-bot, customer-support

                                    setTimeout(
                                        () => {
                                            window.location.href = `${window.location.origin}/portal${intentionLink}`;
                                        }, 300
                                    )

                                    // window.location.reload();
                                }}
                                endIcon={<EastIcon sx={{position: 'relative', margin: {xs: '5px', md: 0}}}/>}
                                disabled={!showContinue}
                                sx={{
                                    color: '#3976ef',
                                    background: '#fff',
                                    minWidth: {xs: '32px', md: 'auto'},
                                    height: {xs: '40px', md: 'auto'},
                                }}
                            >
                                Continue
                            </Button>
                        </Box>
                    </>
                </Box>
            </Grid>
        </>
    );
};

export default Brain;
