import { useRef, useState, useEffect } from "react";
import { Typography, Box, Button, TextareaAutosize, TextField, ListItemButton } from "@mui/material";
import Typewriter from '../components/Typewriter';
import Logo from '../components/Logo';
import classnames from 'classnames';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { styled } from '@mui/material/styles';
import styles from "../onboarding.module.css";

const BaseTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    resize: none;
    padding-right: 25px;
    height: 20px;
    overflow: hidden;
    max-width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Mui-focused .MuiOutlinedInput-input {
    overflow: inherit;
    max-width: 100%;
    height: 100%;
    white-space: inherit;
  }

  &.MuiFormControl-root {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 102%;
    left: -1%;
    background: #fff;
    height: 38px;
    top: ${(props) => `${props.top}px` || '134px'};
    transition: 0.3s ease all;
    animation-delay: 4300ms;
  }

  &.MuiFormControl-root:has(> .Mui-focused) fieldset,
  &.MuiFormControl-root:has(> .Mui-focused) .MuiOutlinedInput-root:hover fieldset {
    box-shadow: 0 0 3px 3px rgba(216, 211, 250, 0.2);
  }

  .MuiInputBase-root {
    position: absolute;
    padding: 8px 10px 8px 14px;
    border-radius: 12px;
    font-size: 14px;
    color: #000;
    height: 100%;
    bottom: 0;
    width: 98%;
    left: 1%;
    transition: 0.3s ease top;
  }

  .MuiInputLabel-root {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    transform: translate(14px, 9px) scale(1);
  }

  .MuiInputLabel-root.Mui-focused {
    transform: translate(10px, -9px) scale(0.75) !important;
    padding: 0 5px;
    background: #fff;
  }

  .MuiOutlinedInput-root fieldset,
  .MuiOutlinedInput-root:hover fieldset {
    border-color: rgba(216, 211, 250, 0.4);
    box-shadow: 0 0 2px 2px rgba(216, 211, 250, 0.25);
  }

  .MuiOutlinedInput-root:hover fieldset {
    background: rgba(57, 118, 239, 0.04);
  }

  @media only screen and (max-width: 899px) {
    & .MuiInputLabel-root {
      white-space: pre-wrap;
    }
    & .MuiInputLabel-shrink {
      white-space: nowrap;
    }
  }
`;

const OptionBaseTextField = styled(TextField)`
  &.MuiFormControl-root {
    position: relative;
    width: 100%;
    background: #fff;
    margin-top: 8px;
    transition: 0.3s ease all;
  }

  .MuiOutlinedInput-input {
    resize: none;
    padding-right: 25px;
    font-size: 14px;
  }

  .MuiOutlinedInput-root fieldset {
    border-color: rgba(216,211,250, 0.4);
    box-shadow: 0 0 2px 2px rgba(216,211,250, 0.25);
  }
`;

const CustomTextField = styled(BaseTextField)`
  &.MuiFormControl-root:has(> .Mui-focused) {
    top: ${(props) => (props.expanded ? '0' : '-1%')};
    height: ${(props) => (props.expanded ? '0' : '101%')};
  }

  .MuiInputBase-root.Mui-focused {
    top: 0;
    bottom: 0;
    height: 100%;
    padding-top: 10px;
  }

  .MuiInputBase-root.Mui-focused .MuiInputBase-inputMultiline {
    height: 100% !important;
  }
`;

const Quiz = ({ item, isNewUser, steps, quizLength, setAnimatedSteps, animatedSteps, setAnswers, answers, stepBag, getModels, isFocused, setIsFocused, setTextInputText, textInputText, setFinishedStepNames, finishedStepNames }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 5000);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };

  }, []);

  const onFocus = () => {
    window.gtag('event', 'Onboarding_focus_text_field', {
      'event_category': 'Onboarding',
      'event_label': 'Onboarding',
      'value': 'focus text field',
      'step': stepBag.step,
      'width': window.innerWidth,
    });
    setIsFocused(true);
  }
  const onBlur = () => setIsFocused(false);
  const animationFinished = finishedStepNames.includes(item.name);
  const inputRef = useRef(null);
  const isFieldDisabled = item.isOptional ? false : !Boolean(textInputText.length);
  // const currentAnswer = answers.find(obj => obj.question === item.name)?.answer || [];
  const currentAnswer = answers.find(obj => obj.name === item.name)?.answer || [];
  const [selectedOptions, setSelectedOptions] = useState(Array.isArray(currentAnswer) ? currentAnswer : currentAnswer.split(', '));
  const [textInput, setTextInput] = useState('');
  const [optionExplanations, setOptionExplanations] = useState({});
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [showMultipleChoiceNote, setShowMultipleChoiceNote] = useState(false);

  const otherFieldTop = item.otherTop
  ? 0
  : containerHeight + (item.options.length === 1 ? 50 : 0);
  const otherAppearDelay = item.options.length * 500 + 2000;

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, [selectedOptions, optionExplanations]); // update when content changes

  const getOptionLabel = (option) => (typeof option === 'object' ? option.label : option);
  const getOptionPlaceholder = (option) =>
    typeof option === 'object' && option.placeholder
      ? option.placeholder
      : "Optional: please describe in more detail for better personalization";

  useEffect(() => {
    const stepKey = steps[stepBag.step - 1];
    setAnimatedSteps({...animatedSteps, [stepKey]: true});
  }, []);

  useEffect(() => {
    const existingAnswerObj = answers.find(answer => answer.name === item.name);
    if (existingAnswerObj) {
      const answerStr = existingAnswerObj.answer;
      const delimiter = " ||| ";
      const innerDelimiter = "::";
      // If the merged answer was used.
      if (answerStr.includes(delimiter)) {
        const parts = answerStr.split(delimiter);
        const recoveredOptions = [];
        const recoveredExplanations = {};
        parts.forEach(part => {
          if (part.startsWith("OTHER" + innerDelimiter)) {
            setTextInput(part.replace("OTHER" + innerDelimiter, ""));
          } else if (part.includes(innerDelimiter)) {
            const [opt, explanation] = part.split(innerDelimiter);
            recoveredOptions.push(opt);
            recoveredExplanations[opt] = explanation;
          } else {
            recoveredOptions.push(part);
          }
        });
        setSelectedOptions(recoveredOptions);
        setOptionExplanations(recoveredExplanations);
      } else {
        // If the answer is not merged (only "Other" answer).
        if (item.expanded) {
          setTextInput(answerStr);
        } else {
          const answerOptions = answerStr.split(', ');
          setSelectedOptions(answerOptions);
        }
      }
    }
  }, [answers, item.name, item.expanded, item.options]);

  const updatedAnswerByKey = (array, item, newAnswer) => {
    const questionText = item.title || item.name;
    // Match based on item.name instead of question text.
    const existingObjectIndex = array.findIndex(obj => obj.name === item.name);
    
    if (existingObjectIndex !== -1) {
      array[existingObjectIndex].question = questionText; // update question text too
      array[existingObjectIndex].answer = newAnswer;
    } else {
      array.push({
        name: item.name,
        question: questionText,
        answer: newAnswer
      });
    }
    return array;
  };

  const handleOptionClick = (option) => {
    // Prepare the short option name and reduce it to first word.
    const optionAnalytic = getOptionLabel(option).split(' ')[0].toLowerCase();
    // Track to the google analytics all the options selected by the user
    window.gtag('event', 'Onboarding_select_option_' + optionAnalytic, {
      'event_category': 'Onboarding',
      'event_label': 'Onboarding',
      'value': 'select option',
      'step': stepBag.step,
      'width': window.innerWidth,
      'option': optionAnalytic
    });

    if (item.multipleChoice) {
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter(opt => opt !== option)
        : [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
      const updatedAnswers = updatedAnswerByKey(answers, item, updatedOptions.join(', '));
      setAnswers(updatedAnswers);
    } else {
      // For single-choice, set the option without auto-advancing
      setSelectedOptions([option]);
      const updatedAnswers = updatedAnswerByKey(answers, item, option);
      setAnswers(updatedAnswers);
      // Do NOT auto-call handleNextClick here; wait for the Next button click.
    }
  };

  const handleNextClick = (options = selectedOptions) => {
    const delimiter = " ||| ";
    const innerDelimiter = "::";
    // Merge selected options with their optional clarifications.
    const optionsMerged = selectedOptions.map(opt =>
      optionExplanations[opt] ? `${opt}${innerDelimiter}${optionExplanations[opt]}` : opt
    ).join(delimiter);
    // Merge the "Other" text answer if available, using an "OTHER" prefix.
    const otherMerged = textInput.trim() ? `OTHER${innerDelimiter}${textInput.trim()}` : "";
    const finalAnswer =
      optionsMerged && otherMerged
        ? optionsMerged + delimiter + otherMerged
        : (optionsMerged || otherMerged);
  
    // Compute additional question details from any answered optional text.
    // For each selected option that has an explanation,
    // find its corresponding placeholder (if set) and append it.
    const optionalPlaceholders = selectedOptions.reduce((acc, opt) => {
      if (optionExplanations[opt]) {
        const optionObj = item.options.find(
          o => typeof o === "object" && o.label === opt && o.placeholder
        );
        if (optionObj) {
          acc.push(optionObj.placeholder);
        }
      }
      return acc;
    }, []);
  
    const fullQuestion =
      optionalPlaceholders.length > 0
        ? `${item.title}; ${optionalPlaceholders.join("; ")}`
        : item.title;
  
    // Pass an updated item (with title replaced by fullQuestion) to updatedAnswerByKey.
    const updatedAnswers = updatedAnswerByKey(answers, { ...item, title: fullQuestion }, finalAnswer);
    setAnswers(updatedAnswers);
    setFinishedStepNames([...finishedStepNames, item.name]);
    window.gtag("event", "Onboarding_select_answer", {
      event_category: "Onboarding",
      event_label: "Onboarding",
      value: "select answer",
      step: stepBag.step,
      width: window.innerWidth,
    });
    stepBag.setNextStep();
    if (stepBag.step === quizLength) {
      window.gtag("event", "Onboarding_questionnaire_complete", {
        event_category: "Onboarding",
        event_label: "Questionnaire Complete",
        step: stepBag.step,
        width: window.innerWidth,
      });
      getModels(updatedAnswers);
    }
  };

  return (
    <>      
      <Typography
        variant="h2"
        sx={{
          marginBottom: showMultipleChoiceNote ? '5px' : '20px',
          textAlign: 'center',
          width: '100%',
          fontSize: {xs: '22px', md: '32px'},
          fontWeight: 300,
          minHeight: '39px',
          position: 'relative',
        }}
      >
        {animationFinished || !isNewUser ? item.title : (
          <>
            <Box sx={{ opacity: 0 }}>{item.title}</Box>
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center', width: '100%' }}>
              <Typewriter text={item.title} animationSpeed={stepBag.step >= 2 ? 20 : 30} animationDelay={2400} onTypingEnd={() => setShowMultipleChoiceNote(item.multipleChoice)} />
            </Box>
          </>
        )}
      </Typography>
      {animationFinished || isNewUser && item.multipleChoice && showMultipleChoiceNote && (
          <Typography
            variant="body1"
            sx={{
              marginBottom: '20px',
              textAlign: 'center',
              width: '100%',
              fontSize: '14px',
              fontWeight: 300,
              color: '#7e7e7e',
            }}
            animationDelay={4000}
          >
            You may select one or more
          </Typography>
      )}
      <Box id="quiz-questions" sx={{ maxWidth: '340px', width: '100%', margin: 'auto', position: 'relative', paddingBottom: item.expanded ? '200px' : '38px' }}>
      {item.options.map((option, i) => {
        const label = getOptionLabel(option);
        // Check if explanation is disabled for this option:
        const explanationDisabled = typeof option === 'object' && option.disableTextArea;
        // Calculate animation delay differently if other option is at the top.
        const optionAnimationDelay = i * 500;

        return (
          <div key={`option-container-${i}`} style={{ marginBottom: '16px' }}>
            <Button
              key={`btn-${i}`}
              onClick={() => handleOptionClick(label)}
              sx={{
                border: '1px solid rgba(216,211,250, .1)',
                borderRadius: '12px',
                padding: '4px 10px 4px 14px',
                width: '100%',
                justifyContent: 'left',
                color: selectedOptions.includes(label) ? '#fff' : '#000',
                backgroundColor: selectedOptions.includes(label) ? '#3976EF' : '#fff',
                fontSize: '14px',
                textTransform: 'none',
                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                animationDelay: `${optionAnimationDelay + 4600}ms`,
                height: '35px',
                '&:hover': {
                  backgroundColor: selectedOptions.includes(label) ? '#2c5bbf' : '#f0f0f0'
                }
              }}
              className={classnames(!animationFinished && isNewUser && styles.button)}
            >
              {animationFinished || !isNewUser ? label : (
                <Typewriter
                  text={label}
                  animationSpeed={50}
                  animationDelay={`${optionAnimationDelay + 4400}ms`}
                />
              )}
            </Button>
            {selectedOptions.includes(label) && !explanationDisabled && (
              <OptionBaseTextField
                placeholder={getOptionPlaceholder(option)}
                fullWidth
                multiline
                rows={4}
                value={optionExplanations[label] || ''}
                onChange={(e) =>
                  setOptionExplanations({
                    ...optionExplanations,
                    [label]: e.target.value
                  })
                }
                variant="outlined"
                size="small"
                sx={{ mt: 1, mb: 1 }}
              />
            )}
          </div>
        );
      })}
        {item.expanded ? (
          <BaseTextField
            top={otherFieldTop}
            inputHeight={item.expanded}
            inputRef={inputRef}
            placeholder={isFocused || item.expanded ? item.other : Boolean(item.customOther) ? item.customOther : 'Other (Please specify)'}
            name='text'
            fullWidth
            value={textInput}
            rows={4}
            multiline={true}
            className={classnames(!animationFinished && isNewUser && styles.button, item.expanded && styles.expanded)}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => {
              setTextInputText(event.target.value);
              setTextInput(event.target.value);
              const updatedAnswers = updatedAnswerByKey(answers, item, event.target.value);
              setAnswers(updatedAnswers);
            }}
            InputProps={{
              inputComponent: TextareaAutosize,
              endAdornment: (
                <ListItemButton
                  onClick={(event) => {
                    event.stopPropagation();
                    const updatedAnswers = updatedAnswerByKey(answers, item, inputRef.current.value);
                    setAnswers(updatedAnswers);
                    setFinishedStepNames([...finishedStepNames, item.name]);
                    stepBag.setNextStep();
                    window.gtag('event', 'Onboarding_select_custom_answer', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'select custom answer',
                      'step': stepBag.step,
                      'width': window.innerWidth
                    });
                    if (stepBag.step === quizLength) {
                      getModels(updatedAnswers.filter(item => item.answer.length));
                    }
                  }}
                  disableGutters
                  disabled={isFieldDisabled}
                  edge="end"
                  aria-label="edit"
                  sx={{ position: 'absolute', right: '7px', bottom: '4px', padding: '5px', borderRadius: '50%' }}
                >
                  <EastOutlinedIcon sx={{ color: '#3976EF' }} fontSize="small" />
                </ListItemButton>
              )
            }}
          />
        ) : (
          <CustomTextField
            id="other-text-field"
            top={otherFieldTop}
            inputHeight={item.expanded}
            inputRef={inputRef}
            placeholder={isFocused || item.expanded ? item.other : Boolean(item.customOther) ? item.customOther : 'Other (Please specify)'}
            name='text'
            fullWidth
            value={textInput}
            rows={4}
            multiline={true}
            className={classnames(!animationFinished && isNewUser && styles.button, item.expanded && styles.expanded)}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => {
              setTextInputText(event.target.value);
              setTextInput(event.target.value);
              const updatedAnswers = updatedAnswerByKey(answers, item, event.target.value);
              setAnswers(updatedAnswers);
            }}
            InputProps={{
              inputComponent: TextareaAutosize,
              endAdornment: (
                <ListItemButton
                  onClick={(event) => {
                    event.stopPropagation();
                    const updatedAnswers = updatedAnswerByKey(answers, item, inputRef.current.value);
                    setAnswers(updatedAnswers);
                    setFinishedStepNames([...finishedStepNames, item.name]);
                    stepBag.setNextStep();
                    window.gtag('event', 'Onboarding_select_custom_answer', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'select custom answer',
                      'step': stepBag.step,
                      'width': window.innerWidth
                    });
                    if (stepBag.step === quizLength) {
                      getModels(updatedAnswers.filter(item => item.answer.length));
                    }
                  }}
                  disableGutters
                  disabled={isFieldDisabled}
                  edge="end"
                  aria-label="edit"
                  sx={{ position: 'absolute', right: '7px', bottom: '4px', padding: '5px', borderRadius: '50%' }}
                >
                  <EastOutlinedIcon sx={{ color: '#3976EF' }} fontSize="small" />
                </ListItemButton>
              )
            }}
          />
        )}
      </Box>
      <Button
        onClick={() => {
          const currentIndex = steps.indexOf(item.name);
          const newStep = stepBag.step - 1;
          window.gtag('event', 'Onboarding_click_back', {
            'event_category': 'Onboarding',
            'event_label': 'Onboarding',
            'value': 'click back',
            'step': stepBag.step,
            'width': window.innerWidth,
          });
          setAnimatedSteps({...animatedSteps, [stepBag.stepKey]: true});
          setFinishedStepNames([ ...finishedStepNames, item.name ]);
          stepBag.setStep(steps[currentIndex - 1] ? steps[currentIndex - 1] : 'initial');
        }}
        startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
        sx={{ 
          position: { xs: 'fixed', md: 'absolute', lg: 'absolute' },
          bottom: '40px',
          left: { xs: '5%', md: '20px', lg: '20px' },
          color: '#7e7e7e',
          background: '#fff',
          minWidth: {xs: '32px', md: 'auto'}, 
          height: {xs: '40px', md: 'auto'},
          display: { xs: 'block', md: 'block' },
          zIndex: 1,
          opacity: { xs: isScrolling ? 1 : 0.1, md: 1 },
          '&:hover': {
            opacity: 1,
            boxShadow: { xs: '0 0 2px 2px rgba(216,211,250, .5)', md: 'none' },
          }
        }}
      >
        <Box sx={{ display: {xs: 'none', md: 'block'} }}>
          Back
        </Box>
      </Button>
      <Button
        onClick={() => handleNextClick()}
        disabled={selectedOptions.length === 0}
        startIcon={<EastOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
        sx={{ 
          position: { xs: 'fixed', md: 'absolute', lg: 'absolute' },
          bottom: '40px',
          // left: { xs: '85%', md: 'auto', lg: 'auto' },
          right: { xs: '5%', md: '20px', lg: '20px' },
          color: '#3976ef',
          background: '#fff',
          minWidth: {xs: '32px', md: 'auto'}, 
          height: {xs: '40px', md: 'auto'},
          display: { xs: 'block', md: 'block' },
          zIndex: 1,
          opacity: selectedOptions.length === 0 ? 0 : 1,
          boxShadow: { xs: '0 0 2px 2px rgba(216,211,250, .5)', md: 'none' },
          '&:hover': {
            opacity: 1,
          }
        }}
      >
        <Box sx={{ display: {xs: 'none', md: 'block'} }}>
          Next
        </Box>
      </Button>
    </>
  )
};

export default Quiz;
