import {createSlice} from "@reduxjs/toolkit";
import {getMsgListThunk, loadMoreThunk, getMsgThunkByIds, sendMessage} from "./messageThunk";

const initialState = {
    chatId: null,
    list: [],
    sharedList: [],
    count: 0,
    limit: 0,
    lastResponseSources: {
        messageId: 0,
        list: {}
    },
    toolResult: [],
    processingMessages: [],
    loading: false,
    loadMoreLoading: false,
    errors: [],
    session: {
        loading: {
            stage: 1,
            isMsgSending: false,
            tick: 0
        },
        tools: {
            call: '',
            stack: {},
            result: []
        },
        attachments: false,
        user: false,
        ai: false,
        shared: false,
    },
};

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {

        // addTempMessage(state, action) {

        //   let type = action.payload.type;

        //   if(action.payload.refresh) {
        //     state.current[type] = false;
        //     return;
        //   }

        //   let text = action.payload.text;
        //   state.current[type] = text;
        // },

        addToolCallToStack(state, action) {
            let tool = action.payload;
            const count = state.session.tools.stack[tool] || 0;
            state.session.tools.stack[tool] = count + 1;
        },
        popFromToolCallStack(state, action) {
            let tool = action.payload;
            const count = state.session.tools.stack[tool];

            if (!count) {
                return;
            }

            if(count - 1 === 0) {
                delete state.session.tools.stack[tool]
                return
            }

            state.session.tools.stack[tool] = count - 1;
        },
        cleanMessages(state) {
          state.list = [];
          state.count = 0;
          state.limit = 0;
          state.lastResponseSources.messageId = 0;
          state.lastResponseSources.list = [];
          state.toolResult = [];
        },
        cleanStack(state) {
            state.session.tools.stack = {}
        },
        updateToolCallStatus(state, action) {
            let tool = action.payload;
            state.session.tools.call = tool;
        },

        brainTickUpdate(state) {
            state.session.loading.tick += 1
        },

        setMessageLoading(state, action) {

            let {loading, stage} = action.payload;

            if (typeof loading !== 'undefined') {
                state.session.loading.isMsgSending = loading;
            }

            if (typeof stage !== 'undefined') {
                state.session.loading.stage = stage;
            }

        },
        cleanUpData(state) {
            // state.chatId = null;
            // state.list = [];
            // state.sharedList = [];
            state = initialState;
            // state.session = initialState.current;
        },

        addTempMessage(state, action) {

            let {type, text, attachments} = action.payload;

            if (action.payload.refresh) {
                state.session[type] = false;

                if (type === "user") {
                    state.session.attachments = false;
                }

                return;
            }


            if (state.session[type] == false) {
                state.session[type] = text;
            } else {
                state.session[type] += text;
            }

            if (attachments) {
                state.session.attachments = attachments;
            }
        },

        addMessage(state, action) {
            const message = action.payload;
            state.list.push(message);
        },
        addMessages(state, action) {
            const {message, response} = action.payload;
            state.list.push(message);
            state.list.push(response);
        },

        deleteLastMsgs(state, action) {
            let len = action.payload;
            state.list.splice(-(len));
        },
        deleteAll(state) {
            state.chatId = null;
            state.list = [];
        },

        editMessage(state, action) {

            const updatedArray = state.list.map(msg => {
                if (msg.id === action.payload.id) {

                    return {
                        ...msg,
                        message: action.payload.message
                    }
                }
                return msg;
            })


            state.list = updatedArray;
        },
        setLastResponseSources(state, action) {
            const {messageId, sources} = action.payload;
            state.lastResponseSources = {messageId: messageId, list: sources};
        },
        setToolsResults(state, action) {
            let results = [];
            
            // Check if action.payload.toolsData exists (based on the example structure)
            if (action.payload.toolsData && Array.isArray(action.payload.toolsData)) {
                // The array is inside toolsData property
                action.payload.toolsData.forEach((toolData) => {
                    results.push(toolData);
                });
            } else if (Array.isArray(action.payload)) {
                // For backward compatibility if payload is directly an array
                action.payload.forEach((toolData) => {
                    results.push(toolData);
                });
            } else {
                results = Array.isArray(action.payload) ? [...action.payload] : 
                         (action.payload ? [action.payload] : []);
            }

            // Remove generate_image tool from the results
            results = results.filter((toolData) => toolData.tool !== 'generate_image');
            
            state.toolResult = results;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getMsgListThunk.rejected, (state, {_}) => {
            state.loading = false;
            state.list = [];
        });

        builder.addCase(getMsgListThunk.pending, (state, {_}) => {
            state.loading = true;
        });

        builder.addCase(getMsgListThunk.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.list = payload.messages;
            state.limit = payload.limit;
            state.count = payload.count;
            state.errors = [];
            const processingMessages = payload.messages.filter((msg) => msg.messageStatus === "processing");
            state.processingMessages = processingMessages;
            state.lastResponseSources = initialState.lastResponseSources;
            state.toolResult = [];
        });

        builder.addCase(loadMoreThunk.rejected, (state, {_}) => {
          state.loadMoreLoading = false;
        });

        builder.addCase(loadMoreThunk.pending, (state, {_}) => {
          state.loadMoreLoading = true;
        });

        builder.addCase(loadMoreThunk.fulfilled, (state, {payload}) => {
          state.loadMoreLoading = false;
          state.list = payload.messages;
          state.limit = payload.limit;
          state.count = payload.count;
          state.errors = [];
        })

        builder.addCase(getMsgThunkByIds.rejected, (state, {_}) => {
            state.list = [];
        });

        builder.addCase(getMsgThunkByIds.fulfilled, (state, {payload}) => {
            const updatedMessages = payload.messages || [];
            const updatedArray = state.list.map(msg => {
                const matchingMessage = updatedMessages.find(updatedMsg => updatedMsg.id === msg.id);
                if (matchingMessage) {
                    return matchingMessage;
                }
                return msg;
            });
            state.list = updatedArray;
            state.errors = [];
            state.lastResponseSources = initialState.lastResponseSources;
        })
    }
});

export const {
    addMessages,
    addMessage,
    deleteAll,
    editMessage,
    deleteLastMsgs,
    addTempMessage,
    cleanUpData,
    setLastResponseSources,
    setToolsResults,
    setMessageLoading,
    brainTickUpdate,
    updateToolCallStatus,
    addToolCallToStack,
    popFromToolCallStack,
    cleanMessages,
    cleanStack
} = messagesSlice.actions;

export const getList = getMsgListThunk;
export const loadMore = loadMoreThunk;
export const getByIds = getMsgThunkByIds;

export default messagesSlice.reducer;
