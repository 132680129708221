import api from "_services/api";
import {
  Badge,
  Tabs,
  Tab,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import FileInput from "components/inputs/FileInput";
import SendDataButton from "components/buttons/SendDataButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import { interactWithUser } from "redux/status";
import DownloadIcon from "@mui/icons-material/Download";
import TableHead from "@mui/material/TableHead";
import { checkFormForErrors } from "utils/validateInputs";
import formatFileSizes from "utils/formatFileSizes";
import formatTime from "utils/formatTime";
import EastIcon from "@mui/icons-material/East";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {setStatus, updateCells} from "redux/tools";
import styles from '../../training.module.css';
import {sendUIEvent} from "../../../../../../events/analytics/google";


const FilesTraining = ({ id }) => {
  // const { modelId } = useOutletContext();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [rows, setRows] = useState([]);
  const [isListLoading, setListLoading] = useState(false);
  const [isDataSending, setDataSending] = useState(false);
  const [resetCounter,setResetCounter] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(0);

  const maxAllowedFiles = useSelector(state => state.permissions?.planLimitations?.learningFile?.maxLimit);
  const model = useSelector((state) => state.models?.current);
  const modelId = Boolean(model.id) ? model.id : id;

  const supportedFormats = [
    {
      formats: [".docx", ".doc", ".pdf", ".txt"],
      title: 'Documents'
    },
    {
      formats: [".xlsx", ".csv"],
      title: 'Spreadsheets',
    },
    {
      formats: [".eml", ".html"],
      title: 'Rich Documents',
    },
    {
      formats: [".pptx", ".ppt"],
      title: 'Presentations',
    }
  ];

  const [fileState, setFileState] = useState({
    title: {
      errMsg: "",
      isRequired: true,
    },
    file: {
      errMsg: "",
      isRequired: true,
      additionals: {
        allowedTypes: [
          "docx",
          "doc",
          "pptx",
          "ppt",
          "xlsx",
          "csv",
          "eml",
          "html",
          "pdf",
          "txt",
        ],
        maxSizeInBytes: 100000000,
      },

    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRedirect = (id,path) => {
    nav(path+'/'+id);
    sendUIEvent({name: `file_tool_view_item`});
  }

  const fileNameInput = useRef(null);

  const fetchData = async ( isRefetching = false ) => {
    if (!isRefetching) setListLoading(true);
    if (modelId) {
      await api
        .get(`learning/file/list/${modelId}`)
        .then((r) => {
          let parsedRows =
            r.code >= 400 || !r.file_memory_items ? [] : r.file_memory_items;
          setRows(parsedRows);
          if (!isRefetching) setListLoading(false);
          const pendingRows = parsedRows.filter(item => Boolean(item.task));
          if (Boolean(pendingRows.length)) {
            dispatch(setStatus({type: 'files', status: 'pending'}));
          } else if (Boolean(parsedRows.length)) {
            dispatch(setStatus({type: 'files', status: 'done'}));
          }
        })

        .catch((error) => {
          if (!isRefetching) setListLoading(false);
        });
    }
    // setData(result.data);
  };

  useEffect(() => {
    const pendingRows = rows.filter(item => Boolean(item.task));
    if (pendingRows.length) {
      const intervalId = setInterval(() => {
        fetchData(true);
      }, 10000);
    
      return () => clearInterval(intervalId);
    }
  }, [modelId, rows]);
  
  useEffect(() => {
    if (!rows.length) {
      fetchData();
    }
  }, [modelId]);

  const handleDeleteRows = (id,task_id) => {

    let url = task_id ? `task/${task_id}` : `/learning/file/${id}`

    const deleteData = async () => {
      setListLoading(true);

      await api
        .delete(url)
        .then((r) => {
          if (r.code < 400) {
            const newRows = rows.filter((row) => id != row.id);
            // TODO: use it for progress
            // if (!newRows.length) {
            //   dispatch(setProgressItem({ modelId, progress: {file_resource_type: {passed: false, skipped: false}} }));
            //   manageProgressState(modelId, 'file_resource_type', false);
            // }
            setRows(newRows);
            dispatch(updateCells({increase: false}));
          }

          setListLoading(false);
        })
        .catch((error) => {
          setListLoading(false);
        });
    };

    deleteData();
  };

  const handleDownloadRows = (id, fileName) => {
    const downloadData = async () => {
      setListLoading(true);
      await api
        .get(`/file/download/${id}`, { responseType: "blob" })
        .then(async (blob) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", fileName);
          const href = URL.createObjectURL(blob);
          aElement.href = href;
          // aElement.setAttribute('href', href);
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);
          setListLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setListLoading(false);
        });
    };

    downloadData();
  };

  const addToBrain = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    // return
    let status = checkFormForErrors(form, {
      ...fileState,
      file: {
        ...fileState.file,
        additionals: {
          ...fileState.file.additionals,
          maxAllowedFiles: maxAllowedFiles,
          totalFiles: rows.length
        }
      }
    });

    setFileState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    if (status.hasErrors) {
      return;
    }

    const params = new FormData();
    params.append("dataType", file.type);
    params.append("title", fileName);
    params.append("upload", file);

    const intValue = parseInt(modelId, 10);
    params.append("modelId", intValue);

    setFileName('');
    setDataSending(true);
    setListLoading(true);
    setResetCounter((prev) => prev + 1);

    await api
      .post("/learning/file-upload", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => {
        const { task, file } = r;

        const { id, title, data, created_at } = file;

        let status = {
          status: task.status,
          error_message: task.error_message,
        };

        handleAddRow(
          id,
          title,
          data,
          file.original_name,
          file.file_size,
          created_at,
          status
        );
        // TODO: use it for progress
        // dispatch(setProgressItem({ modelId, progress: {file_resource_type: {passed: true, skipped: false}} }));
        // manageProgressState(modelId, 'file_resource_type', true);
        setListLoading(false);
        setDataSending(false);
        dispatch(updateCells({increase: true}));
        dispatch(setStatus({type: 'files', status: 'pending'}));
      })

      .catch((error) => {
        console.log(error);
        dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        setListLoading(false);
        setDataSending(false);
      });
  };

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  }

  const handleAddRow = (
    id,
    title,
    data,
    fileName,
    fileSize,
    created_at,
    task
  ) => {
    const newRow = {
      id: id,
      title: title,
      file_content: data,
      relate_data: {
        file_size: fileSize,
        original_name: fileName,
      },
      created_at: created_at,
      task: task,
    };
    setRows([newRow, ...rows]);
  };

  const Row = (props) => {
    const { row } = props;

    let original_name = "...";
    let file_size = "...";

    if (row.relate_data) {
      original_name = row.relate_data.original_name ?? original_name;
      file_size = row.relate_data.file_size ?? file_size;
    } else if (row.file) {
      original_name = row.file.original_name ?? original_name;
      file_size = row.file.file_size ?? file_size;
    }

    const typePath = '/training/files' || '/training';
    const { date } = row.created_at;

    let status_message = "The model has been trained.";
    let status_name = "trained";
    let task_id = 0;

    if (row.task) {
      status_message = row.task.error_message;
      status_name = row.task.status;
      task_id = row.task.id;
    }
    // const status_message = row.status ? row.status.message : 'Something went wrong.';
    // const status_name = row.status ? row.status.name : 'error';

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row" className={styles.trim}>
            {row.title || row.file_name || row.relate_data.original_name}
          </TableCell>
          <TableCell component="th" scope="row">
            <Tooltip title={status_message} placement="top">
              <Typography variant={status_name + "_status"}>
                {status_name === 'pending' && <CircularProgress color="inherit" sx={{ position: 'relative', marginRight: '3px', top: '3px' }} size={14} />}
                {status_name}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: "5px" }} align="right">
            <IconButton
              onClick={() => handleDownloadRows(row.file_id, original_name)}
            >
              <DownloadIcon color="icons" />
            </IconButton>
            <IconButton
              // delete tusk if the file is  corrupted
              onClick={() => handleDeleteRows(row.id,status_name == 'error' ? task_id : null )}>
              <DeleteIcon color="icons" />
            </IconButton>
            <IconButton  onClick={() => handleRedirect(row.id,typePath)}>
              <VisibilityIcon />
            </IconButton>

          </TableCell>
        </TableRow>
      </>
    );
  };

  const startIndex = page * 10;
  const endIndex = startIndex + 10;
  const currentData = rows.slice(startIndex, endIndex);

  return (
    <Grid container justifyContent="center" rowSpacing={6}>
      {/* <Grid item xs={12} md={12}>
      <Stack direction="row" spacing={10}>
        <Chip label="documents" color="primary" variant="outlined"  size="small" />
        <Badge badgeContent={"experimental"} color="primary" size="small">
          <Chip label="spreadsheets" color="primary" variant="outlined"  size="small" />
        </Badge>
        <Chip label="presentations" color="primary" variant="outlined"  size="small" />
        <Badge badgeContent={"experimental"} color="primary" size="small">
        <Chip label="code" color="primary" variant="outlined"  size="small" />
        </Badge>
      </Stack>


      </Grid> */}
      <Grid item xs={12} md={12}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="File Format"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              '& .MuiTabs-flexContainer': {justifyContent: 'flex-start', gap: '8px'},
            },
            [theme.breakpoints.up("sm")]: {
              '& .MuiTabs-flexContainer': {justifyContent: 'center', gap: '8px'},
            },
            '& .MuiButtonBase-root': {fontSize: '12px', padding: '16px 4px 12px', overflow: 'visible'} 
          })}
        >
          {supportedFormats.map((item, i) =>  {
            if (!i) {
              return (
                <Tab key={`file-tab-${i}`} label={item.title} />
              )
            }
            const isExperimental = !item.formats.includes('.xlsx');
            return (
              <Tab key={`file-tab-${i}`} label={<Badge
                badgeContent={isExperimental ? 'Experimental' : null}
                color="secondary"
                sx={{
                  "MuiBadge-root": {
                    marginBottom: 0
                  },
                  "& .MuiBadge-badge": {
                    fontSize: '9px',
                    height: '16px',
                    top: '-11px',
                    right: '20px',
                  }
                }}>{item.title}</Badge>} />
            )
          })}
        </Tabs>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        container
        rowSpacing={6}
        onSubmit={addToBrain}
        noValidate
        component="form"
        sx={{ maxWidth: "500px !important", margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          id="files-tool-opened-page"
        >
          <FileInput
            supportedFormats={supportedFormats}
            selectedTab={selectedTab}
            setSpreadsheetsTab={() => setSelectedTab(1)}
            onChange={(data) => {
              setFile(data || null);
              if (data) {
                setFileName(data?.name || '');
                fileNameInput.current.focus();
                // trigger a synthetic change event
                // const event = new Event('input', { bubbles: true });
                // fileNameInput.current.dispatchEvent(event);
              } else {
                setFileName('');
              }
            }}

            resetCounter={resetCounter}
          />

          {fileState.file.errMsg !== "" && (
            <FormHelperText error>{fileState.file.errMsg}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={12} justifyContent="center">
          <TextField
            value={fileName}
            name="title"
            inputRef={fileNameInput}
            label="Enter Title Name.."
            fullWidth
            onChange={(e) => {
              setFileName(e.target.value)
            }}
            error={fileState.title.errMsg != ""}
            helperText={fileState.title.errMsg ? fileState.title.errMsg : ""}
          />
        </Grid>

        <Grid display="flex" justifyContent="center" item md={12} xs={12}>
          <SendDataButton
            type="submit"
            isListLoading={isListLoading}
            // onClickFunc={addToBrain}
            isDataSending={isDataSending}
          />
        </Grid>
      </Grid>

      {/* <Divider sx={{ width: "80%", margin: "0 auto",padding: '30px 0' }}/> */}
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            fixedheader="true"
            fixedfooter="true"
          >
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell /> */}
                <TableCell align="right" sx={{ paddingRight: "20px" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isListLoading ? (
                <>
                  {[...Array(4)].map((_, i) => (
                    <TableRow key={`wave-loading-${i}`}>
                      <TableCell key={``} colSpan={7} align="center">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))}

                </>
              ) : rows ? (
                currentData.map((row) => <Row key={row.id} row={row} />)
              ) : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
      </Grid>
  );
};

export default FilesTraining;
