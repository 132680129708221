// start of code change [toursSlice.js]
import { createSlice } from '@reduxjs/toolkit';
import { getToursThunk, updateTourStepThunk } from './toursThunk';

const initialState = {
  tours: [],
  loading: false,
  error: null,
};

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // handle getToursThunk
    builder
      .addCase(getToursThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getToursThunk.fulfilled, (state, action) => {
        state.loading = false;
        // server returns { tours: [...] }
        state.tours = action.payload.tours;
      })
      .addCase(getToursThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // handle updateTourStepThunk
    builder
      .addCase(updateTourStepThunk.pending, (state) => {
        // no loading UI required if you only want minimal changes
      })
      .addCase(updateTourStepThunk.fulfilled, (state, action) => {
        // server returns updated tours: e.g. { tours: [...] }
        const updatedTours = action.payload.tours;
        // Overwrite or merge
        state.tours = updatedTours;
      })
      .addCase(updateTourStepThunk.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default toursSlice.reducer;
// end of code change
