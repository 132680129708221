import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import MenuIcons from "../../../../icons/menuIcons";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useState} from "react";
import {useStore} from "react-redux";
import IosShareIcon from "@mui/icons-material/IosShare";
import {useNavigate} from "react-router-dom";
import {sendUIEvent} from "../../../../../events/analytics/google";

const ShareModelListBtn = ({setShareModelConfig, onClickCallback}) => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleRedirect = (e,id) => {
        navigate(`/share/${id}`);
        // setShareModelConfig((prev) => ({
        //     ...prev,
        //     isExpanded: true,
        //     shareType: id
        // }));
        onClickCallback();

        sendUIEvent({name: `share_model_${id}_nav_click`});
    };

    return (
        <>
            <ListItemButton
                sx={{pl: 6}}
                onClick={() => {
                    setOpen(prev => !prev);
                    const label = open ? 'closed' : 'opened';
                    sendUIEvent({name: `share_model_nav_click_${label}`});
                }}
                id="share-ai-model"
            >
                <ListItemIcon sx={{minWidth: "40px"}}>
                    <IosShareIcon color="icons" width="20" height="20" sx={{position: 'relative', left: '-2px'}}/>
                </ListItemIcon>
                <ListItemText primary="Share AI Model"/>
                {open ? (
                    <ExpandLessIcon fontSize="small"/>
                ) : (
                    <ExpandMoreIcon fontSize="small"/>
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        id="public"
                        sx={{pl: 6}}
                        onClick={(e)=> handleRedirect(e,'public')}
                    >
                        <ListItemIcon sx={{minWidth: "40px"}}/>
                        <ListItemText primary={'Public'}/>

                    </ListItemButton>
                    <ListItemButton
                        id="restricted"
                        sx={{pl: 6}}
                        onClick={(e)=> handleRedirect(e,'restricted')}
                    >
                        <ListItemIcon sx={{minWidth: "40px"}}/>
                        <ListItemText primary={'Restricted'}/>

                    </ListItemButton>
                    <ListItemButton
                        id="chat-bot"
                        sx={{pl: 6}}
                        onClick={(e)=> handleRedirect(e,'chat-bot')}
                    >
                        <ListItemIcon sx={{minWidth: "40px"}}/>
                        <ListItemText primary={'Chatbot'}/>
                    </ListItemButton>
                    <ListItemButton
                        id="monetization"
                        sx={{pl: 6}}
                        onClick={(e)=> handleRedirect(e,'monetization')}
                    >
                        <ListItemIcon sx={{minWidth: "40px"}}/>
                        <ListItemText primary={'For a fee'}/>

                    </ListItemButton>
                    <ListItemButton
                        id="customer-support"
                        sx={{pl: 6}}
                        onClick={(e)=> handleRedirect(e,'speak-to-product')}
                    >
                        <ListItemIcon sx={{minWidth: "40px"}}/>
                        <ListItemText primary={'Speak to Product'}/>

                    </ListItemButton>
                </List>
            </Collapse>
        </>
    )
}

export default ShareModelListBtn;