import React, { forwardRef } from 'react';
import { Button, Link } from '@mui/material';

const CustomTooltip = forwardRef(({
  // Joyride props
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  isLastStep,
  index,
  size,
  step,
  continuous,
  // Add our custom prop for disabling all tours
  onDisableAllTours,
  // rest of Joyride tooltip props
  tooltipProps, // includes the container ref
}, ref) => {
  
  // Buttons text from Joyride's built-in i18n system
  const { title: backLabel } = backProps;
  const { title: closeLabel } = closeProps;
  const { title: primaryLabel } = primaryProps;
  const { title: skipLabel } = skipProps;
  
  const handleDisableClick = (e) => {
    e.preventDefault();
    // Immediately disable all tours without confirmation
    if (onDisableAllTours) {
      onDisableAllTours();
      // Dispatch the custom event so other components can update their state
      window.dispatchEvent(new Event('tours:setting:changed'));
    }
  };
  
  return (
    <div
      ref={ref}
      {...tooltipProps}
      className="react-joyride__tooltip"
      style={{
        backgroundColor: '#fff',
        color: '#333',
        borderRadius: 4,
        padding: 12,
        maxWidth: 400,
        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
      }}
    >
      {/* STEP TITLE (if any) */}
      {step.title && (
        <div className="react-joyride__tooltip--title" style={{ fontWeight: 'bold', marginBottom: 8 }}>
          {step.title}
        </div>
      )}

      {/* STEP CONTENT */}
      <div className="react-joyride__tooltip--content" style={{ marginBottom: 12 }}>
        {step.content}
      </div>

      {/* FOOTER AREA FOR BUTTONS */}
      <div className="react-joyride__tooltip--footer" style={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        flexWrap: 'wrap' 
      }}>
        {/* Skip button moved to LEFT */}
        <div>
          <Button
            {...skipProps}
            variant="outlined"
            size="small"
            color="inherit"
            className="react-joyride__button--skip"
            sx={{ color: 'text.secondary' }}
          >
            {skipLabel || 'Skip'}
          </Button>
        </div>

        {/* Right side buttons */}
        <div style={{ display: 'flex', gap: '8px' }}>
          {/* If not first step => show "Back" button */}
          {index > 0 && (
            <Button
              {...backProps}
              variant="outlined"
              size="small"
              className="react-joyride__button--back"
            >
              {backLabel || 'Back'}
            </Button>
          )}

          <Button
            {...primaryProps}
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: '#4caf50', color: '#fff' }}
            className="react-joyride__button--primary"
          >
            {primaryLabel || 'Next'}
          </Button>
        </div>
      </div>
      
      {/* Subtle link to disable all tours - now with immediate action */}
      <div style={{ 
        textAlign: 'center', 
        marginTop: 12, 
        fontSize: '0.75rem', 
        opacity: 0.6
      }}>
        <Link 
          href="#" 
          onClick={handleDisableClick} 
          underline="hover"
          sx={{ color: 'text.secondary' }}
        >
          Don't show educational tooltips again
        </Link>
      </div>
    </div>
  );
});

export default CustomTooltip;