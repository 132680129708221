import {useState, useCallback, memo, useMemo} from "react";
import {Box, List, ListItem, ListItemButton, ListItemText, TextField, Typography, Divider} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const commands = [
    {
        title: 'Files',
        hash: 'files',
    }, {
        title: 'Settings',
        hash: 'settings',
    },
    {
        title: 'Connected apps',
        hash: 'custom-tools',
    }
];

const SearchList = ({handleSelectItem, smallScreen, isFilesAllowed}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredItems = useMemo(() =>
            commands.filter(item => item.title.toLowerCase().includes(searchTerm)),
        [commands, searchTerm]
    );

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value.toLowerCase());
    }, []);

    const desktopStyling = {
        borderRadius: '4px',
        boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, ' +
            'rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, ' +
            'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
    };

    return (
        <List id="search-list" sx={smallScreen ? {} : desktopStyling}>
            <ListItem
                secondaryAction={<Typography sx={{
                    padding: "4px 10px",
                    backgroundColor: "#eee",
                    borderRadius: "14px",
                    opacity: 0.8,
                    position: "relative",
                    right: "-6px",
                }} variant="describe_text">/</Typography>}>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    size="small"
                    // margin="normal"
                    onChange={handleSearchChange}
                />
            </ListItem>
            <Box sx={{maxHeight: "225px", overflowY: "scroll"}}>
                {filteredItems.length == 0 ?
                    <ListItem id="no-options">
                        <ListItemText primary={<Typography variant="describe_text">0 options found</Typography>}/>
                    </ListItem>
                    :
                    filteredItems.map((item, i) => (
                            <Box key={`command-${i}`}>
                                <ListItemButton
                                    onClick={() => handleSelectItem(item)}>
                                    <ListItemText primary={item.title} secondary={`/${item.hash}`} sx={{flex: 'none'}}/>

                                </ListItemButton>
                                {filteredItems.length - 1 !== i && <Divider sx={{margim: '10px 0'}}/>}
                            </Box>
                        )
                    )
                }
            </Box>
        </List>
    )
};

export default memo(SearchList, (prevProps, nextProps) => {
    return prevProps.modelId === nextProps.modelId;
});