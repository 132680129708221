import SendIcon from "@mui/icons-material/Send";
import {Box, Button, CardActionArea, Fade, Slide, Tooltip, Typography, Avatar} from "@mui/material";
import Card from "@mui/material/Card";
import data, {randomWelcomeMessage} from "mappings/chatHintsMessages";
import {Fragment, memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from '../chat-hints.module.css';
import Carousel from "react-material-ui-carousel";
import Typewriter from "../../../../Onboarding/components/Typewriter";
import {DynamicLogo} from "components/loadings/BrandLoading";
import classnames from "classnames";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import {getHintsList} from "redux/models";
import SmoothContent from "components/loadings/SmoothContent";
import {shuffleArray} from "utils/arrayManipulation";
import {sendUIEvent} from "../../../../../../events/analytics/google";


const HintsLoadingSkeletonMobile = () => {

    return (
        <Box className={styles['hints-container-mobile']}>
            <Box sx={{minWidth: '80%'}}>
                <SmoothContent loading={true} width={'100%'} height={80}>
                    <Card className={styles['hint-card']}/>
                </SmoothContent>
            </Box>
            <Box sx={{marginRight: '15px'}}></Box>
            <Box sx={{minWidth: '270px'}}>
                <SmoothContent loading={true} width={270} height={80}>
                    <Card className={styles['hint-card']}/>
                </SmoothContent>
            </Box>
        </Box>
    )
}

const HintsLoadingSkeleton = () => {

    return (
        <Box className={styles['grouped-container']} spacing={10} display="flex"
             justifyContent="space-between"
             sx={{width: '100%'}}>
            <SmoothContent loading={true} height={80}>
                <Card className={styles['hint-card']}/>
            </SmoothContent>
            <Box sx={{marginRight: '15px'}}></Box>
            <SmoothContent loading={true} height={80}>
                <Card className={styles['hint-card']}/>
            </SmoothContent>
            <Box sx={{marginRight: '15px'}}></Box>
            <SmoothContent loading={true} height={80}>
                <Card className={styles['hint-card']}/>
            </SmoothContent>
        </Box>
    )
}

const INITIAL_STAGE = 1;
const FINAL_STAGE = 3;
const LOADING_DELAY = 400;


const HomeBrain = memo(({stage = 1, setStage, envState = 'messages'}) => {

    const loadingStage = useSelector(state => state[envState].session.loading.stage);
    // const isMsgSending = useSelector(state => state[envState].session.loading.isMsgSending);
    const tickNum = useSelector(state => state[envState].session.loading.tick);

    const loadingIndex = loadingStage === 2 ? 2 : stage;


    useEffect(() => {
        setStage(INITIAL_STAGE);
        const timeout = setTimeout(() => {
            setStage(FINAL_STAGE);
        }, LOADING_DELAY);
        return () => {
            clearTimeout(timeout);
            // setStage(INITIAL_STAGE)
        };
    }, []);


    return <DynamicLogo size={"large"} tickNum={tickNum} stage={loadingIndex} isLoading={false}/>
});


const TryButton = ({message, sent, onTry}) => {
    const tryMessage = (e) => {
        onTry(e, message);
    };
    return (
        <Button
            color="primary"
            onClick={tryMessage}
            variant="text"
            size="small"
            endIcon={<SendIcon/>}
            disabled={sent}
            sx={{alignSelf: "flex-end"}}
        >
            Try
        </Button>
    );
};

// const HintsListDesktop = () => {
//
//     return data.map((item) => (
//         <HintCard key={`item-hints-${item.type}}`} onTry={onTry} item={item} sent={isMsgSending}/>
//     ))
//
// }

const AnimatedHints = ({displayChildren = false, children}) => {

    return (
        <Slide sx={{width: '100%', minHeight: '72px'}} direction="up" in={displayChildren} timeout={1500}>
            <Box>{children}</Box>
        </Slide>
    )

}

const HintCard = ({onTry, item, minWidth = '150px'}) => {


    const handleHintAction = (e) => {
        onTry(e, item)
    }


    return (
        <Card
            sx={{
                minWidth: minWidth
                // margin: '0 8px 0',
                // minWidth: 100,
                // maxWidth: 275,
                // minHeight: 60,
                // maxHeight: 160,
                // height: "100%",
                // position: "relative"
            }}
            className={styles['hint-card']}


        >
            {/*<CardContent sx={{*/}
            {/*    padding: "15px 15px 15px !important",*/}
            {/*    // display: "flex",*/}
            {/*    // justifyContent: "space-between",*/}
            {/*    // flexDirection: "column",*/}
            {/*    // height: "100%"*/}
            {/*}}>*/}

            {/*<Typography*/}
            {/*    sx={{fontSize: 9}}*/}
            {/*    color="text.secondary"*/}
            {/*    gutterBottom*/}
            {/*>*/}
            {/*    {item.type}*/}
            {/*</Typography>*/}
            <CardActionArea sx={{padding: "15px 15px 15px !important"}} onClick={handleHintAction}>
                <Tooltip arrow title={item} placement="top" enterDelay={700} TransitionComponent={Fade}
                         slotProps={{
                             popper: {
                                 modifiers: [
                                     {
                                         name: 'offset',
                                         options: {
                                             offset: [0, 20],
                                         },
                                     },
                                 ],
                             },
                         }}
                         TransitionProps={{timeout: 200}}>
                    <Typography className={styles['card-content']} variant="cards_title" component="div">
                        {item}
                    </Typography>
                </Tooltip>
            </CardActionArea>

            {/*<TryButton onTry={onTry} message={item.content} sent={isMsgSending}/>*/}
            {/*</CardContent>*/}
        </Card>


    )
}

const HintCardMobile = ({onTry, item, minWidth = '150px'}) => {


    const handleHintAction = (e) => {
        onTry(e, item)
    }


    return (
        <Card
            sx={{
                minWidth: minWidth
                // margin: '0 8px 0',
                // minWidth: 100,
                // maxWidth: 275,
                // minHeight: 60,
                // maxHeight: 160,
                // height: "100%",
                // position: "relative"
            }}
            className={styles['hint-card']}


        >
            {/*<CardContent sx={{*/}
            {/*    padding: "15px 15px 15px !important",*/}
            {/*    // display: "flex",*/}
            {/*    // justifyContent: "space-between",*/}
            {/*    // flexDirection: "column",*/}
            {/*    // height: "100%"*/}
            {/*}}>*/}

            {/*<Typography*/}
            {/*    sx={{fontSize: 9}}*/}
            {/*    color="text.secondary"*/}
            {/*    gutterBottom*/}
            {/*>*/}
            {/*    {item.type}*/}
            {/*</Typography>*/}
            <CardActionArea sx={{padding: "15px !important"}} onClick={handleHintAction}>

                <Typography className={styles['card-content']} variant="cards_title_mobile" component="div">
                    {item}
                </Typography>

            </CardActionArea>

            {/*<TryButton onTry={onTry} message={item.content} sent={isMsgSending}/>*/}
            {/*</CardContent>*/}
        </Card>


    )
}

const HintsListDesktop = memo(({data, onTry, isMsgSending}) => {
    return data.map((item) => (
        <HintCard key={`item-hints-${item.type}`} onTry={onTry} item={item} sent={isMsgSending}/>
    ));
});

const HintsListMobile = memo(({hints = [], onTry, isMsgSending}) => {

    return (
        <Box className={styles['hints-container-mobile']}>
            {hints.map((item, id) => (
                // <Box sx={{display: 'flex', overflowX: 'scroll', width: '100%', flexFlow: 'row nowrap'}}>
                <HintCardMobile minWidth={'150px'} key={`item-hints-${id}`} onTry={onTry} item={item}
                                sent={isMsgSending}/>
                // <HintCardMobile key={`item-hints-${item.type}`} onTry={onTry} item={item} sent={isMsgSending}/>
            ))}
        </Box>
    )
});


const HintsListDesktopCaraousel = memo(({hints = [], hintsContainer, onTry, isMsgSending}) => {


    const SLIDE_DURATION_MS = 1100;
    const HINTS_NUM_THRESHOLD = 6;
    const [hintsNum, setHintsNum] = useState(0);
    const [groupIndex, setGroupIndex] = useState({id: 0, disabled: false, timeoutId: null})


    const groupedArray = useMemo(() => {

        if (hintsNum === 0) {
            return [];
        }

        const dataLength = hints.length || 0;

        let tempArray = [];
        for (let i = 0; i < dataLength; i += hintsNum) {

            const group = hints.slice(i, i + hintsNum);

            tempArray.push(group);
        }

        return tempArray;

    }, [hintsNum, hints, hints.length]);

    const groupedHintsLen = groupedArray.length || 0;

    const calculateHintsItems = (width) => {

        const minimumHintWidth = 200;
        // const minimumHintWidth = 150;
        const spacesWidth = 15;

        try {

            const rawItemsNum = parseInt(width) / (minimumHintWidth + spacesWidth);
            const formattedNum = Math.floor(rawItemsNum);
            setHintsNum(formattedNum);
        } catch {

        }

    }

    useEffect(() => {

        if (hintsContainer.current) {
            const width = hintsContainer.current.clientWidth;
            calculateHintsItems(width)
        }
    }, []);

    if (hintsNum === 0) {
        return;
    }


    // const SortedItems = ({customItems, hintsNum}) => {
    //
    //     // customItems.map((item, id) =>
    //
    //
    //     const SortedGroup = (i) => {
    //
    //     }
    //
    //     return
    //
    //         customItems.map((item, id) => (
    //             <Box key={`item-hints-${id}`} display={"flex"} justifyContent="center" sx={{width: '100%'}}>
    //                 <HintCard onTry={onTry} item={item[i]} sent={isMsgSending}/>
    //             </Box>)
    //         ))
    //
    //
    // }

    // const AnimatedHintContainer = ({id, children, checked}) => {
    //     return <Grow in={checked}>{children}</Grow>
    // }


    const handleSlide = (e, getNextIndex) => {
        e.preventDefault();

        if (groupIndex.disabled) {
            return;
        }

        if (groupIndex.timeoutId) {
            clearTimeout(groupIndex.timeoutId);
        }

        const timeoutId = setTimeout(() => {
            setGroupIndex(prevState => ({...prevState, disabled: false, timeoutId: null}));
        }, SLIDE_DURATION_MS);

        setGroupIndex(prevState => {
            const {id} = prevState;
            const newId = getNextIndex(id);
            return {id: newId, disabled: true, timeoutId: timeoutId};
        });
    }

    const handlePrevSlide = (e) => {
        handleSlide(e, (id) => id === 0 ? groupedHintsLen - 1 : id - 1);
        sendUIEvent({name: `chat_hints_left_arrow_click`});
    }

    const handleNextSlide = (e) => {
        handleSlide(e, (id) => id === groupedHintsLen - 1 ? 0 : id + 1);
        sendUIEvent({name: `chat_hints_right_arrow_click`});
    }

    return (
        <Box
            // ref={hintsContainer}
            sx={{position: 'relative', display: 'flex', alignItems: 'center'}}>
            {/*<IconButton onClick={handlePrevSlide}*/}
            {/*            sx={{color: '#757575', position: 'absolute', left: '-45px', opacity: '0.5'}}>*/}
            <ArrowBackIosIcon
                className={classnames(styles['hints-controls'], styles['left'])}
                onClick={handlePrevSlide}
                fontSize="small"/>
            {/*</IconButton>*/}
            <Carousel

                index={groupIndex.id}
                height={72}
                fullHeightHover={false}
                autoPlay={false}
                // stopAutoPlayOnHover={true}
                duration={SLIDE_DURATION_MS}
                // NavButtonsWrapperProps={{
                //     style: {
                //         position: 'absolute',
                //         top: '50%',
                //         transform: 'translateY(-50%)',
                //         zIndex: 1000,
                //         display: 'flex',
                //         justifyContent: 'space-between',
                //         width: '100%',
                //         opacity: 0.2
                //     },
                // }}
                // navButtonsProps={{
                //     style: {
                //         backgroundColor: 'transparent',
                //         borderRadius: '50%',
                //
                //     },
                // }}
                // // ButtonGroupProps={{
                // //     children: null
                // // }}
                //  stopAutoPlayOnHover={true}
                // //
                // navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={true}

                sx={{
                    width: '100%',
                    height: '100%',
                    minHeight: {xs: '72px', sm: '72px', md: '72px'},
                    display: 'flex',
                    alignItems: 'center'
                }}
                // onChange={handleSlideChange}
                indicators={false}
                animation='slide'
                interval={6000}


            >

                {groupedArray.map((item, id) => (
                    <Box className={styles['grouped-container']} spacing={2} key={`item-hints-${id}`} display="flex"
                         justifyContent={hintsNum === 1 ? "center" : "space-between"}
                         sx={{width: '100%'}}>
                        {item.map((hint, index) => (
                            <HintCard key={`hint-card-${index}`} onTry={onTry} item={hint} sent={isMsgSending}/>
                        ))}
                    </Box>
                ))}
                {/*{customItems.map((item, id) => {*/}
                {/*    */}
                {/*    return (*/}
                {/*        <Box key={`item-hints-${id}`} display={"flex"} justifyContent="center" sx={{width: '100%'}}>*/}
                {/*            <HintCard onTry={onTry} item={item[0]} sent={isMsgSending}/>*/}
                {/*            <HintCard onTry={onTry} item={item[1]} sent={isMsgSending}/>*/}
                {/*            <HintCard onTry={onTry} item={item[2]} sent={isMsgSending}/>*/}
                {/*        </Box>*/}
                {/*    )*/}
                {/*})}*/}
            </Carousel>
            {/*<IconButton onClick={handleNextSlide}*/}
            {/*            sx={{color: '#757575', position: 'absolute', right: '-30px'}}>*/}
            <ArrowForwardIosIcon
                className={classnames(styles['hints-controls'], styles['right'])}
                onClick={handleNextSlide}
                fontSize="small"/>
            {/*</IconButton>*/}
        </Box>
    )
});


const WelcomeMessage = memo(({chatStarted = false, userName = "", envState, modelData = null}) => {


    const [animationEnded, setAnimationEnd] = useState({firstLine: false, secondLine: false});
    const [stage, setStage] = useState(INITIAL_STAGE);
  

    // const firstName = useMemo(() => {
    //
    //     return userName.split(' ')[0];
    //
    // }, [userName])


    const {firstLine, secondLine} = useMemo(() => {

        const firstName = userName.split(' ')[0];
        if (Boolean(modelData?.model.qr?.id)) {
          return {
            "firstLine": `Hi there!`,
            "secondLine": "How can I help you today?"
          }
        }

        return randomWelcomeMessage(firstName);

    }, [userName])


    const dispatch = useDispatch();

    // const setStage = (stage) => {
    //     setLoadingStageAction(dispatch,stage,envState);
    // }
    const handleFirstLineEnd = () => {
        setAnimationEnd(prevState => ({...prevState, firstLine: true}));
    }

    const handleSecondLineEnd = () => {
        setStage(INITIAL_STAGE)
        setAnimationEnd(prevState => ({...prevState, secondLine: true}));
    }

    return <Box className={classnames(chatStarted && styles['new-chat-start'], styles['welcome-window'])} sx={{ paddingTop: '20px' }}>
        {/*<LogoSvgIcon width={100} height={72.5}/>*/}

        {/*<Fade in={!chatStarted} timeout={1000}>*/}
        {/*<Box>*/}
        <HomeBrain stage={stage} setStage={setStage} envState={envState}/>
        {/*</Box>*/}
        {/*</Fade>*/}
        <Typography variant="welcome_header"
            id={localStorage.getItem('isMobileDevice') === 'true' ? 'welcome-message-mobile' : 'welcome-message-desktop'}
                    sx={{textAlign: animationEnded.secondLine ? 'center' : 'center', minHeight: '150px'}}>
            <Typewriter text={firstLine} animationSpeed={30} animationDelay={2000}
                        onTypingEnd={handleFirstLineEnd}/>

            <br/>
            {/*{animationEnded &&*/}
            {animationEnded.firstLine &&
                <Typewriter text={secondLine} animationSpeed={30} animationDelay={400} cursorDelay={100}
                            onTypingEnd={handleSecondLineEnd}/>}
            {/*}*/}
        </Typography>
        {Boolean(modelData) && (
          <Box sx={{ width: '100%', textAlign: 'center', paddingTop: {xs: '20px', md: '40px'} }}>
            {Boolean(modelData?.model.qr?.id) && (
              <Box sx={{ textAlign: 'center', marginBottom: '5px' }}>
                <Avatar src={modelData?.model?.avatar ?? ''} sx={{ width: '30px', height: '30px', margin: '0 auto' }}>{modelData?.model?.name?.substring(0, 1)}</Avatar>
              </Box>
            )}
            <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, marginBottom: '5px' }}>{modelData.model?.name}</Typography>
            {Boolean(modelData.model?.mini_description) && (
              <Typography sx={{ fontSize: '1rem', fontWeight: 400, marginBottom: '6px', fontSize: '0.875rem' }}>{modelData.model?.mini_description}</Typography>
            )}
            {!Boolean(modelData?.model.qr?.id) && (
              <Box display='flex' alignItems='center' justifyContent='center' gap={2} sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 400, color: '#b2b2b2' }}>By:</Typography>
                <Avatar sx={{width:'26px',height:'26px', border:'1px solid #00000011', fontSize: '0.875rem'}} src={modelData.ownerInfo?.avatar ?? ''}>{modelData.ownerInfo?.initial}</Avatar>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 400, color: '#b2b2b2' }}>{modelData.ownerInfo?.fullName}</Typography>
              </Box>
            )}
          </Box>
        )}
    </Box>
});


const ResponsiveHints = memo(({sharedModelId = undefined, isMsgSending = false, onTry, hintsContainer, modelData}) => {

    const HINTS_NUM_THRESHOLD = 6;

    const {smallScreen} = useIsSmallScreen();
    const dispatch = useDispatch();
    const hints = useSelector(state => state.models.hints, shallowEqual);
    const currentModelId = useSelector(state => state.models.current.id, shallowEqual);

    const modelId = sharedModelId || currentModelId;


    const dataArr = useMemo(() => {

        if(!hints.list[modelId]) {
            return []
        }


        let tempArr = hints.list[modelId];
        if (tempArr.length < HINTS_NUM_THRESHOLD) {
            tempArr = tempArr.concat(data).slice(0, HINTS_NUM_THRESHOLD);
        }
        return shuffleArray([...tempArr])
    }, [hints.list[modelId]]);


    useEffect(() => {
        let controller;

        if (typeof hints.list[modelId] === 'undefined') {
            controller = new AbortController();
            const signal = controller.signal;
            dispatch(getHintsList({modelId: modelId, signal: signal}))
        }

        return () => {
            controller && controller.abort();
        }

    }, [modelId]);

    const isReady = !hints.loading && dataArr.length !== 0;



    // if (smallScreen && (hints.loading || typeof hints.list[modelId] === 'undefined') ) {
    //     return <></>
    //     // <HintsLoadingSkeletonMobile/>
    // }
    //
    // if (hints.loading || typeof hints.list[modelId] === 'undefined') {
    //     return <></>
    //     // <HintsLoadingSkeleton/>
    // }


    if (smallScreen) {
        return <AnimatedHints displayChildren={!isMsgSending && isReady}><HintsListMobile hints={hints.list[modelId]}
                                                                                          onTry={onTry}/></AnimatedHints>;
    }

    return <AnimatedHints displayChildren={!isMsgSending && isReady}><HintsListDesktopCaraousel
        hints={hints.list[modelId]} hintsContainer={hintsContainer} onTry={onTry}/></AnimatedHints>
});

const  NewChatHints = ({
                          onTry = () => {
                          }, userName = 'there',
                          envState = 'messages',
                          sharedModelId = undefined,
                          modelData,
                      }) => {
    const hintsContainer = useRef(null);

    const isMsgSending = useSelector(state => state.messages.session.loading.isMsgSending);


    const tryHint = useCallback((e, item) => {
        // setChatStarted(true);
        onTry(e, item)
        sendUIEvent({name: `try_chat_hints_click`});
    }, [])


    return (
        <Box data-testid="hint-prompts" className={styles['hints-container']}>
            <WelcomeMessage userName={userName} chatStarted={isMsgSending} envState={envState} modelData={modelData} />
            <Box spacing={4}
                 ref={hintsContainer}
                 sx={{
                     width: '100%',
                     // marginBottom: {xs: 0, md: "15px"},
                     // marginTop: '15px'
                 }}
            >
                <ResponsiveHints sharedModelId={sharedModelId} isMsgSending={isMsgSending} hintsContainer={hintsContainer} onTry={tryHint}/>


                {/*<HintsListDesktop data={data} onTry={onTry} isMsgSending={isMsgSending}/>*/}
            </Box>
        </Box>
    );
};

export default memo(NewChatHints);
