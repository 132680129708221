// import React from "react";
import {useEffect, useState, useRef, useMemo, memo, useCallback} from "react";
import {ClickAwayListener, Box, TextField, IconButton, Grid, styled, Typography, Button, Skeleton, Link,Tooltip } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styles from "./playground.module.css";
import SendIcon from "@mui/icons-material/Send";
import ChatConversation from "components/ChatConversation";
import NewChatHints from "./components/NewChatHints";
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {getWordsFromString, getInitials} from "utils/prepareNames";
import {pushChat, setStatus} from "redux/chats";
import {
    addTempMessage,
    addMessage,
    getList,
    deleteAll,
    setLastResponseSources,
    setToolsResults,
    cleanUpData,
    loadMore,
    setMessageLoading, brainTickUpdate, updateToolCallStatus, cleanStack, popFromToolCallStack, addToolCallToStack,
} from "redux/messages";
import {manualLock, setJsonData} from "redux/auth";
import StopIcon from "@mui/icons-material/Stop";
import ClearIcon from '@mui/icons-material/Clear';
import createStream from "_services/stream";
import useViewportHeight from "hooks/useViewportHeight";
import {encodeData} from "utils/formatContent";
import ChatMenu from "components/menus/helperMenu/ChatMenu";
import {interactWithUser} from "redux/status";
import controlledAPI from "_services/controlledApi";
import api from "_services/api";
import useDeviceDetect from "hooks/useDetectDevice";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import chatLabels from "mappings/languages/en/chatLabels.json";
import modelLabels from "mappings/languages/en/modelLabels.json";
import {updateCredits} from "redux/billing";
import statusMessages from "mappings/languages/en/statusMessages.json";
import FileSelector from "components/inputs/dialogs/FileSelector";
import Settings from "components/inputs/dialogs/Settings";
import Commands from "components/inputs/dialogs/Commands";
import usePermissions from "hooks/usePermissions";
import AttachmentLinks from "components/showcase/AttachmentsLinks";
import {changeFilePrices} from "redux/tools";
import {setSource} from 'redux/chats';
import {addModel} from 'redux/models';
import InteractWithUser from 'components/userInteractions/InteractWithUser';
import AccessChecker from "components/security/AccessChecker";
import useGetAllBrainFiles from 'hooks/useGetAllBrainFiles';
import MiddleBrain from "components/inputs/dialogs/MiddleBrain";
import ResponsiveChatsMenu from "components/menus/helperMenu/ChatMenu/components/ResponsiveChatsMenu";
import initial from "../../Onboarding/steps/Initial";
import useWriteAnimation from "hooks/useWriteAnimation";
import Chat from 'pages/ShareModel/components/Chat';
import Content from 'pages/ShareModelMarketplace/components/Content';
import {sendUIEvent} from "../../../../events/analytics/google";
import {ENHANCED_GPT_MODEL} from "constants";
import CustomTools from "../../../../components/inputs/dialogs/CustomTools";
import MessageStructureSkeleton from "components/loadings/templates/MessageStructureSkeleton";
import stylesChatConversation from "components/ChatConversation/chat-conversation.module.css";

import classnames from 'classnames';

var abortController = new AbortController();
let fileUploadAbortController = new AbortController();

const CustomTextField = styled(TextField)`
    & .MuiInputLabel-root {
        top: 9px;
        opacity: 0.6;
        width: calc(100% - 84px);
    }

    & .MuiInputLabel-shrink {
        white-space: nowrap;
        left: 0 !important;
        top: 0 !important;
        opacity: 1;
        width: 100%;
    }
`;
// some of the components are here now, later they will be updated within whole playground optimization

const Playground = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const viewportHeight = useViewportHeight();
    const [isNewChat, setIsNewChat] = useState(false);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [mobileHelperShow, setMobileHelperShow] = useState(true);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [searchSource, setSearchSource] = useState("Thinking...");
    const [fileSources, setFileSources] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [commandPrompt, setCommandPrompt] = useState(false);
    const {handleGetAllBrainFiles, isGettingAllBrainFiles} = useGetAllBrainFiles();
    const fileInputRef = useRef(null);
    const [inputFiles, setInputFiles] = useState([]);  // For files
    const [inputImages, setInputImages] = useState([]);  // For images
    const [sourcesIds, setSourcesIds] = useState([]);  // For sources
    const paramFileIds = useRef({});  // For uploaded files

    // for immediate access
    const inputFilesRef = useRef([]);   // For files
    const inputImagesRef = useRef([]);  // For images

    const sources = useMemo(() => {
        return Object.values(chatLabels.labels.sources);
    }, []);

    const {contentWriteAnimation, cleanContentWriteAnimation} = useWriteAnimation(sources, setSearchSource);

    const {isMobileDevice} = useDeviceDetect();
    const {smallScreen} = useIsSmallScreen();

    const handleChangeMobileHelperShow = useCallback((e) => {
        setMobileHelperShow(e);
    }, []);

    const model = useSelector(state => state.models.current, shallowEqual);
    const messages = useSelector((state => state.messages), shallowEqual);
    const selectedModel = useSelector(state => state.models.selectedModel, shallowEqual);
    const chats = useSelector(state => state.chats, shallowEqual);
    const aiMessage = useSelector(state => state.messages.session.ai);
    const user = useSelector(state => state.auth.user, shallowEqual);
    const auth = useSelector(state => state.auth, shallowEqual);
    const cards = useSelector(state => state.marketplace.list);
    const isMsgSending = useSelector(state => state.messages.session.loading.isMsgSending);
    const sharedChats = useSelector(state => state.chats.sharedChats.items, shallowEqual);
    const isSharedChatSelected = useSelector(state => state.chats.source !== "default");
    const subscriptions = useSelector(state => state.models.subscriptions, shallowEqual);
    const isOwnersModel = model.user_id === user.id;
    const [activateLoader, setActivateLoader] = useState(false);

    useEffect(() => {
    }, [activateLoader]);

    // useEffect(() => {
    //   if (scrollContainer?.current) {
    //     setScrollHeight(scrollContainer?.current?.scrollHeight);
    //   }
    // }, []);

    useEffect(() => {
      handleGetAllBrainFiles(model.id, files => {
          if (files) {
              const uploadedFiles = files.filter(file => !Boolean(file.task));
              setFilesList(uploadedFiles);
          }
      });
      setFileSources([]);
      // setParamFileIds([]);
    }, [model]);


    const {hasAccess, userActionAllowed} = usePermissions();

    const isFilesAllowed = useMemo(() => {
        return hasAccess({limitationAction: "learningFile"});
    }, [hasAccess]);

    const isWriteAllowed = useMemo(() => {
        return userActionAllowed('write');
    }, [userActionAllowed]);


    // const talkToModelLabel = <Typography variant="trunkate">{`Talk to ${model.name} model ...`}</Typography>;
    const talkToModelLabel = `Talk to ${model.name} model`;
    const placeholder = isMsgSending && searchSource !== "" ? searchSource : talkToModelLabel;
    // const placeholder = isMsgSending && searchSource !== "" ? (<div className={styles["dots-loading"]}>{searchSource}</div>) : (talkToModelLabel);
    const {chat_id} = useParams();

    // test this better before commit
    const chatId = chat_id ? parseInt(chat_id) : chats.chatId || null;

    // const chatId = chats.chatId || null;


    const sharedChat = sharedChats?.filter(item => item.id === chatId)[0];
    const permissions = model.access == null ? ["*"] : model.access;

    const questionRef = useRef(null);
    const commandBoxRef = useRef(null);
    const scrollContainer = useRef(null);
    const openCtaRef = useRef(null);
    const containerRef = useRef(null);

    const userInfo = useMemo(
        () => ({
            initials: getInitials(user.fullName),
            avatar: user.avatar,
            permissions: permissions
        }),
        [user.fullName, user.avatar]
    );
    
    const modelInfo = useMemo(
        () => ({
            modelName: getInitials(model.name),
            version: modelLabels.labels[model.settings?.gpt_model] || "Standard",
            modelFullName: model.name,
            avatar: model.avatar ?? null,
        }),
        [model.name, model.settings?.gpt_model]
    );
    
    const middleBrainShouldAppear = useMemo(() => {
        return Boolean(chatId);
    }, [chatId])

    const setMessageLoadState = (loading = undefined, stage = undefined) => {

        let params = {
            loading,
            stage
        }
        dispatch(setMessageLoading(params));
    }

    const monetizedModels = subscriptions?.map(item => item.model.id);
    const isTrialModel = Boolean(monetizedModels) && model?.access?.includes('view') && !monetizedModels?.includes(model?.id);

    const handleAbortRequest = () => {
        abortRequestAction({userAction: true});
        saveAiResponse();
        dispatch(addTempMessage({type: "ai", refresh: true}));
        // setMsgLoadState(false);
        setMessageLoadState(false, 1);
    };

    const abortRequestAction = (reason = {}) => {

        if (abortController.signal.aborted !== false) {
            return;
        }
        abortController.abort(reason);
    }

    const saveAiResponse = async (chat_id = null, msg = null, finallyCallback = false) => {
        let emptyError = false;
        emptyError = msg ? msg.trim() == "" : aiMessage?.trim() == "";

        if (emptyError) {
            return;
        }

        let msgParams = {
            message: encodeData(msg ?? aiMessage),
            type: "response",
            chatId: chat_id ?? chatId,
        };

        await controlledAPI({ignoreUser: true})
            .post("message", msgParams)
            .then((r) => {
                if (r) {
                    dispatch(addMessage(r.message));
                    dispatch(addTempMessage({type: "ai", refresh: true}));
                }
            })
            .catch((err) => {
                // dispatch(addTempMessage({type: 'ai', refresh: true }));
                dispatch(addTempMessage({type: "user", refresh: true}));
            })
            .finally(() => {
                if (finallyCallback) {
                    finallyCallback();
                }
            });
    };

    // const supportedFormats = ["docx", "doc", "pdf", "txt","xlsx", "csv","eml", "html","pptx", "ppt","jpg","jpeg","png"]
    const fileFormats = ["docx", "doc", "pdf", "txt","xlsx", "csv","eml", "html","pptx", "ppt"]
    const imageFormats = ["jpg","jpeg","png"]

    const handleDeleteFileSources = (id) => {
        setFileSources((prevFileSources) => prevFileSources.filter(file => file.id !== id));
    };

    const cleanChunkSources = (initial = "") => {
        cleanContentWriteAnimation()
        setSearchSource(initial);
    }

    const handleAdvancedKeyInput = (key, inputValue) => {
        let value = questionRef?.current?.value || '';
        let prevKey = value.charAt(value.length - 1);

        if (prevKey == "/" && commandPrompt) {
            setCommandPrompt(false);
        }
        if (!['/files', '/', '/settings'].includes(inputValue)) {
            setCommandPrompt(false);
        }
        if (key === 'Escape') {
            cleanQuestionInput();
        }

        const targetRef = questionRef.current;

        if (key == "/") {
            setCommandPrompt('commandMenu');
        } else if (inputValue.endsWith("/files")) {
            setCommandPrompt('files');
        } else if (inputValue.endsWith("/settings")) {
            setCommandPrompt('settings');
        }
        if (targetRef) {
            targetRef.focus();
        }
    }

    const handleInputFocus = (e) => {
        e.preventDefault();
        // if (questionRef.current?.value?.length === 0) {
        //     setMessageLoadState(undefined, 1)
        //     return;
        // }
        setIsLoadMore(false);
        setMessageLoadState(undefined, 2)
    }

    const handleInputBlur = () => {
        setMessageLoadState(undefined, 1)
    }

    const brainTick = (value) => {
        // global for now, later will put it in the same scope with the chat input
        if (value?.length === 0) {
            setMessageLoadState(undefined, 1)
            return;
        } else {
            setMessageLoadState(undefined, 2)
        }


        dispatch(brainTickUpdate());
    }

    const handleKeyInput = (e) => {

        if (e.defaultPrevented || !e.isTrusted) {
            return;
        }

        const native = e.nativeEvent;
        const inputValue = e.target.value;


        // if (chatId) {
        brainTick(inputValue);
        // }


        // handleAdvancedKeyInput(e.key, inputValue);
        handleAdvancedKeyInput(native.data, inputValue);
    };

    const getMessageList = () => {
        dispatch(getList(chatId));
    };

    useEffect(() => {
      if (messages.list?.length > 10 && isLoadMore) {
        scrollContainer?.current?.scrollTo({
          top: scrollContainer?.current?.scrollHeight - scrollHeight
        });
      }
    }, [messages, isLoadMore]);

    const location = useLocation();

    useEffect(() => {
        let chatId = parseInt(chat_id);
        let chatIdFromLocation = parseInt(location.pathname.split("/").pop());
        if (!chatId) {
            return;
        }

        let cleanupFunction = undefined;
        if (chats.status === "new" && chatId !== chatIdFromLocation) {
            dispatch(deleteAll());
        } else {
            if (chatId) {
                if (!isNewChat) {
                    setIsLoadMore(false);
                    getMessageList();
                }

                cleanupFunction = async () => {
                    abortRequestAction();
                    cleanChunkSources();
                    setFileSources([]);
                    // setParamFileIds([]);
                    setIsNewChat(false);
                    dispatch(cleanUpData());

                    // dispatch(addTempMessage({ type: "ai", refresh: true }));
                    // dispatch(addTempMessage({ type: "user", refresh: true }));
                    dispatch(changeFilePrices({refresh: true}));
                    // setMsgLoadState(false);
                    setMessageLoadState(false, 1);

                    //dispatch(deleteAll());
                };
            } else {
                dispatch(deleteAll());
            }
        }

        return cleanupFunction;
    }, [chatId, location.pathname]);

    const cleanQuestionInput = () => {
        if (questionRef.current.value.endsWith("/files")) {
            const newText = questionRef.current.value.slice(0, -'/files'.length);
            questionRef.current.value = newText;
        }
        if (questionRef.current.value.endsWith("/")) {
            const newText = questionRef.current.value.slice(0, -'/'.length);
            questionRef.current.value = newText;
        }
        if (questionRef.current.value.endsWith("/settings")) {
            const newText = questionRef.current.value.slice(0, -'/settings'.length);
            questionRef.current.value = newText;
        }
    }
    //check if input file is uploaded before
    const checkInputFile = async (file)=>{
        try {
            const resp = await api.get("/file/list");
            const storedFiles = resp?.files || [];
            const matchingFile = storedFiles.find(storedFile => {
                return storedFile?.original_name === file.name && storedFile?.file_size === file.size
            });
            if (matchingFile) {
                return matchingFile?.id;
            }
        }
            catch(error){
            console.log(error);
            dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
            return null
        }
    }

    const inputFileUpload = async (inputFiles, inputImages, chatId) => {
        const uploadedFileIds = {
            files: [],
            images: [],
            names: [],
        };

        const uploadSingleFile = async (file) => {
            const storedFile = await checkInputFile(file);

            if (storedFile) {
                return storedFile; // If file is already uploaded, return its ID
            } else {
                try {
                    const params = new FormData();
                    params.append("upload", file);
                    params.append("dataType", file?.type);
                    params.append("chatId", chatId);

                    const resp = await api.post("/file/upload", params, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    const fileId = resp.file?.id;

                    if (fileId) {
                        return fileId;
                    }
                } catch (error) {
                    console.error(error);
                    dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
                    return null;
                }
            }
        };

        // Upload all files
        for (const file of inputFiles) {
            const fileId = await uploadSingleFile(file);
            if (fileId) {
                uploadedFileIds.files.push(fileId);
                uploadedFileIds.names.push(file.name);
            }
        }

        // Upload all images
        for (const image of inputImages) {
            const imageId = await uploadSingleFile(image);
            if (imageId) {
                uploadedFileIds.images.push(imageId);
                uploadedFileIds.names.push(image.name);
            }
        }

        return uploadedFileIds;
    };

    const stopFileUpload = () => {
        if (fileUploadAbortController) {
            fileUploadAbortController.abort(); // cancel the ongoing file upload
        }
    };

    const sendMessage = useCallback(async (e, newMessage = null, params = {}) => {

        if (e) {
            e.preventDefault();
        }

        let question = questionRef.current.value,
            ref = questionRef,
            emptyError = false,
            maxWordsAllowed = 0,
            savedChat = {id: chatId},
            cachedMsg = "",
            firstChunkArrived = false,
            sources = {},
            errorFired = false,
            sourceObjectData = false;

        // Set temp chat id if chatId is not available
        const tempChatId = chatId ?? Date.now();
        const isTmpChat = tempChatId !== chatId;

        emptyError = newMessage ? newMessage.trim() == "" : question.trim() == "";

        if (emptyError) {
            dispatch(
                interactWithUser({
                    message: "Message is empty",
                    type: "info",
                })
            );
            return;
        }

        maxWordsAllowed = newMessage
            ? newMessage.split(" ").length
            : question.split(" ").length;

        if (maxWordsAllowed > 1500) {
            dispatch(
                interactWithUser({
                    message: "The message is exceeded maximum words allowed of 1500",
                    type: "info",
                })
            );
            return;
        }

        dispatch(changeFilePrices({refresh: true}));
        ref.current.value = "";


        let userTempMessage = {type: "user", text: newMessage ?? question};

        if (params.attachments) {
            if (!Array.isArray(params.attachments)) {
                // convert object to an array
                params.attachments = [params.attachments];
            }

            userTempMessage.attachments = params.attachments;
            setSourcesIds(params.attachments.map(file => file.id));
        } else if (fileSources.length > 0) {
            userTempMessage.attachments = fileSources;
            setSourcesIds(fileSources.map(file => file.id));
            // setFileSources([]);
        }

        

        if (sourcesIds?.length > 0) {
            setSearchSource("Thinking...");
        } else {
            contentWriteAnimation();
        }

        setMessageLoadState(true, 3);

        // setMsgLoadState(true);
        let msgParams = {
            message: encodeData(newMessage ?? question),
            type: "prompt",
            // chatId: savedChat.id,
        };

        if (!chatId && (inputFilesRef.current.length > 0 || inputImagesRef.current.length > 0)) {
            dispatch(addTempMessage(userTempMessage));
            dispatch(addTempMessage({type: "ai", text: ""}));

            setIsNewChat(true);
            let name = getWordsFromString(newMessage ?? question, 4);
            await controlledAPI({ignoreUser: true})
                .post("/chat", {
                    name: name,
                    modelId: model.id,
                })
                .then(async (resp) => {
                    dispatch(pushChat(resp.chat));
                    if (resp?.chat) {
                        savedChat = resp.chat;
                        navigate("/playground/chat/" + resp.chat.id);

                        // Upload files and images if they exist
                        if (inputFilesRef.current.length > 0 || inputImagesRef.current.length > 0) {
                            sourceObjectData = await inputFileUpload(inputFilesRef.current, inputImagesRef.current, resp.chat.id);
                        }
                        msgParams.chatId = resp.chat.id;
                    }
                    dispatch(setStatus({ status: "new" }));
                })
                .catch((error) => {
                    setMessageLoadState(false, 1);
                });
        }else if (!chatId) {
            // navigate("/playground/chat/" + tempChatId);
            // dispatch(setStatus({status: "new"}));
            // setMessageLoadState(true);
            // dispatch(setLoading({loading: true}));
            // dispatch(addTempMessage({type: "user", text: newMessage ?? question}));
            // dispatch(addTempMessage({type: "ai", text: ""}));
            // setIsNewChat(true);

            // chagne activateLoader to true
            setActivateLoader(true);
        }

        if (chatId > 0) {
            dispatch(addTempMessage(userTempMessage));
            dispatch(addTempMessage({type: "ai", text: ""}));

            msgParams.chatId = chatId;
            setIsNewChat(false);

            stopFileUpload();

            let fileItems = inputFilesRef.current.filter(item => !paramFileIds.current?.names?.includes(item.name)),
                imageItems = inputFilesRef.current.filter(item => !paramFileIds.current?.names?.includes(item.name));


            if (paramFileIds.current.files?.length > 0 || paramFileIds.current.images?.length > 0) {
                sourceObjectData = paramFileIds.current;
            }

            // Upload files and images if they exist
            if (fileItems.length > 0 || imageItems.length > 0) {
                let sourceObjectAdditionalData = await inputFileUpload(fileItems, imageItems, chatId);

                sourceObjectData = {...sourceObjectData, ...sourceObjectAdditionalData};
            }

        }

        

        // If chat exists and it is an integer
        if (!msgParams.chatId) {
            // Add modelId if chatId is not available
            msgParams.modelId = model.id;
        }

        if (sourceObjectData.files?.length > 0 || sourceObjectData.images?.length > 0 || fileSources?.length > 0) {
            if (sourceObjectData.files?.length > 0) {
                msgParams.attachmentsId = sourceObjectData.files;

                setSourcesIds([...sourcesIds, ...sourceObjectData.files]);
            }

            // Check if inputImages were uploaded and assign image IDs separately
            if (sourceObjectData.images?.length > 0) {
                msgParams.imageIds = sourceObjectData.images;

                setSourcesIds([...sourcesIds, ...sourceObjectData.images]);
            }

            // added sources
            if (fileSources?.length > 0) {
                let sourcesIds = fileSources.map((fileItem) => fileItem.id);

                if (typeof msgParams.attachmentsId !== 'undefined') {
                    msgParams.attachmentsId = [...msgParams.attachmentsId, ...sourcesIds];
                } else {
                    msgParams.attachmentsId = sourcesIds;
                }
            }
        }

        // Reset after upload
        inputFilesRef.current = [];
        inputImagesRef.current = [];
        paramFileIds.current = [];
        setInputFiles([]);
        setInputImages([]);

        if (params.regenerate) {
            msgParams.regenerate = 1;
        }

        const errorCallback = (response = {message: [], params: {}}) => {

            if (errorFired) {
                return;
            }
            errorFired = true;

            let messages = response.message;

            if(messages[0] === 'user.balance.not_enough') {
                dispatch(manualLock(true));
            } else {
                if (!response.params?.hideErrorMsg) {
                    let errMsg =
                        statusMessages.messages[messages[0]] ??
                        "Something went wrong.. Please, try again later..";
                    dispatch(
                        interactWithUser({
                            message: errMsg,
                            type: "error",
                        })
                    );
                    if (messages[0] === 'model.monetization.preview.exceed' && user.jsonData) {
                        const { monetizationSubscription } = user.jsonData;
                        if (Boolean(monetizationSubscription)) {
                            const updatedData = { ...monetizationSubscription, isTrial: 0 };
                            dispatch(setJsonData({ jsonData: { ...auth.user.jsonData, monetizationSubscription: updatedData } }));
                            navigate('/billing/subscribe');
                        }
                    }
                }
            }


            abortRequestAction();
            dispatch(addTempMessage({type: "ai", refresh: true}));
            dispatch(cleanStack());

            setMessageLoadState(false, 1);

            cleanChunkSources("Thinking...");
            // handleAbortRequest();
            dispatch(addTempMessage({type: "user", refresh: true}));

            if (response.params?.saveMessage) {
                let message = {type: "prompt", message: userTempMessage.text}
                dispatch(addMessage(message));
            }

            ref.current.focus();

            return;
        };

        const bufferCallback = (resp) => {
            if (resp.data) {
                let data = resp.data;
                cachedMsg += data;
                dispatch(addTempMessage({type: "ai", text: data}));

                if (!firstChunkArrived && data != "") {
                    firstChunkArrived = true;
                    cleanChunkSources("Typing...");
                }

                if (!resp.response) {
                    return;
                }
            }

            if (resp.error) {

                if (resp.data != "") {
                    cachedMsg += resp.data;
                }

                let message = resp.error;

                abortRequestAction();
                saveAiResponse(savedChat.id, cachedMsg);
                dispatch(cleanStack());
                dispatch(addTempMessage({type: "ai", refresh: true}));
                // setMsgLoadState(false);
                setMessageLoadState(false, 1);
                cleanChunkSources("Thinking...");
                // handleAbortRequest();
                // dispatch(addTempMessage({ type: "user", refresh: true }));
                if (aiMessage == "") {
                    // dispatch(addTempMessage({ type: "ai", refresh: true }));
                }
                ref.current.focus();

                return;
                // dispatch(addTempMessage({ type: "ai", refresh: true }));
                // setMsgLoadState(false);
            }


            if (resp.balance) {
                dispatch(updateCredits({newBalance: resp.balance}));
            }

            if (resp.message) {
                const message = resp.message;
                if (isTmpChat) {
                    navigate("/playground/chat/" + message.chat_id);
                    setActivateLoader(false);
                    let name = getWordsFromString(newMessage ?? question, 4);
                    dispatch(pushChat({
                            id: message.chat_id,
                            name: name
                        }
                    ));      
                }
                dispatch(addTempMessage({type: "user", refresh: true}));
                dispatch(addMessage(message));
                sources = resp.sources || {};
                // setMessageLoadState(undefined, 2);
            }


            if (resp.progressStatus) {
                let msg = resp.progressStatus?.msg || "Thinking...";
                setSearchSource(msg);
            }

            if(resp.tool_call) {

                if(resp.tool_data) {
                    dispatch(popFromToolCallStack(resp));
                    return;
                }

                dispatch(addToolCallToStack(resp.tool_call));

                return;
            }

            if(resp.tool_data) {
                dispatch(setToolsResults({toolsData: resp.tool_data}));
            }

            if (resp.response) {
                const response = resp.response;
                ref.current.value = "";
                cleanChunkSources("");
                dispatch(setLastResponseSources({messageId: response.id, sources: sources}));
                dispatch(addMessage(response));
                dispatch(addTempMessage({type: "ai", refresh: true}));
                dispatch(cleanStack());
                // setMsgLoadState(false);

                setMessageLoadState(false, 1);
                ref.current.disabled = false;
                setTimeout(() => {
                    ref.current.focus();
                    if(!resp.balance) {
                        dispatch(manualLock(true))
                    }
                }, 100)

                return;
            }
        };

        abortController = new AbortController();
        let signal = abortController.signal, streamSettings = undefined;


        if (sourcesIds.length > 0) {
            streamSettings = {TIMEOUT_PERIOD_MS: 60000, hasAttachments: true};
        }

        createStream("/message",
            bufferCallback,
            msgParams,
            signal,
            errorCallback,
            streamSettings
        );
    }, [chatId, questionRef, dispatch, fileSources, model.id, navigate, aiMessage, inputFilesRef, inputImagesRef]);


    const attachHandleClick = () => {
        fileInputRef.current.click();
    }
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Get the array of files

        if (files.length > 10) {
            dispatch(
                interactWithUser({
                    message: "File upload limit exceeded. You can upload up to 10 files only!",
                    type: "error",
                })
            );

            // Reset after upload
            inputFilesRef.current = [];
            inputImagesRef.current = [];
            setInputFiles([]);
            setInputImages([]);

            // Add this line to reset the file input element
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            return;
        }

        if (files.length > 0) {
            const newValidFiles = [];
            const newValidImages = [];

            files.forEach((file) => {
                const fileExtension = file.name.split(".").pop().toLowerCase();
                const isFileForm = fileFormats.includes(fileExtension);
                const isImageForm = imageFormats.includes(fileExtension);

                if (isFileForm) {
                    newValidFiles.push(file); // Handle file inputs
                } else if (isImageForm) {
                    const gpt_model = model?.settings?.gpt_model;
                    if (gpt_model === ENHANCED_GPT_MODEL) {
                        newValidImages.push(file); // Handle image inputs
                    } else {
                        dispatch(
                            interactWithUser({
                                message: "Image uploading function is supported only by Advanced Version. Please change model settings.",
                                type: "error",
                            })
                        );
                    }
                } else {
                    dispatch(
                        interactWithUser({
                            message: "File format not supported",
                            type: "error",
                        })
                    );
                }
            });

            // update state with new valid files and images
            if (newValidFiles.length > 0) {
                inputFilesRef.current = [...inputFilesRef.current, ...newValidFiles];

                if (typeof prevFiles !== 'undefined') {
                    setInputFiles((prevFiles) => [...prevFiles, ...newValidFiles]);
                } else {
                    setInputFiles(newValidFiles);
                }
            }

            if (newValidImages.length > 0) {
                inputImagesRef.current = [...inputImagesRef.current, ...newValidImages];
                setInputImages((prevImages) => [...prevImages, ...newValidImages]); // Add to images array
            }

            // Clear the input if no valid files are selected
            if (newValidFiles.length === 0 && newValidImages.length === 0) {
                fileInputRef.current.value = ''; // Clear file input if no valid files
            }

            // start uploading files and images if they exist
            if (chatId) {
                // Before starting a new upload, cancel any ongoing file upload if needed
                if (fileUploadAbortController) {
                    fileUploadAbortController.abort(); // Cancel any previous upload
                    fileUploadAbortController = new AbortController(); // Recreate for the next upload
                }

                const signal = fileUploadAbortController.signal; // Use this signal only for file uploads

                if (inputFilesRef.current.length > 0 || inputImagesRef.current.length > 0) {
                    inputFileUpload(inputFilesRef.current, inputImagesRef.current, chatId, signal)
                        .then((fileData) => {
                            paramFileIds.current = fileData;
                        })
                        .catch((error) => {
                            if (error.name === 'AbortError') {
                                console.log("File upload was aborted");
                            } else {
                                console.error(error);
                            }
                        });
                }
            }

        }
    };

    useEffect(()=>{
        inputFilesRef.current = [];
        inputImagesRef.current = [];
        setInputFiles([])
        setInputImages([])
        setFileSources([]);
        // setParamFileIds([]);
        if (fileInputRef.current){
            fileInputRef.current.value = '';
        }
    },[model])


    return (
        <Grid
            container={true}
            //sx={{ height: "inherit", overflowY: "auto" }}
            sx={{height: '100%'}}
            ref={containerRef}
        >
            {/* <Grid
                item
                xs={12}
                md={3}
                sx={{
                    display: smallScreen ? (mobileHelperShow ? "flex" : "flex") : "flex"
                }}
            >
                {smallScreen ? <ResponsiveChatsMenu onChatChange={setIsNewChat}/> : <ChatMenu
                    // mobileHelperChange={handleChangeMobileHelperShow}
                    onChatChange={setIsNewChat}
                />}

            </Grid> */}
            <Grid
                item
                xs={12}
                // md={9}
                sx={{
                    display: smallScreen ? (mobileHelperShow ? "flex" : "flex") : "flex",
                    height: "100%"
                }}
            >
              {selectedModel.type === 'monetization' && (
                <Box sx={{ padding: { xs: '0 15px', md: '0'}, width: '100%' }}>
                {/* Paid model */}
                  <Content
                    modelHash={selectedModel.data?.marketplace_card?.url_hash}
                    // setModelinitials={(modelInitials) => setModelInitialsData(modelInitials)}
                    setModelinitials={() => {}}
                    offsetLeft={0}
                    isSticky={true}
                  />
                </Box>
              )}
              {selectedModel.type === 'shared' && (
                <Chat
                  islogin={auth?.user}
                  viewportHeight={viewportHeight}
                  topContainerHeight={0}
                  info={{ ...selectedModel.data?.info, chat_id: null }}
                  modelName={selectedModel.data?.model?.name && getInitials(selectedModel.data?.model.name)}
                  chatLoading={selectedModel.loading}
                  type="shared"
                  topOffset={{ md:166, xs:166 }}
                  userInfo={userInfo}
                  modelData={selectedModel.data}
                />
              )}
              {selectedModel.type === 'default' && (
                <Grid
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "100%",
                        flexFlow: "column",
                        margin: "0",
                        // marginTop: "0.5em",
                    }}
                >
                  {isTrialModel && (
                        <Box sx={{padding: '10px 15px 0', position: 'sticky', zIndex: 100, top: 0, background: '#fff', display: 'flex', justifyContent: 'center', flexWrap: {xs: 'wrap', md: 'npwrap'}, alignItems: 'center', gap: '20px', borderBottom: '1px solid rgba(0,0,0, .1)', paddingBottom: '15px', width: '100%', }}>
                          <Typography variant="text" sx={{ textAlign: 'center' }}>
                            {`Try "${model.name}" for free. You have 2 messages limit.`}
                          </Typography>
                          <Button
                            onClick={() => {
                              const { monetizationSubscription } = user.jsonData;
                              if (Boolean(monetizationSubscription)) {
                                const updatedData = { ...monetizationSubscription, isTrial: 0 };
                                dispatch(setJsonData({ jsonData: { ...auth.user.jsonData, monetizationSubscription: updatedData } }));
                                navigate('/billing/subscribe');
                              }
                            sendUIEvent({name: `try_for_free_2_messages_left_click`});
                            }}
                            variant="contained"
                            size="small"
                          >
                            {`Subscribe $${model.settings.monetization_price}/${model.settings.monetization_charge_frequency}`}
                          </Button>
                        </Box>
                      )}
                    <Grid
                        pt={2}
                        sx={{
                            width: "100%",
                            overflowY: "auto",
                            height: '100%'
                            // width: {xs: "90%", md: "calc(60% + 16px)"},
                        }}
                        ref={scrollContainer}
                    >
                      {messages.limit < messages.count && !messages.loading && (
                        <Box sx={{ textAlign: 'center', paddingBottom: '10px' }}>
                          <Button
                            type="button"
                            sx={{
                              fontSize: '0.75rem',
                              // color: '#7e7e7e',
                              width: {xs: '90%', sm: 'auto'}
                            }}
                            disabled={messages.loadMoreLoading}
                            variant="outlined"
                            onClick={() => {
                              setIsLoadMore(true);
                              setScrollHeight(scrollContainer?.current?.scrollHeight);
                              dispatch(loadMore({chatId, limit: messages.list.length + 10}));
                            }}
                          >Load more</Button>
                        </Box>
                      )}
                        {chatId || activateLoader ? (
                            activateLoader ? (
                              <MessageStructureSkeleton styles={stylesChatConversation}  loading={true} modelInfo={modelInfo}/> 
                            ) : (
                              <ChatConversation
                                modelInfo={modelInfo}
                                sendMessage={sendMessage}
                                userInfo={userInfo}
                                scrollContainer={scrollContainer}
                                isLoadMore={isLoadMore}
                              />
                            )
                        ) : (
                            <Box sx={{height: '100%', width: {xs: "90%", md: "60%"}, margin: 'auto', zIndex: 1}}>
                                <NewChatHints
                                    userName={user.fullName} onTry={sendMessage}/>
                            </Box>
                        )}
                    </Grid>
                    <AccessChecker
                        currentPermissions={userInfo.permissions}
                        requiredPermissions={["write"]}
                    >
                        <Grid sx={{width: {xs: "100%", md: "60%", position: "relative", marginTop: "auto", zIndex: 1}}}
                              ref={commandBoxRef}>
                            {commandBoxRef.current && commandPrompt &&
                                <ClickAwayListener onClickAway={(event) => {
                                    const isIconButtonClick = openCtaRef.current && openCtaRef.current.contains(event.target);
                                    if (isIconButtonClick) return;

                                    cleanQuestionInput();
                                    setCommandPrompt(false);
                                    // questionRef.current.value = '';
                                }}>
                                    <Box
                                        sx={{backgroundColor: commandPrompt === 'files' && !isFilesAllowed ? '#eeeeeee3' : '#fff'}}>
                                        <FileSelector
                                            modelId={model.id}
                                            isOpen={commandPrompt === 'files'}
                                            fileSources={fileSources}
                                            setInputFile={setInputFiles}
                                            setInputImage={setInputImages}
                                            setFileSources={(sources) => {
                                                setFileSources(sources);
                                            }}
                                            files={filesList}
                                            isLoading={isGettingAllBrainFiles}
                                            isFilesAllowed={isFilesAllowed}
                                            commandBoxRef={commandBoxRef.current}
                                            closeDialog={() => {
                                                setCommandPrompt(false);
                                                cleanQuestionInput();
                                            }}
                                            setCommandPrompt={(state) => {
                                                // TODO: create a helper func
                                                if (fileSources.length != 0) {
                                                    let value = questionRef.current.value;
                                                    if (value.endsWith("/files")) {
                                                        const newText = questionRef.current.value.slice(0, -'/files'.length);
                                                        questionRef.current.value = newText;
                                                    }
                                                }
                                                setCommandPrompt(state);
                                            }}
                                        />
                                        <Commands
                                            modelId={model.id}
                                            isOpen={commandPrompt === 'commandMenu'}
                                            commandBoxRef={commandBoxRef.current}
                                            isFilesAllowed={isFilesAllowed}
                                            setCommandPrompt={(state) => {
                                                // if (fileSources.length != 0) {
                                                //   let value = questionRef.current.value;
                                                //   if (value.endsWith("/")) {
                                                //     questionRef.current.value = value.substring(0, value.length - 1);
                                                //   }
                                                // }
                                                let value = questionRef.current.value;
                                                if (value.endsWith("/")) {
                                                    questionRef.current.value = value.substring(0, value.length - 1);
                                                }
                                                // if (state) questionRef.current.value = `/${state}`
                                                setCommandPrompt(state);
                                                sendUIEvent({name: `chat_settings_open_${state}`});
                                            }}
                                        />
                                        <Settings
                                            modelId={model.id}
                                            isOpen={commandPrompt === 'settings'}
                                            commandBoxRef={commandBoxRef.current}
                                            closeDialog={() => {
                                                setCommandPrompt(false);
                                                cleanQuestionInput();
                                            }}
                                            setCommandPrompt={(state) => {
                                                if (fileSources.length != 0) {
                                                    let value = questionRef.current.value;
                                                    if (value.endsWith("/settings")) {
                                                        // questionRef.current.value = '';
                                                    }
                                                }
                                                if (state) questionRef.current.value = `/${state}`
                                                setCommandPrompt(state);
                                            }}
                                        />
                                        <CustomTools
                                            modelId={model.id}
                                            isOpen={commandPrompt === 'custom-tools'}/>
                                    </Box>
                                </ClickAwayListener>
                            }
                            {
                                !commandPrompt && Boolean(fileSources.length) && (
                                    <Box sx={{padding: {xs: '0 5%', md: '0'}}}>
                                        <AttachmentLinks content={fileSources} onDelete={handleDeleteFileSources}/>
                                    </Box>
                                )
                            }
                        </Grid>
                    </AccessChecker>
                    <Grid sx={{width: {xs: "100%", md: "60%", position: "relative", marginTop: "auto", zIndex: 1}}}>
                        {
                            inputFiles?.length > 0 && (
                                <Box sx={{padding: {xs: '0 5%', md: '0'}}}>
                                    {inputFiles.map((file, index) => (
                                        <Link underline="none" key={`file-${index}`}
                                              sx={{
                                                  fontWeight: "500",
                                                  margin: "12px 12px 0 0",
                                              }}>
                                            <Typography variant="truncate_text_150w" maxWidth={150}>
                                                <Typography variant="plain_link">{file?.name} </Typography>
                                            </Typography>
                                            <ClearIcon
                                                onClick={() => {
                                                    setInputFiles(inputFiles.filter((_, i) => i !== index));
                                                }}
                                                sx={{ marginLeft: 0.8, cursor: 'pointer', fontSize: "14px" }}
                                            />
                                        </Link>
                                    ))}
                                </Box>
                            )
                        }
                        {
                            inputImages?.length > 0 && (
                                <Box sx={{padding: {xs: '0 5%', md: '0'}}}>
                                    {inputImages.map((image, index) => (
                                        <Link underline="none" key={`image-${index}`}
                                              sx={{
                                                  fontWeight: "500",
                                                  margin: "12px 12px 0 0",
                                              }}>
                                            <Typography variant="truncate_text_150w" maxWidth={150}>
                                                <Typography variant="plain_link">{image?.name} </Typography>
                                            </Typography>
                                            <ClearIcon
                                                onClick={() => {
                                                    setInputImages(inputImages.filter((_, i) => i !== index));
                                                }}
                                                sx={{ marginLeft: 0.8, cursor: 'pointer', fontSize: "14px" }}
                                            />
                                        </Link>
                                    ))}
                                </Box>
                            )
                        }
                    </Grid>
                    <Grid mx={2}
                          sx={{
                              width: {xs: "90%", md: "60%"},
                              marginBottom: {xs: 0, md: "15px"},
                              marginTop: '15px',
                              position: 'relative',
                              zIndex: 1
                          }}>
                        <MiddleBrain shouldAppear={middleBrainShouldAppear}/>

                        <CustomTextField
                            data-testid="main-playground-input"
                            id="main-playground-input"
                            fullWidth
                            inputRef={questionRef}
                            maxRows={5}
                            minRows={1}
                            multiline
                            disabled={isMsgSending}
                            placeholder={placeholder}
                            FormHelperTextProps={{ component: 'div' }}
                            helperText={
                                <div className={styles.helperTextWrapper}>
                                    <div>{/* existing helper text if any */}</div>
                                    {questionRef.current?.value?.length > 0 && (
                                        <div className={classnames(
                                            styles.symbols, 
                                            isMsgSending && styles.error,
                                            questionRef.current?.value?.length > 1500 && styles.exceeded
                                        )}>
                                            {`${questionRef.current?.value?.length}/1500`}
                                        </div>
                                    )}
                                </div>
                            }
                            // label={
                            //     isMsgSending && searchSource !== "" ? (
                            //         <div className={styles["dots-loading"]}>{searchSource}</div>
                            //     ) : (
                            //         placeholder
                            //     )
                            // }
                            // isinputfocused={commandPrompt ? commandPrompt.toString() : undefined}
                            variant="outlined"
                            // {...(isMobileDevice ? { onChange: handleMobileKeyInput } : { onChange: handleKeyInput })}
                            onChange={handleKeyInput}
                            onKeyDown={(e) => {

                                if (e.key == "Enter" && !isMobileDevice) {
                                    if (!e.shiftKey) {
                                        // TODO: move this code to a separated function
                                        if (isSharedChatSelected && sharedChat) {
                                            e.preventDefault();
                                            // setMsgLoadState(true);
                                            setMessageLoadState(true, 3);
                                            saveAiResponse(chatId, questionRef.current.value, () => setMessageLoadState(false, 1));
                                            questionRef.current.value = ''
                                            return;
                                        }
                                        if (isSharedChatSelected && !chatId) {
                                            dispatch(setSource({source: 'default'}));
                                        }
                                        sendMessage(e);
                                    }
                                }
                            }}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            // sx={{
                            //     "& .MuiInputBase-input": {
                            //         display: '-webkit-box',
                            //         WebkitLineClamp: 1,
                            //         WebkitBoxOrient: 'vertical',
                            //         whiteSpace: 'nowrap'
                            //     }
                            // }}
                            InputLabelProps={{
                                style: {left: user.id !== model.user_id ? 0 : "30px"}
                            }}
                            InputProps={{
                                style: {paddingRight: "5px", paddingLeft: isWriteAllowed ? '2px' : '14px'},
                                startAdornment: (
                                        <Grid display='flex' flexDirection='row'>
                                        <AccessChecker
                                        currentPermissions={userInfo.permissions}
                                        requiredPermissions={["write"]}
                                    >
                                        <IconButton
                                            ref={openCtaRef}
                                            sx={{borderRadius: "5px"}}
                                            onClick={() => {
                                                const commandOpened = Boolean(commandPrompt)
                                                if (commandOpened) {
                                                    cleanQuestionInput();
                                                    setCommandPrompt(false);

                                                } else {
                                                    setCommandPrompt('commandMenu');
                                                }

                                                const label = commandOpened ? 'close' : 'open';
                                                sendUIEvent({name: `chat_settings_${label}`});

                                            }}
                                        >
                                            <KeyboardArrowUpIcon 
                                            id="pallette-icon" sx={{
                                                color: "#5f6368",
                                                transform: commandPrompt ? 'rotate(180deg)' : 'none'
                                            }}/>
                                        </IconButton>
                                    </AccessChecker>
                                        <Tooltip title='Attach temporary chat‘s file' placement="top">
                                            <IconButton
                                            sx={{borderRadius: "5px", alignSelf: "flex-end"}}
                                            onClick={()=>attachHandleClick()}>
                                                <AttachFileIcon id="temporary-file-icon"/>
                                            </IconButton>
                                        </Tooltip>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                                multiple
                                            />
                                        </Grid>
                                ),
                                endAdornment: (
                                    <Grid display='flex' flexDirection='row'>
                                        <IconButton
                                        sx={{borderRadius: "5px", alignSelf: "flex-end"}}
                                        onClick={(e) => {
                                            if (isMsgSending) {
                                                handleAbortRequest();
                                            } else {
                                                // TODO: move this code to a separated function
                                                if (isSharedChatSelected && sharedChat) {
                                                    saveAiResponse(chatId, questionRef.current.value);
                                                    questionRef.current.value = '';
                                                    return;
                                                }
                                                if (isSharedChatSelected && !chatId) {
                                                    dispatch(setSource({source: 'default'}));
                                                }

                                                sendMessage(e);
                                            }
                                        }}
                                    >
                                        {isMsgSending ? (
                                            <StopIcon sx={{color: "#5f6368"}}/>
                                        ) : (
                                            <SendIcon color="primary"/>
                                        )}
                                        </IconButton>
                                    </Grid>

                                ),
                            }}
                        />
                    </Grid>
                </Grid>
              )}
            </Grid>
        </Grid>
    );
};

export default memo(Playground);
