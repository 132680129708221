import {useState, useRef, memo} from 'react';
import api from "_services/api";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from "react-router-dom";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    TextField,
    CircularProgress,
    Box,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import AccessChecker from 'components/security/AccessChecker';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import useLongPress from 'hooks/useLongPress';
import useDeleteChat from 'hooks/useDeleteChat';
import useRenameChat from 'hooks/useRenameChat';
import { setStatus } from "redux/chats";
import { setSelectedModelType } from "redux/models";
import classnames from 'classnames';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import styles from "../chat-menu.module.css";

const ChatList = ({mobileChatOpenHandler, onChatChange, setIsDialogOpen, setSelectedChat}) => {

    const [isChatEditing, setIsChatEditing] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let {chat_id} = useParams();
    const onLongPress = useLongPress();
    const {smallScreen} = useIsSmallScreen();
    const {handleDeleteChat, isDeleteLoading} = useDeleteChat();
    const {handleRenameChat, isRenamingLoading} = useRenameChat();
    const fieldRef = useRef(null);
    const { model, list, userId, chats } = useSelector((state) => ({
        list:
            state.chats.source == "default"
                ? state.chats.chats.items
                : state.chats.sharedChats.items,
        chats: state.chats.source == "default"
        ? state.chats.chats
        : state.chats.sharedChats,
        model: state.models.current,
        userId: state.auth.user.id,
    }), shallowEqual);

    if (!list) return null;

    return list?.map((item, i) => (
        <ListItem key={`${item.id}-${i}`} disablePadding>
            <ListItemButton
                selected={chat_id == item.id}
                id={chat_id == item.id ? "selected-chat" : ""}
                {...onLongPress(() => {
                    if (smallScreen && userId === item.user_id) {
                        setSelectedChat(item);
                        setIsDialogOpen(true);
                    }
                })}
                onClick={() => {
                    navigate("/playground/chat/" + item.id);
                    // mobileChatOpenHandler();
                    onChatChange(false);
                    dispatch(setStatus({status: ""}));
                    setIsChatEditing(false);
                    dispatch(setSelectedModelType({ modelType: 'default', data: null }));
                }}
                className={styles.listItem}
            >
                {isChatEditing == item.id ?
                    !isChatEditing && (<ListItemIcon sx={{minWidth: "30px"}}>
                        <ChatIcon fontSize="small"/>
                    </ListItemIcon>)
                    : (
                        <ListItemIcon sx={{minWidth: "30px"}}>
                            <ChatIcon fontSize="small"/>
                        </ListItemIcon>
                    )}
                <ListItemText
                    className={classnames(styles['one-line'], styles.menu, Boolean(isChatEditing) && isChatEditing == item.id && styles.renaming)}
                    primaryTypographyProps={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    primary={
                        Boolean(isChatEditing) && isChatEditing == item.id ?
                            <>
                                <TextField
                                    autoFocus
                                    onBlur={(event) => {
                                        if (event.relatedTarget?.getAttribute('aria-label') !== 'edit') {
                                            setIsChatEditing(false);
                                        }
                                    }}
                                    inputRef={fieldRef}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleRenameChat(item.id, event.target.value, () => setIsChatEditing(false));
                                        }
                                        if (event.key === "Escape") {
                                            setIsChatEditing(false);
                                        }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    variant="outlined"
                                    defaultValue={item.name}
                                    sx={{paddingRight: '0', width: '100%',}}
                                    inputProps={{
                                        style: {
                                            fontSize: '12px',
                                            padding: '3px 25px 3px 5px',
                                            margin: '1px 0',
                                        }
                                    }}
                                />
                                <ListItemButton
                                    disabled={isRenamingLoading}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleRenameChat(item.id, fieldRef.current.value, () => setIsChatEditing(false));
                                    }}
                                    disableGutters={true}
                                    edge="end"
                                    aria-label="edit"
                                    sx={{
                                        position: 'absolute',
                                        right: '19px',
                                        top: '50%',
                                        marginTop: '-10px',
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    {isRenamingLoading ? (
                                        <CircularProgress color="inherit" size={20}/>
                                    ) : (
                                        <EastOutlinedIcon sx={{color: '#3976EF'}} fontSize="small"/>
                                    )}
                                </ListItemButton>
                            </>
                            : item.name
                    }
                />
                {!smallScreen && !isChatEditing && userId === item.user_id && (
                    <AccessChecker
                        currentPermissions={model.access}
                        requiredPermissions={["write"]}
                    >
                        <Box className={styles.actionCtaWrap}>
                            <ListItemButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsChatEditing(item.id, chat_id);
                                }}
                                disableGutters={true}
                                edge="end"
                                aria-label="edit"
                                className={classnames(styles.actionCta, styles.editCta)}
                            >
                                <EditIcon fontSize={"small"}/>
                            </ListItemButton>
                            <ListItemButton
                                disabled={isDeleteLoading}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteChat(item.id, () => {
                                        if (!Boolean(chat_id)) {
                                            navigate('/playground', {replace: true});
                                        }
                                    })
                                }}
                                disableGutters={true}
                                edge="end"
                                aria-label="delete"
                                className={classnames(styles.actionCta, styles.deleteCta)}
                            >
                                {isDeleteLoading ? (
                                    <CircularProgress size={20}/>
                                ) : (
                                    <DeleteIcon fontSize={"small"}/>
                                )}
                            </ListItemButton>
                        </Box>
                    </AccessChecker>
                )}
            </ListItemButton>
        </ListItem>
    ));
};

export default memo(ChatList);
