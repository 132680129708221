import React, { useEffect, useMemo, useState, useRef } from 'react';
import Joyride, { EVENTS, ACTIONS, STATUS, LIFECYCLE } from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getToursThunk, updateTourStepThunk } from 'redux/tours/toursThunk';
import {
  pickFirstIncompleteTourExcluding,
  flattenTourSteps,
  waitForElement,
  skipAllIncompleteSteps,
  createTourTargetObserver,
} from './tourUtils';
import MousePointerBeacon from './MousePointerBeacon';
import CustomTooltip from './CustomTooltip';

function GlobalOnboardingOverlay() {
  const auth = useSelector((state) => state.auth);
  const { tours, loading, error } = useSelector((state) => state.tours);

  const dispatch = useDispatch();
  const location = useLocation();

  // Track which tour is currently shown
  const [activeTour, setActiveTour] = useState(null);

  // Track the last processed path to detect real navigation changes
  const [lastProcessedPath, setLastProcessedPath] = useState(location.pathname);

  // Store the observer
  const targetObserverRef = useRef(null);

  // Trigger re-evaluation when DOM elements appear
  const [forceReconsider, setForceReconsider] = useState(0);

  // Tours the user has completed or skipped (don't show again)
  const [completedTourIds, setCompletedTourIds] = useState(new Set());

  // Tours the user has closed with 'X' (don't show again this session)
  const [sessionClosedTourIds, setSessionClosedTourIds] = useState(new Set());

  // Joyride local states
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  // Track when we're checking for a target to prevent race conditions
  const [checkingTarget, setCheckingTarget] = useState(false);

  const [allToursDisabled, setAllToursDisabled] = useState(() => {
    return localStorage.getItem('disableAllTours') === 'true';
  });

  const handleDisableAllTours = () => {
    localStorage.setItem('disableAllTours', 'true');
    setAllToursDisabled(true);
    setRun(false);
    setActiveTour(null);
  };

  useEffect(() => {
    // Function to check localStorage and update state
    const checkToursDisabled = () => {
      const disableAllTours = localStorage.getItem('disableAllTours') === 'true';
      setAllToursDisabled(disableAllTours);
      
      // If tours were disabled, also stop any active tour
      if (disableAllTours && (activeTour || run)) {
        setRun(false);
        setActiveTour(null);
      }
    };
    
    // Listen for storage events (when localStorage changes in other components)
    window.addEventListener('storage', checkToursDisabled);
    
    // Also create a custom event listener for same-window updates
    window.addEventListener('tours:setting:changed', checkToursDisabled);
    
    return () => {
      window.removeEventListener('storage', checkToursDisabled);
      window.removeEventListener('tours:setting:changed', checkToursDisabled);
    };
  }, [activeTour, run]);

  // Debounced version of forceReconsider to avoid multiple concurrent evaluations
  const debouncedForceReconsider = () => {
    if (window._tourReconsiderTimeout) {
      clearTimeout(window._tourReconsiderTimeout);
    }

    window._tourReconsiderTimeout = setTimeout(() => {
      setForceReconsider(prev => prev + 1);
    }, 300);
  };

  // Clean up global timeouts on unmount
  useEffect(() => {
    return () => {
      if (window._tourReconsiderTimeout) {
        clearTimeout(window._tourReconsiderTimeout);
      }
      if (window._tourUserInteractionTimeout) {
        clearTimeout(window._tourUserInteractionTimeout);
      }
    };
  }, []);

  // 1) On route change, fetch tours if authorized
  useEffect(() => {
    if (!auth?.token) return;
    dispatch(
      getToursThunk({
        statuses: ['not_started', 'opened'],
      })
    );
  }, [auth?.token, location, dispatch]);

  // 2) Set up the target observer when tours change
  useEffect(() => {
    if (!auth?.token || loading || error || !tours?.length) return;

    // Clean up any existing observer
    if (targetObserverRef.current) {
      targetObserverRef.current.disconnect();
    }

    // Create a new observer
    targetObserverRef.current = createTourTargetObserver(
      tours,
      new Set([...completedTourIds, ...sessionClosedTourIds]), // Exclude both completed and session-closed tours
      (newTargets) => {
        // When new targets appear, force tour reconsideration
        debouncedForceReconsider();
      }
    );

    // Clean up on unmount
    return () => {
      if (targetObserverRef.current) {
        targetObserverRef.current.disconnect();
      }
    };
  }, [tours, loading, error, auth?.token, completedTourIds, sessionClosedTourIds]);

  // 3) Set up user interaction listener to detect when new elements might appear
  useEffect(() => {
    // Only set up listener if we're not showing a tour
    if (auth?.token && !loading && !error) {
      const handleUserInteraction = () => {
        // Debounce to avoid too many checks
        if (window._tourUserInteractionTimeout) {
          clearTimeout(window._tourUserInteractionTimeout);
        }

        window._tourUserInteractionTimeout = setTimeout(() => {
          debouncedForceReconsider();
        }, 500);
      };

      // Listen for clicks and key presses
      document.addEventListener('click', handleUserInteraction);
      document.addEventListener('keyup', handleUserInteraction);

      return () => {
        document.removeEventListener('click', handleUserInteraction);
        document.removeEventListener('keyup', handleUserInteraction);
        if (window._tourUserInteractionTimeout) {
          clearTimeout(window._tourUserInteractionTimeout);
        }
      };
    }
  }, [auth?.token, loading, error]);

  // 4) Tour selection logic - when to pick a new tour
  useEffect(() => {
    // Skip if no auth or if we're still loading tours
    if (!auth?.token || loading || error) return;

    // Check if this is a real path change that should trigger tour reconsideration
    const isPathChange = location.pathname !== lastProcessedPath;

    // Debug helper for available tours
    // Add more detailed debug logging within your debugAvailableTours function:

    function debugAvailableTours() {
      if (!tours?.length) return [];

      const availableTours = tours
        .filter(t => !completedTourIds.has(t.tour_id))
        .filter(t => !sessionClosedTourIds.has(t.tour_id))
        .map(tour => {
          // Check DOM presence
          const tourSteps = Array.isArray(tour.steps)
            ? tour.steps
            : Object.values(tour.steps || {});

          const firstStep = tourSteps.find(s => s.active && ['not_started', 'opened'].includes(s.status));

          let targetExists = false;
          if (firstStep?.target) {
            try {
              targetExists = document.querySelector(firstStep.target) !== null;
            } catch (err) { /* ignore */ }
          }

          return {
            id: tour.tour_id,
            hasVisibleTarget: targetExists,
            target: firstStep?.target,
            filterExcluded: false
          };
        });

      // Also log ALL tours regardless of filters to see what's being excluded
      const allToursStatus = tours.map(tour => {
        const isCompleted = completedTourIds.has(tour.tour_id);
        const isSessionClosed = sessionClosedTourIds.has(tour.tour_id);
        const tourSteps = Array.isArray(tour.steps) ? tour.steps : Object.values(tour.steps || {});
        const firstStep = tourSteps.find(s => s.active && ['not_started', 'opened'].includes(s.status));

        let targetExists = false;
        if (firstStep?.target) {
          try {
            targetExists = document.querySelector(firstStep.target) !== null;
          } catch (err) { }
        }

        return {
          id: tour.tour_id,
          isExcludedCompleted: isCompleted,
          isExcludedSessionClosed: isSessionClosed,
          hasVisibleTarget: targetExists,
          isAvailable: !isCompleted && !isSessionClosed
        };
      });

      return availableTours;
    }

    // Process if:
    // 1. Path changed, or 
    // 2. No active tour and not loading, or
    // 3. forceReconsider was incremented (new DOM elements appeared)
    if (isPathChange || (!activeTour && !loading && !error) || forceReconsider > 0) {
      const triggerReason = isPathChange
        ? 'path change'
        : (forceReconsider > 0 ? 'DOM elements appeared' : 'no active tour');

      // Debug what tours are available
      const availableTours = debugAvailableTours();

      // If path changed and we have an active tour, finish current tour first
      if (isPathChange && activeTour) {
        setLastProcessedPath(location.pathname);
        setActiveTour(null);
        setRun(false);
        return;
      }

      // Update last processed path if needed
      if (isPathChange) {
        setLastProcessedPath(location.pathname);
      }

      // Reset force reconsider if it was used
      if (forceReconsider > 0) {
        setForceReconsider(0);
      }

      // Only proceed with selecting a new tour when:
      // 1. No active tour exists, OR
      // 2. DOM elements have appeared (forceReconsider > 0) and we should check if a better tour is now available
      if (activeTour && forceReconsider === 0) return;

      // If we have an active tour and are reconsidering due to new DOM elements,
      // we need to be more careful about switching tours
      const shouldEvaluateNewTour = !activeTour || forceReconsider > 0;

      // Select next tour, excluding completed and session-closed tours
      const toursToConsider = tours.filter(t =>
        !completedTourIds.has(t.tour_id) &&
        !sessionClosedTourIds.has(t.tour_id) &&
        (!activeTour || forceReconsider > 0 || t.tour_id !== activeTour.tour_id)
      );

      const nextTour = pickFirstIncompleteTourExcluding(
        toursToConsider,
        location.pathname,
        completedTourIds
      );

      // Only select a tour if we have DOM elements ready for it
      if (nextTour) {
        // Check if the first step's DOM element exists
        const tourSteps = Array.isArray(nextTour.steps)
          ? nextTour.steps
          : Object.values(nextTour.steps || {});
      
        const firstStep = tourSteps.find(s => s.active && ['not_started', 'opened'].includes(s.status));
      
        let targetExists = false;
        if (firstStep?.target) {
          try {
            targetExists = document.querySelector(firstStep.target) !== null;
          } catch (err) {
            console.error('Error checking DOM target:', err);
          }
        }
      
        if (targetExists) {
          // Don't switch if it's the same tour we already have active
          if (activeTour && nextTour.tour_id === activeTour.tour_id) {
            return;
          }
          
          // THIS IS THE CRITICAL CODE THAT WAS MISSING:
          setActiveTour(nextTour);
          setStepIndex(0);
          setRun(false);
        } else {

        }
      }
    }
  }, [activeTour, tours, loading, error, auth?.token, location.pathname, completedTourIds, lastProcessedPath, forceReconsider, sessionClosedTourIds]);

  // 5) Flatten the local active tour into Joyride steps
  const steps = useMemo(() => {
    if (!activeTour) return [];
    return flattenTourSteps(activeTour);
  }, [activeTour]);

  // 6) We attempt to show Joyride once steps exist
  //    but we do a small check for the next step's target before letting Joyride proceed
  useEffect(() => {
    if (steps.length === 0) {
      setRun(false);
      return;
    }

    // If we have steps but haven't started Joyride
    if (!run && !checkingTarget) {
      // Let's do an initial check on the first step's target
      const firstStep = steps[0];
      if (!document.querySelector(firstStep.target)) {
        // Pause Joyride until the element appears
        setCheckingTarget(true);
        waitForElement(
          firstStep.target,
          () => {
            // Found => unpause
            setCheckingTarget(false);
            setRun(true);
          },
          () => {
            // Timed out => we skip or just start anyway
            setCheckingTarget(false);
            setRun(true); // You could skip the step if you prefer
          },
          3000
        );
      } else {
        // It's already there => just start
        setRun(true);
      }
    }
  }, [steps, run, checkingTarget]);

  // 7) Joyride callback
  const handleJoyrideCallback = (data) => {
    const { type, action, status, step, lifecycle } = data;

    // Mark step as "opened" when it first appears
    if (type === EVENTS.STEP_BEFORE && lifecycle === LIFECYCLE.BEFORE) {
      if (action === ACTIONS.START || action === ACTIONS.NEXT) {
        // For a brand-new step
        dispatch(
          updateTourStepThunk({
            tourId: step.tourId,
            stepId: step.stepId,
            action: 'opened',
            status_filter: ['not_started'],
          })
        );

        // Check if the target exists
        if (!document.querySelector(step.target)) {
          setRun(false);
          setCheckingTarget(true);

          // Wait for the element to appear
          waitForElement(
            step.target,
            () => {
              setCheckingTarget(false);
              setRun(true);
            },
            () => {
              // Mark this specific step as skipped since we couldn't show it
              dispatch(
                updateTourStepThunk({
                  tourId: step.tourId,
                  stepId: step.stepId,
                  action: 'not_started',
                  status_filter: ['not_started', 'opened'],
                })
              );
              // Move to next step
              setCheckingTarget(false);
              setStepIndex(prev => prev + 1);
              setRun(true);
            },
            5000
          );
        }
      }
    }

    // When user completes a step normally
    if (type === EVENTS.STEP_AFTER) {
      // Map Joyride action to backend status
      let newStatus = action;
      if (action === ACTIONS.NEXT) newStatus = 'completed';
      if (action === ACTIONS.PREV) newStatus = 'opened';
      if (action === ACTIONS.SKIP) newStatus = 'skipped';
      if (action === ACTIONS.CLOSE) newStatus = 'completed';

      dispatch(
        updateTourStepThunk({
          tourId: step.tourId,
          stepId: step.stepId,
          action: newStatus,
          status_filter: ['not_started', 'opened'],
        })
      );

      // Update step index based on action
      if (action === ACTIONS.PREV) {
        setStepIndex(prev => prev - 1);
      } else {
        setStepIndex(prev => prev + 1);
      }
    }

    // Handle target not found separately
    if (type === EVENTS.TARGET_NOT_FOUND) {

      // Mark as skipped, not completed
      dispatch(
        updateTourStepThunk({
          tourId: step.tourId,
          stepId: step.stepId,
          action: 'not_started',
          status_filter: ['not_started', 'opened'],
        })
      );

      // Move to next step
      setStepIndex(prev => prev + 1);
    }

    // If the entire tour is finished or user pressed skip
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && activeTour) {
      // End local flow
      setRun(false);

      // Only skip all steps if user explicitly skipped the tour
      if (status === STATUS.SKIPPED) {
        skipAllIncompleteSteps(activeTour, dispatch);

        // Don't re-run this tour since it was explicitly skipped
        setCompletedTourIds(prev => new Set([...prev, activeTour.tour_id]));
      }
      // For CLOSE actions
      // else if (action === ACTIONS.CLOSE) {
      //   console.log('Tour was closed but not skipped, will not show again this session');

      //   // Store the ID to prevent showing again this session
      //   const closedTourId = activeTour.tour_id;
      //   setSessionClosedTourIds(prev => new Set([...prev, closedTourId]));
      // }
      // For normal completion
      else {
        // Don't re-run this tour since it was completed
        setCompletedTourIds(prev => new Set([...prev, activeTour.tour_id]));
      }

      // Clear current tour
      setActiveTour(null);

      // Trigger a re-evaluation after a brief delay
      // This will check if any other tours should be shown
      setTimeout(() => {

        // Force refresh the observer to detect new elements
        if (targetObserverRef.current) {
          targetObserverRef.current.disconnect();
          targetObserverRef.current = null;

          // Force the next effect run to re-establish the observer
          const toursCopy = [...tours];
          dispatch(
            getToursThunk({
              statuses: ['not_started', 'opened'],
              forceRefresh: true // Add a parameter to force redux to update
            })
          );
        }

        debouncedForceReconsider();
      }, 300);
    }
  };

  // 8) If no user, or no steps, or loading => no Joyride
  if (!auth?.token || error || loading || !steps.length || allToursDisabled) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={stepIndex}
      continuous
      showProgress
      showSkipButton
      // scrollToFirstStep
      callback={handleJoyrideCallback}
      spotlightClicks
      beaconComponent={MousePointerBeacon}
      tooltipComponent={(props) => (
        <CustomTooltip 
          {...props} 
          onDisableAllTours={handleDisableAllTours} 
        />
      )}
      styles={{
        options: {
          zIndex: 10000, // Very high z-index to appear above everything
        },
        // Make beacons and tooltips more visible
        beacon: {
          outer: {
            zIndex: 10001,
            position: 'relative'
          },
          inner: {
            zIndex: 10001
          }
        },
        tooltip: {
          zIndex: 10001,
          position: 'relative'
        },
        overlay: {
          zIndex: 9999
        },
        buttonNext: {
          backgroundColor: 'green',
          color: 'white'
        }
      }}
      debug={false}
    />
  );
}

export default GlobalOnboardingOverlay;