import { useState, useEffect, useRef } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Step, Stepper } from 'components/Stepper';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import Logo from './components/Logo';
import Initial from './steps/Initial';
import Marketplace from './steps/Marketplace';
import SelectFlow from './steps/SelectFlow';
import Quiz from './steps/Quiz';
import Models from './steps/Models';
import { encodeData } from 'utils/formatContent';
import Brain from './steps/Brain';
import CreateModel from '../Model/components/CreateModel';
import { setEveryToolsStatus } from "redux/tools";
import {cleanEmails} from "redux/email";
import useGenerateModelInstructions from 'hooks/useGenerateModelInstructions';
import { customModelData, presetData } from '../Model/components/CreateModel/presetData';
import {sendUIEvent} from "../../../events/analytics/google";
import styles from './onboarding.module.css';

const data = {
  monetization: [
    {
      name: 'sell',
      title: 'What service(s) would you like me to monetize?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true }, // For "none" or blank selection
        {
          label: 'Selling unique knowledge or data',
          placeholder: 'E.g. "Describe the specialized knowledge or dataset you plan to offer subscribers."'
        },
        {
          label: 'Offering specialized consultation',
          placeholder: 'E.g. "Explain your consultation scope: e.g., business coaching, health tips, or design advice."'
        },
        {
          label: 'Providing educational courses or tutorials',
          placeholder: 'E.g. "Briefly describe the course topics or learning paths you’ll provide."'
        },
        {
          label: 'Create a niche tool or service',
          placeholder: 'E.g. "Mention how this tool will serve your audience: e.g., daily quizzes, advanced analytics, etc."'
        }
      ],
      other: 'Example: "I want to build an AI that provides daily investment insights, referencing both my private data and external APIs that I will connect to this AI."'
    },
    {
      name: 'target',
      title: 'Who is your target market?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        { label: 'General public', placeholder: 'E.g. "What general topics or services will you offer to the public?"' },
        { label: 'Targeted audience', placeholder: 'E.g. "What specific interests or needs will you cater to?"' },
        { label: 'Businesses', placeholder: 'E.g. "What industry or business size will you focus on?"' },
        { label: 'Followers', placeholder: 'E.g. "Briefly describe the type of followers or fans you have."' }
      ],
      other: 'Example: My target market includes hobbyist photographers who want tutorials based on their uploaded images.'
    },
    {
      name: 'userExperience',
      title: 'How should I interact with users?',
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'Immediate answers, minimal questions',
          disableTextArea: true
        },
        {
          label: 'Ask questions before providing insights',
          placeholder: 'E.g. "Which questions do you want me to ask first? (Skill level, goals, etc.)"'
        },
        {
          label: 'Offer a short user quiz or onboarding flow',
          placeholder: 'E.g. "How many questions and what key areas do you want to cover in the quiz?"'
        }
      ],
      other: 'Example: "I want the AI to prompt users for their goals or skill level, then tailor the content before providing recommendations."'
    },
    {
      name: 'connectedApps',
      title: 'Which advanced features should I use in this monetized AI?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'None - just my personalized content',
          disableTextArea: true
        },
        {
          label: 'Use Creative Image Generator',
          placeholder: 'E.g. "Any particular style or types of images you want me to generate for users?"'
        },
        {
          label: 'Perform online searches for fresh data',
          placeholder: 'E.g. "Specific sources or topics to focus on during searches?"'
        },
        {
          label: 'Send emails or newsletters to users',
          placeholder: 'E.g. "What should trigger the email, and what content should it include?"'
        }
      ],
      other: 'Example: "I’d like to integrate multiple APIs for stock market data and news feeds to provide real-time insights to subscribers."'
    },
    {
      name: 'toneOrStyle',
      title: 'What style or tone do you want me to adopt with your customers?',
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'Friendly and relaxed',
          disableTextArea: true
        },
        {
          label: 'Professional and concise',
          disableTextArea: true
        },
        {
          label: 'Playful/humorous',
          disableTextArea: true
        },
        {
          label: 'Decide on your own',
          disableTextArea: true
        }
      ],
      other: 'Example: "I want a friendly, approachable vibe—but keep it professional if users ask detailed questions."'
    }
  ],

  assistant: [
    {
      name: 'role',
      title: 'What is your current role or industry?',
      multipleChoice: true,
      otherTop: true,
      options: [
        { label: '', disableTextArea: true },
        { label: 'Customer Service', disableTextArea: true },
        { label: 'Copywriter', disableTextArea: true },
        { label: 'Marketing', disableTextArea: true },
        { label: 'Sales', disableTextArea: true },
        { label: 'Education', disableTextArea: true },
        { label: 'Legal', disableTextArea: true },
        { label: 'Research', disableTextArea: true },
        { label: 'Data Analyst', disableTextArea: true },
        { label: 'SEO/SMM', disableTextArea: true },
        { label: 'Software', disableTextArea: true },
        { label: 'Account Manager', disableTextArea: true },
        { label: 'Working professional', disableTextArea: true },
        { label: 'Student', disableTextArea: true },
        { label: 'Job seeker', disableTextArea: true }
      ],
      other: 'Example: I currently work as a Senior Project Manager, leading teams in planning, executing, and closing projects within tight deadlines.'
    },
    {
      name: 'routineTasks',
      title: 'Which tasks or routines do you typically handle at work?',
      multipleChoice: true,
      otherTop: true,
      options: [
        { label: '', disableTextArea: true },
        { label: 'Emails and correspondence' },
        { label: 'Creating marketing or social media content' },
        { label: 'Conducting online research' },
        { label: 'Analyzing data or generating insights' },
        { label: 'Project planning / task management' },
        { label: 'Brainstorming ideas / strategy' },
        { label: 'Software development / coding' },
        { label: 'Reformatting or summarizing text' },
        { label: 'Drafting reports or documents' }
      ],
      other: 'Example: "I manage my team’s backlog in Jira, plus compile weekly status reports for stakeholders."'
    },
    {
      name: 'desiredFeatures',
      title: 'Which of my special skills would you like me to use?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'None - keep it simple for now',
          disableTextArea: true
        },
        {
          label: 'Image generation (Creative Image Generator)',
          placeholder: 'E.g. "Specify any style or type of images you want me to create."'
        },
        {
          label: 'Online search for real-time info',
          placeholder: 'E.g. "Should I look for competitor data, news, or general industry info?"'
        },
        {
          label: 'Send emails on my behalf',
          placeholder: 'E.g. "What should trigger the email, and what content should it include?"'
        },
        {
          label: 'Custom API integrations',
          placeholder: 'E.g. "Which APIs will you connect me to? What I should do with the data?"'
        }
      ],
      other: 'Example: "I’d like me to use online search and connect with my Salesforce API for real-time customer data."'
    },
    {
      name: 'specialKnowledge',
      title: 'Are there any brand guidelines, compliance rules, or unique data I must respect?',
      // multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'No, keep it general',
          disableTextArea: true
        }
      ],
      other: 'Example: "I handle legal documents—don’t give legal advice or reveal confidential info."'
    },
    {
      name: 'vision',
      title: 'What would you like your AI to do for you?',
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'I’m not sure - suggest ideas',
          disableTextArea: true
        }
      ],
      customOther: 'I have something in mind',
      other: 'Example: "I want you to help me draft marketing materials, research topics, and generate emails that align with our brand voice."'
    }
  ],

  'chat-bot': [
    {
      name: 'type',
      title: 'What type of website do you operate?',
      otherTop: true,
      options: [
        { label: '', disableTextArea: true },
        { label: 'E-commerce store', placeholder: 'E.g. "I sell handmade jewelry and accessories online."' },
        { label: 'Educational / informational', placeholder: 'E.g. "I run a blog with tutorials and resources for aspiring writers."' },
        { label: 'Entertainment / content platform', placeholder: 'E.g. "I host a podcast network with multiple shows and guest interviews."' },
        { label: 'Blog or news site', placeholder: 'E.g. "I write about the latest tech news and reviews."' },
        { label: 'Event / membership site', placeholder: 'E.g. "I host virtual events and offer exclusive content to subscribers."' },
        { label: 'Service-based business', placeholder: 'E.g. "I offer web design and digital marketing services."' },
        { label: 'Portfolio or personal site', placeholder: 'E.g. "I showcase my photography portfolio and offer prints for sale."' },
        { label: 'Forum or community', placeholder: 'E.g. "I run a forum for indie game developers to share tips and resources."' },
      ],
      other: 'Example: "I have a membership-based fitness site, offering customized workout plans and recipes."'
    },
    {
      name: 'purpose',
      title: 'What main purpose should I serve on your website?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'Customer support (basic Q&A, quick help)',
          placeholder: 'E.g. "What common questions or issues should I address for customers?"'
        },
        {
          label: 'Product or service guidance',
          placeholder: 'E.g. "What specific products or services should I help users find or learn about?"'
        },
        {
          label: 'Upselling or cross-selling',
          placeholder: 'E.g. "What products or services should I recommend based on user interactions?"'
        },
        {
          label: 'Content search / knowledge base assistance',
          placeholder: 'E.g. "What topics or resources should I help users find on your site?"'
        },
        {
          label: 'Ticket filing or escalation',
          placeholder: 'For better personalization, explain where to send tickets, conditions, etc.'
        },
        {
          label: 'Event scheduling or registration flow',
          placeholder: 'E.g. "Do you want me to handle RSVPs, time slots, or follow-up reminders?"'
        },
        {
          label: 'General visitor engagement / lead collection',
          placeholder: 'E.g. "Any specific data you want from leads (emails, phone, area of interest)?"'
        }
      ],
      other: 'Example: "I want the AI to act as a virtual coordinator, handling schedules and event registrations."'
    },
    {
      name: 'chatFeatures',
      title: 'Which advanced capabilities would you like me to have?',
      multipleChoice: true,
      options: [
        { label: '', disableTextArea: true },
        {
          label: 'None - just text-based Q&A',
          disableTextArea: true
        },
        {
          label: 'Research online for real-time info',
          placeholder: 'E.g. "Are there certain websites or references you want me to prioritize?"'
        },
        {
          label: 'Generate images',
          placeholder: 'E.g. "Do you want me to create product mockups, illustrations, or icons?"'
        },
        {
          label: 'Collect or send emails for follow-up',
          placeholder: 'E.g. "What should trigger the email, and what content should it include?"'
        }
      ],
      other: 'Example: "I’d like it to book appointments via Google Calendar and handle support ticket emails for advanced issues."'
    },
    {
      name: 'avoidTopics',
      title: 'Are there topics or data you want me to avoid or disclaimers to include?',
      customOther: 'Yes',
      options: [
        { label: '', disableTextArea: true },
        { label: 'No', disableTextArea: true }
      ],
      other: 'Example: "Avoid legal or financial advice. Direct them to a professional if they ask."'
    }
  ],

  'customer-support': [
    {
      name: 'type',
      title: 'What product do you need support for?',
      isOptional: false,
      expanded: true,
      options: [],
      customOther: '',
      other: 'Example: "I need support for the XYZ Smart Thermostat, which lets users control home heating/cooling via an app."'
    },
    {
      name: 'questions',
      title: 'What questions should be asked before support?',
      options: [
        {
          label: 'I am not sure',
          disableTextArea: true
        }
      ],
      customOther: '',
      isOptional: true,
      expanded: true,
      other: 'Example: "Ask if the issue is with the device or app, what the specific error is, and if any solutions were tried."'
    },
    {
      name: 'contact',
      title: 'Provide business contact details for further assistance (optional).',
      options: [
        { label: 'I will add later', disableTextArea: true }
      ],
      isOptional: true,
      expanded: true,
      other: 'Example: "For more help or refunds, call (123) 456-7890 or email support@xyzcompany.com."'
    }
  ]
};


const Onboarding = ({ initialStep }) => {
  // TODO: mode state to context
  const [answers, setAnswers] = useState([]);
  const [textInputText, setTextInputText] = useState('');
  const [finishedSteps, setFinishedSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('initial');
  const [selectedModel, setSelectedModel] = useState(null);
  const [isCustomModel, setIsCustomModel] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState('assistant'); // assistant, chat-bot, monetization
  const [finishedStepNames, setFinishedStepNames] = useState([]);
  const [animatedSteps, setAnimatedSteps] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [stage, setStage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedModels, setRenderedModels] = useState([]);
  const [renderedGeneratedModels, setRenderedGeneratedModels] = useState([]);
  const [isFormShowed, setIsFormShowed] = useState(false);
  const [isInitialStepShowed, setIsInitialStepShowed] = useState(false);
  const { handleGenerateModelInstructions, modelInstructions, isGenerating } = useGenerateModelInstructions();
  const [models, setModels] = useState(modelInstructions);
  const stageTimeoutRef = useRef(null);
  const logoRef = useRef(null);
  const steps = {
    assistant: ['initial', 'selectFlow', 'role', 'routineTasks', 'desiredFeatures', 'specialKnowledge', 'vision', 'modelSelect', 'modelForm', 'modelBrain'],
    monetization: ['initial', 'selectFlow', 'sell', 'target', 'userExperience', 'connectedApps',  'toneOrStyle', 'modelSelect', 'modelForm', 'modelBrain'],
    'chat-bot': ['initial', 'selectFlow', 'type', 'purpose', 'chatFeatures', 'avoidTopics', 'modelSelect', 'modelForm', 'modelBrain'],
    'customer-support': ['initial', 'selectFlow', 'type', 'questions', 'contact', 'modelSelect', 'modelForm', 'modelBrain'],
  };
  const fullHeightSteps = ['modelForm'];
  const model = useSelector((state) => state.models?.current, shallowEqual);
  const isBrainOnboardingEnabled = localStorage.getItem('brainOnboarding');
  const [queryParameters] = useSearchParams();
  const continueOnboarding = queryParameters.get('continueOnboarding');
  const dispatch = useDispatch();
  const isNewUser = !Boolean(model?.id);

  const setStageToLoading = () => {
    setStage(1);
    stageTimeoutRef.current = setTimeout(() => {
      setStage(3);
    }, 400);
    stageTimeoutRef.current = setTimeout(() => {
      setStage(1);
    }, stage > 1 ? 7200 : 5200);
  };

  useEffect(() => {
    // localStorage.removeItem('onboardingBrainStatus');
    dispatch(cleanEmails());
    dispatch(setEveryToolsStatus({ status: {text: null, qa: null, files: null, emails: null, links: null}}));
  }, []);

  useEffect(() => {
    if (isGenerating) {
      setStage(3);
      setIsLoading(true);
    }
    if (!isGenerating) {
      if (modelInstructions?.length) {
        setTimeout(() => {
          setStage(1);
          setIsLoading(false);
        }, 3000 * modelInstructions.length + 1000);
      } else {
        setIsLoading(false);
        setStage(1);
        setModels(modelInstructions);
      }
    }
  }, [isGenerating, modelInstructions]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if(steps[selectedFlow][currentStep] !== 'modelBrain') {
        e.preventDefault();
        e.returnValue = '';
        sendUIEvent({name: `onboarding_intent_leave_${selectedFlow}_${steps[selectedFlow][currentStep]}`});
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentStep]);
  
  useEffect(() => {
    if (['marketplace', 'selectFlow'].includes(currentStepName)) {
      logoRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    if (finishedStepNames.includes(currentStepName)) return;
    if (isNewUser) {
      setStageToLoading();
    }
    setTextInputText('');

    return () => {
      clearTimeout(stageTimeoutRef.current);
    };
  }, [currentStepName]);

  useEffect(() => {
    setStage(1);
  }, [animatedSteps]);
  
  useEffect(() => {
    clearTimeout(stageTimeoutRef.current);
    
    if (isFocused) {
      setStage(2);
    } else {
      setStage(1);
    }
  }, [isFocused]);

  return (
    <Box sx={{ height: 'inherit', overflowY: {xs: 'none', md: 'auto'}, display: {xs: 'block', md: 'flex'}, position: 'relative', padding: '0 10px' }}>
      <Grid container mt={6} sx={{ minHeight: currentStepName === 'marketplace' ? '100%' : 'none', height: currentStepName === 'marketplace' ? 'none' : fullHeightSteps.includes(currentStepName) ? '100%' : 'auto', margin: 'auto' }}>
        {currentStep !== data[selectedFlow].length + 3  && (
          <Grid item sx={{ width: '100%', textAlign: 'center' }} mb={10} ref={logoRef}>
            <Box sx={{ display: 'inline-block', marginTop: '50px' }}>
              <Logo stage={stage} isLoading={isGenerating} textInputText={textInputText} />
            </Box>
          </Grid>
        )}
        <Stepper initialStep={continueOnboarding ? 'modelBrain' : initialStep} updateAnyway onChangeStep={(step) => {
          const stepIndex = steps[selectedFlow].indexOf(step);

          setCurrentStep(stepIndex);
          setCurrentStepName(step);
          if (finishedSteps < stepIndex) {
            setFinishedSteps(stepIndex);
          }
          
        }}>
          <Step stepKey='initial'>
            {(stepBag) => (
              <Initial
                stepBag={stepBag}
                isNewUser={isNewUser}
                isInitialStepShowed={isInitialStepShowed}
                setIsInitialStepShowed={val => setIsInitialStepShowed(val)}
                finishedSteps={finishedSteps}
                setFlow={val => {
                  setSelectedFlow(val);
                }}
                finishedStepNames={finishedStepNames}
                setFinishedStepNames={setFinishedStepNames}
              />
            )}
          </Step>
          <Step stepKey='marketplace'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Marketplace
                    stepBag={stepBag}
                    isNewUser={isNewUser}
                    animatedSteps={animatedSteps}
                    finishedStepNames={finishedStepNames}
                  />
                </Box>
                <Button
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_marketplace', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    setFinishedStepNames([ ...finishedStepNames, 'marketplace' ]);
                    stepBag.setStep('initial');
                  }}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'}, 
                  }}
                >
                  <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          <Step stepKey='selectFlow'>
            {(stepBag) => (
              <>
                <SelectFlow
                  stepBag={stepBag}
                  isNewUser={isNewUser}
                  isFormShowed={isFormShowed}
                  setIsFormShowed={val => setIsFormShowed(val)}
                  finishedSteps={finishedSteps}
                  setFlow={val => {
                    setSelectedFlow(val);
                    setFinishedStepNames([ ...finishedStepNames, 'selectFlow' ]);
                  }}
                  setIsCustomModel={val => {
                    setIsCustomModel(val);
                    if (val) {
                      setSelectedModel(customModelData);
                    } else {
                      setSelectedModel(null);
                    }
                  }}
                  finishedStepNames={finishedStepNames}
                />
                <Button
                  className={styles.backBtn}
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_selectFlow', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    setFinishedStepNames([ ...finishedStepNames, 'selectFlow' ]);
                    stepBag.setStep('initial');
                  }}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    // position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'},
                  }}
                >
                  <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          {data[selectedFlow].map((item, i) => (
            <Step key={`step-${i}`} stepKey={item.name}>
              {(stepBag) => (
                <Quiz
                  item={item}
                  isNewUser={isNewUser}
                  index={i}
                  answers={answers}
                  setAnswers={setAnswers}
                  isFocused={isFocused}
                  setIsFocused={val => setIsFocused(val)}
                  stepBag={stepBag}
                  setTextInputText={val => setTextInputText(val)}
                  textInputText={textInputText}
                  finishedSteps={finishedSteps}
                  setAnimatedSteps={val => setAnimatedSteps(val)}
                  animatedSteps={animatedSteps}
                  steps={steps[selectedFlow]}
                  quizLength={data[selectedFlow].length + 2}
                  getModels={val => {
                    handleGenerateModelInstructions(val, encodeData(selectedFlow));
                  }}
                  finishedStepNames={finishedStepNames}
                  setFinishedStepNames={setFinishedStepNames}
                />
              )}
            </Step>
          ))}
          <Step stepKey='modelSelect'>
            {(stepBag) => (
              <>
                <Models
                  stepBag={stepBag}
                  isNewUser={isNewUser}
                  answers={answers}
                  finishedSteps={finishedSteps}
                  modelInstructions={modelInstructions}
                  isLoading={isLoading}
                  setIsLoading={val => setIsLoading(val)}
                  setSelectedModel={model => {
                    if (Number(stepBag.step) >= finishedSteps) {
                      setFinishedSteps(finishedSteps + 1);
                    }
                    setSelectedModel(model);
                  }}
                  setRenderedModels={val => setRenderedModels(val)}
                  renderedModels={renderedModels}
                  isGenerating={isGenerating}
                  setRenderedGeneratedModels={val => setRenderedGeneratedModels(val)}
                  renderedGeneratedModels={renderedGeneratedModels}
                  setStage={val => setStage(val)}
                  setModels={val => setModels(val)}
                  models={models}
                  getModels={() => {
                    handleGenerateModelInstructions(answers, encodeData(selectedFlow));
                  }}
                  selectedFlow={selectedFlow}
                />
                <Button
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_selectModel', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    stepBag.setStep(data[selectedFlow][data[selectedFlow].options.length - 1]?.name);
                    setFinishedSteps(data[selectedFlow].options.length + 1);
                    setAnimatedSteps({...animatedSteps, routine: true});
                    setRenderedModels([]);
                    setRenderedGeneratedModels([]);
                    setModels(null);
                  }}
                  disabled={isLoading}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'},
                  }}
                >
                  <Box sx={{display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          <Step stepKey='modelForm'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <CreateModel preselectedModel={selectedModel} generatedModels={modelInstructions} stepBag={stepBag} />
                  <Button
                    onClick={() => {
                      window.gtag('event', 'Onboarding_click_back_createModel', {
                        'event_category': 'Onboarding',
                        'event_label': 'Onboarding',
                        'value': 'click back',
                        'step': stepBag.step,
                        'width': window.innerWidth,
                      });
                      setSelectedModel(null);
                      if (isCustomModel) {
                        stepBag.setStep('selectFlow');
                        setFinishedSteps(0);
                        setAnimatedSteps({ ...animatedSteps, initial: true })
                      } else {
                        stepBag.setStep('modelSelect');
                      }
                    }}
                    startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                    sx={{
                      position: 'sticky',
                      bottom: '10px',
                      left: '10px',
                      color: '#7e7e7e',
                      background: '#fff',
                      minWidth: {xs: '32px', md: 'auto'}, 
                      height: {xs: '40px', md: 'auto'},
                    }}
                  >
                    <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                      Back
                    </Box>
                  </Button>
                </Box>
              </>
            )}
          </Step>
          <Step stepKey='modelBrain'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%' }}>
                  <Brain modelId={model.id} intention={selectedFlow} isNewUser={isNewUser} />
                </Box>
              </>
            )}
          </Step>
        </Stepper>
      </Grid>
    </Box>
  );
};

export default Onboarding;
