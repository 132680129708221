import { updateTourStepThunk } from 'redux/tours/toursThunk';
import MarkdownComponent from "components/MarkdownComponent";

/**
 * Picks the first incomplete tour (that matches route & has incomplete steps).
 */
export function pickFirstIncompleteTour(allTours, currentPath) {
    const matchingTours = allTours
        .filter((t) => t.active && currentPath.includes(t.page || ''))
        .filter((t) => {
            const stepsArray = Array.isArray(t.steps)
                ? t.steps
                : Object.values(t.steps || {});
            return stepsArray.some((s) => ['not_started', 'opened'].includes(s.status));
        });

    if (!matchingTours.length) return null;
    return matchingTours[0];
}

/**
 * Same as above, but also excludes any tours that are in `alreadyDoneIds`.
 */
export function pickFirstIncompleteTourExcluding(allTours, currentPath, alreadyDoneIds) {
    if (!allTours?.length) return null;

    // Filter for eligible tours
    const eligibleTours = allTours
        .filter(t => t.active)
        .filter(t => !alreadyDoneIds.has(t.tour_id))
        .filter(t => {
            const stepsArray = Array.isArray(t.steps)
                ? t.steps
                : Object.values(t.steps || {});
            return stepsArray.some(s => s.active && ['not_started', 'opened'].includes(s.status));
        })
        // Add tour dependencies check
        .filter(t => {
            // If tour has dependencies, check if they've been completed
            if (t.dependencies?.required_tours && Array.isArray(t.dependencies.required_tours)) {
                // Check if all required tours are in completedTourIds OR are effectively completed
                const allDependenciesMet = t.dependencies.required_tours.every(requiredTourId => {
                    // Case 1: Tour ID is in the alreadyDoneIds set (session memory)
                    if (alreadyDoneIds.has(requiredTourId)) {
                        return true;
                    }
                    
                    // Case 2: Required tour doesn't exist in the array (filtered out because completed)
                    const requiredTour = allTours.find(tour => tour.tour_id === requiredTourId);
                    if (!requiredTour) {
                        return true;
                    }
                    
                    // Case 3: Required tour exists but has no steps
                    const stepsArray = Array.isArray(requiredTour.steps)
                        ? requiredTour.steps
                        : Object.values(requiredTour.steps || {});
                        
                    if (!stepsArray.length) {
                        return true;
                    }
                    
                    // Case 4: Required tour exists but all steps are completed/skipped
                    const allStepsCompleted = stepsArray.every(step => 
                        step.status === 'completed' || 
                        step.status === 'skipped'
                    );
                    
                    return allStepsCompleted;
                });
        
                if (!allDependenciesMet) {
                    return false;
                }
            }
            return true;
        });

    if (eligibleTours.length === 0) return null;

    // Score tours based on multiple factors
    const scoredTours = eligibleTours.map(tour => {
        // 1. Calculate path relevance score
        let pathScore = 0;
        if (tour.page) {
            if (currentPath === tour.page) {
                pathScore = 1000; // Exact match
            } else if (currentPath.startsWith(tour.page + '/')) {
                pathScore = 500 + tour.page.length; // Current path is sub-path
            } else if (tour.page.startsWith(currentPath + '/')) {
                pathScore = 250 + currentPath.length; // Tour page is sub-path of current
            } else if (currentPath.includes(tour.page)) {
                pathScore = 100 + tour.page.length; // Current path contains tour page
            }
        }

        // 2. Add priority if defined
        const priorityScore = (tour.priority || 0) * 10;

        // 3. Get steps for DOM checking
        const stepsArray = Array.isArray(tour.steps)
            ? tour.steps
            : Object.values(tour.steps || {});

        const activeIncompleteSteps = stepsArray
            .filter(s => s.active && ['not_started', 'opened'].includes(s.status))
            .sort((a, b) => (a.order || 0) - (b.order || 0));

        // 4. Check DOM presence for first step
        let firstStepInDOM = false;
        let domElementsFound = 0;
        let totalDomWeight = 0;

        // Check first 3 steps maximum
        const stepsToCheck = activeIncompleteSteps.slice(0, 3);

        for (const step of stepsToCheck) {
            if (step.target) {
                try {
                    if (document.querySelector(step.target)) {
                        // Give more weight to earlier steps
                        const stepWeight = stepsToCheck.length - stepsToCheck.indexOf(step);
                        domElementsFound++;
                        totalDomWeight += stepWeight * 200;

                        if (!firstStepInDOM && step === stepsToCheck[0]) {
                            firstStepInDOM = true;
                        }
                    }
                } catch (err) {
                    console.error(`Invalid selector in tour ${tour.tour_id}, step ${step.step_id}:`, err);
                }
            }
        }

        // 5. Calculate final score - DOM presence is most important
        // First step in DOM is highest priority, then DOM weight (which accounts for how many steps are visible
        // and their position in the sequence), then path score, then priority
        const domScore = firstStepInDOM ? 20000 : totalDomWeight;

        return {
            tour,
            score: domScore + pathScore + priorityScore,
            pathScore,
            domScore,
            domElementsFound,
            firstStepInDOM
        };
    });

    // Sort by total score (highest first)
    scoredTours.sort((a, b) => b.score - a.score);

    // Prefer tours with DOM elements present
    const bestTour = scoredTours[0]?.tour || null;

    return bestTour;
}

/**
 * Convert a tour's steps into Joyride's step objects.
 */
export function flattenTourSteps(tour) {
    const stepsArray = Array.isArray(tour.steps)
        ? tour.steps
        : Object.values(tour.steps || {});

    const relevantSteps = [];
    
    stepsArray.forEach((step) => {
        if (!step.active) return;
        if (!['not_started', 'opened'].includes(step.status)) return;

        // dependencies check
        if (step.dependencies?.required_step) {
            const req = stepsArray.find((s) => s.step_id === step.dependencies.required_step);
            if (req && req.status !== 'completed') return;
        }
        let textContent = step.content.replace(/\\n/g, '\n') || '';
        
        relevantSteps.push({
            target: step.target,
            content: (
                <div style={{ maxWidth: step.popup_details?.width || 'auto', 
                    textAlign: step.popup_details?.align || 'left'
                }}>
                    {step.media_url && (
                        <video src={step.media_url} controls loop muted playsInline={true} style={{ width: '100%', marginTop: 10 }} autoPlay />
                    )}
                    <MarkdownComponent source={textContent} />
                </div>
            ),
            tourId: tour.tour_id,
            stepId: step.step_id,
            locale: {
                back: step.button_secondary_text || 'Back',
                close: step.button_secondary_text || 'Skip',
                last: step.button_primary_text || 'Finish',
                next: step.button_primary_text || 'Next',
                skip: step.button_secondary_text || 'Skip',
            },
        });
    });
    return relevantSteps;
}

/**
 * Wait up to `maxWait` ms for a missing DOM element to appear.
 * Once found, calls onFound(). If not found in time, calls onTimeout().
 */
export function observeTarget(selector, onFound, onTimeout, maxWait = 2000) {
    // Check if it's already in the DOM
    if (document.querySelector(selector)) {
        onFound();
        return;
    }

    let timeoutId = null;
    let observer = null;

    function stopAll() {
        if (observer) observer.disconnect();
        if (timeoutId) clearTimeout(timeoutId);
    }

    // if not found within maxWait => onTimeout
    timeoutId = setTimeout(() => {
        stopAll();
        onTimeout();
    }, maxWait);

    observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
            stopAll();
            onFound();
        }
    });

    // watch the entire doc (or a specific container) for new child nodes
    observer.observe(document.body, { childList: true, subtree: true });
}

/**
 * skipAllIncompleteSteps
 * Marks all incomplete steps of a given tour as "skipped".
 * This is useful if the user closes or skips the entire tour, 
 * so your backend sees the rest as "skipped" rather than "not_started".
 */
export function skipAllIncompleteSteps(tour, dispatch) {
    if (!tour || !tour.tour_id || !tour.steps) return;

    const stepsArray = Array.isArray(tour.steps)
        ? tour.steps
        : Object.values(tour.steps || {});

    stepsArray.forEach((step) => {
        if (['not_started', 'opened'].includes(step.status)) {
            dispatch(
                updateTourStepThunk({
                    tourId: tour.tour_id,
                    stepId: step.step_id,
                    action: 'skipped',
                    status_filter: ['not_started', 'opened'],
                })
            );
        }
    });
}
// end of code change

export function waitForElement(selector, onFound, onTimeout, maxWait = 3000) {
    if (document.querySelector(selector)) {
        onFound();
        return;
    }
    let stopped = false;
    let observer = null;
    const timeoutId = setTimeout(() => {
        stopped = true;
        if (observer) observer.disconnect();
        onTimeout();
    }, maxWait);

    observer = new MutationObserver(() => {
        if (stopped) return;
        if (document.querySelector(selector)) {
            stopped = true;
            clearTimeout(timeoutId);
            observer.disconnect();
            onFound();
        }
    });
    observer.observe(document.body, { childList: true, subtree: true });
}

/**
 * Creates a watcher that monitors DOM for any tour targets appearing
 * and triggers the callback when eligible tour targets appear.
 */
export function createTourTargetObserver(allTours, completedTourIds, onTargetAppeared) {
    // Early return if no tours to watch
    if (!allTours?.length) return { disconnect: () => { } };

    // Extract all potential target selectors from incomplete tours
    const potentialTargets = new Set();
    const tourIdsByTarget = new Map(); // Maps selectors to tour IDs they belong to

    allTours
        .filter(tour => tour.active && !completedTourIds.has(tour.tour_id))
        .forEach(tour => {
            const stepsArray = Array.isArray(tour.steps)
                ? tour.steps
                : Object.values(tour.steps || {});

            // Only consider first 3 steps of each tour for performance
            stepsArray
                .filter(step => step.active && ['not_started', 'opened'].includes(step.status))
                .slice(0, 3)
                .forEach(step => {
                    if (step.target && typeof step.target === 'string') {
                        potentialTargets.add(step.target);

                        // Track which tour this target belongs to
                        if (!tourIdsByTarget.has(step.target)) {
                            tourIdsByTarget.set(step.target, new Set());
                        }
                        tourIdsByTarget.get(step.target).add(tour.tour_id);
                    }
                });
        });

    // If no targets to observe, return empty observer
    if (potentialTargets.size === 0) {
        return { disconnect: () => { } };
    }

    // Track which targets we've already found to avoid duplicate notifications
    const foundTargets = new Set();

    // Create the observer
    const observer = new MutationObserver((mutations) => {
        let shouldNotify = false;
        const newlyFoundTargets = [];

        // Check if any of our potential targets appeared
        potentialTargets.forEach(selector => {
            // Skip if we already found this one
            if (foundTargets.has(selector)) return;

            try {
                if (document.querySelector(selector)) {
                    foundTargets.add(selector);
                    shouldNotify = true;
                    newlyFoundTargets.push(selector);

                    // Get associated tour IDs
                    const tourIds = tourIdsByTarget.get(selector) || new Set();
                }
            } catch (err) {
                console.error(`Invalid selector in tour target observer: ${selector}`, err);
            }
        });

        // If we found new targets, notify the callback
        if (shouldNotify && typeof onTargetAppeared === 'function') {

            // Use a short debounce to avoid multiple rapid fires
            clearTimeout(observer._notifyTimeout);
            observer._notifyTimeout = setTimeout(() => {
                onTargetAppeared(newlyFoundTargets);
            }, 100);
        }
    });

    // Start observing
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true  // Also watch for attribute changes
    });

    // Add a manual check for elements that might already exist
    potentialTargets.forEach(selector => {
        try {
            if (document.querySelector(selector)) {
                foundTargets.add(selector);
                // Get associated tour IDs
                const tourIds = tourIdsByTarget.get(selector) || new Set();
            }
        } catch (err) {
            console.error(`Invalid selector during initial check: ${selector}`, err);
        }
    });

    // If we found targets during initial check, notify
    if (foundTargets.size > 0) {

        setTimeout(() => {
            if (typeof onTargetAppeared === 'function') {
                onTargetAppeared(Array.from(foundTargets));
            }
        }, 100);
    }

    return observer;
}

