import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api'; 
// Or controlledAPI, depending on your needs

/**
 * Fetch tours from the server, optionally filtered by `statuses` and `current_page`.
 * @param {{ statuses?: string[], current_page?: string }} payload
 */
export const getToursThunk = createAsyncThunk('tours/getTours', async (payload, thunkAPI) => {
  const { statuses, current_page } = payload || {};
  try {
    // Build querystring: e.g. ?statuses[]=opened&statuses[]=not_started&current_page=playground
    // if not provided, fetch them all.
    const params = new URLSearchParams();
    if (statuses && Array.isArray(statuses)) {
      statuses.forEach((s) => params.append('statuses[]', s));
    }
    if (current_page) {
      params.append('current_page', current_page);
    }

    const queryString = params.toString();
    const response = await api.get(`/tours?${queryString}`);

    if (response.code >= 400) {
      throw new Error(response.message || 'Error fetching tours');
    }
    return { tours: response.tours || [] };
  } catch (e) {
    const message = e?.response?.message || e?.message || 'Something went wrong fetching tours';
    // return { tours: [] };
    return thunkAPI.rejectWithValue(message);
  }
});

/**
 * Update a specific step's status, e.g. "opened" -> "completed".
 * Expects server returns updated full tours array or partial updates. 
 */
export const updateTourStepThunk = createAsyncThunk(
  'tours/updateTourStep',
  async (payload, thunkAPI) => {
    const { tourId, stepId, action, status_filter } = payload;
    try {
      // e.g. POST /tours/custom_model_onboard/steps/cmo_step1
      const response = await api.post(`/tours/${tourId}/steps/${stepId}`, {
        action, // e.g. "opened", "completed", "skipped"
        status_filter, // optional
      });

      if (response.code >= 400) {
        throw new Error(response.message || 'Error updating tour step');
      }
      // Return the entire updated tours array (if that's what the server sends), 
      // or the updated single tour
      return { tours: response.tours || [] };
    } catch (e) {
      const message = e?.response?.message || e?.message || 'Something went wrong updating tour step';
      return thunkAPI.rejectWithValue(message);
    }
  }
);
