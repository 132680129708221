import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RoutesMapping from "_navigation/routes/all";
import history from "_helpers/history";
import ShareModel from "pages/ShareModel";
import Spinner from "components/loadings/Spinner";
import ShareModelMarketplace from "pages/ShareModelMarketplace";
import { getToken, setToken } from "_helpers/tokenManagement";
import { useSelector } from "react-redux";
import Events from "../components/userInteractions/Events";
import GlobalOnboardingOverlay from "components/userInteractions/OnboardingOverlay/GlobalOnboardingOverlay";

const MaintenanceMemo = lazy(() => import('components/userInteractions/Memos/Maintenance'));
const NavigationRoutes = () => {
  const isPortalPath = window.location.pathname.startsWith("/portal");

  const maintenance = useSelector(state => { return state.status.maintenance });
  useEffect(()=>{
    const referrer = getToken('referrer');
    const userAgent = getToken('userAgent');

    if (!referrer?.length) {
      setToken('referrer', document.referrer);
    }
    if (!userAgent) {
      setToken('userAgent', navigator?.userAgent);
    }
  }, []);

  if (maintenance.active) {
    let message = maintenance.message || null;
    return <Suspense fallback={<Spinner />}><MaintenanceMemo message={message} /></Suspense>;
  }

  /* fast solution -_- , add nested /portal repo later and update useNavigation hook to navigate through /portal root */

  return isPortalPath ? (
    <Router history={history} basename="/portal">
      <Events/>
      <RoutesMapping />
      <GlobalOnboardingOverlay />
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/:modelHash" element={<ShareModel />} />
        <Route path="/p/:modelHash" element={<ShareModel hideSideBar={true}/>} />
        <Route path="/m/:modelHash" element={<ShareModelMarketplace />} />

      </Routes>
    </Router>
  );
};

export default NavigationRoutes;
