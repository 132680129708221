import classnames from "classnames";
import styles from "../../onboarding.module.css";
import {AccordionDetails, AccordionSummary, Badge, Box, Chip, ListItemText} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailTraining from "../../../tools/Training/components/EmailTraining";
import EmailView from "../../../tools/Training/components/EmailTraining/components/EmailView";
import EmailSignIn from "../../../tools/Training/components/EmailTraining/components/EmailSignIn";
import CustomAccordion from "./CustomAccordion";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import WebsiteTraining from "../../../tools/Training/components/WebsiteTraining";
import FilesTraining from "../../../tools/Training/components/FilesTraining";
import ParseWebsiteContent from "../../../tools/Training/components/WebsiteTraining/components/ParseWebsiteContent";
import PublicIcon from '@mui/icons-material/Public';
import {setStatus} from "redux/tools";
import {memo, useEffect} from "react";
import ViewWebsite from "../../../tools/Training/components/WebsiteTraining/components/ViewWebsite";
import {deeperViewAllowed} from "redux/links";

export const WebsiteAccordion = ({onChange, className}) => {

    const dispatch = useDispatch();
    const linkStatus = useSelector((state) => state.tools.status.links, shallowEqual);
    const website = useSelector((state) => state.models.current?.data?.website, shallowEqual);
    const websiteExist = Boolean(website);
    const emailTranlingEnabled = Boolean(localStorage.getItem('emailLogin'));

    const handleStatusChange = (type) => {
        dispatch(setStatus({type: 'links', status: type}));
    }

    useEffect(() => {
        if (websiteExist) {
            onChange();
        }
        dispatch(deeperViewAllowed(false));

        return () => {
            dispatch(deeperViewAllowed(true));
        }


    }, []);

    return (

        <CustomAccordion
            onChange={onChange}
            className={className}
            defaultExpanded={websiteExist && !emailTranlingEnabled}
            // slotProps={{transition: {unmountOnExit: true}}}
            // onChange={onChange}
            // className={classnames(!isBrainOnboardingEnabled && styles.button)}
            id="website-tool"
        >

            <Badge
                // badgeContent="beta"
                size="small"
                color="secondary"
                sx={{
                    width: '100%',
                    '& .MuiBadge-badge': {
                        fontSize: '0.6rem',
                        padding: '0 6px',
                        height: '18px',
                        minWidth: '15px',
                        top: '-8px',
                        right: '-8px'
                    },
                }}
            >
                <AccordionSummary sx={{width: '100%'}}>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Box sx={{width: '100%'}}>
                            <PublicIcon sx={{fontSize: '18px', position: 'relative', top: '2px'}}/>&nbsp; Add
                            Website links content
                        </Box>
                        {linkStatus === 'done' && (
                            <CheckCircleIcon sx={{color: '#44a047'}}/>
                        )}
                        {linkStatus === 'pending' && (
                            <Chip label="In Progress" sx={{
                                borderRadius: '3px',
                                color: '#2e46fa',
                                background: '#eef0fc',
                                fontSize: '12px',
                                height: 'auto'
                            }}/>
                        )}
                    </Box>

                </AccordionSummary>
            </Badge>
            <AccordionDetails>
                <Box sx={{borderTop: '1px solid rgba(0,0,0, .1)', marginTop: '20px', paddingTop: '20px'}}>
                    <ParseWebsiteContent handleParse={handleStatusChange}/>
                </Box>

                <Box>
                    <ViewWebsite/>
                </Box>
            </AccordionDetails>
        </CustomAccordion>

    )
}


export default memo(WebsiteAccordion);