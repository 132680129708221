import React, {useEffect, useRef, useState, useCallback} from "react";
import styled from "@emotion/styled";
import {TextField, IconButton} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import SendIcon from "@mui/icons-material/Send";
import createStream from "_services/stream";
import {encodeData} from "utils/formatContent";
import {useDispatch, useSelector} from "react-redux";
import {interactWithUser} from "redux/status";
import {addModel} from "redux/models";
import controlledAPI from "_services/controlledApi";
import {getWordsFromString} from "utils/prepareNames";
import contentSources from "mappings/contentSearchSourceActions.json";
import useDeviceDetect from "hooks/useDetectDevice";
import {getToken} from "_helpers/tokenManagement";
import {addTempMessage, addMessage} from "redux/sharedMessages";
import {brainTickUpdate, setMessageLoading} from "redux/sharedMessages";
import {updateToolCallStatus,cleanStack, addToolCallToStack,popFromToolCallStack} from "redux/sharedMessages";

import styles from './MessageSender.module.css';
import classnames from 'classnames';

const CustomTextField = styled(TextField)`
    & .MuiInputLabel-root {
        ${(props) => (props.isinputfocused === "true" ? "" : "display: none;")}
        transform: translate(16px, 25px);
        width: 90%;
        white-space: pre-wrap;
    }

    & .MuiOutlinedInput-notchedOutline legend {
        ${(props) => (props.isinputfocused === "true" ? "" : "width: 0;")}
    }

    & .MuiInputLabel-shrink {
        transform: translate(16px, -8px) scale(0.75);
        white-space: nowrap;
    }

    & .MuiInputBase-input {
      ${(props) => (props.ismultiline === "true" ? "" : "height: 23px !important;")}
    }

    & .MuiInputBase-input::placeholder {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @media only screen and (max-width: 899px) {
        & .MuiInputLabel-root {
            transform: translate(16px, 14px);
            width: 90%;
            white-space: pre-wrap;
        }

        & .MuiInputLabel-shrink {
            width: 112%;
            transform: translate(16px, -8px) scale(0.75);
        }

        @media only screen (max-width: 600px) and (max-width: 899px) {
        & .MuiInputLabel-root {
            width: 90%;
            white-space: pre-wrap;
            transform: translate(16px, 14px);
        }

        & .MuiInputLabel-shrink {
            white-space: nowrap;
            transform: translate(10px, -8px) scale(0.75);
        }
    }
        @media only screen and (max-width: 599px) {
            & .MuiInputLabel-root {
                width: 90%;
                white-space: pre-wrap;
                transform: translate(16px, 14px);
            }

            & .MuiInputLabel-shrink {
                white-space: nowrap;
                transform: translate(16px, -8px) scale(0.75);
            }
        }
    }
`;
var abortController = new AbortController();

const MessageSender = React.memo(({
                                      info = {},
                                      type, // 'rabbit' or 'shared'
                                      sendSignal = {count: 0, msg: ""},
                                      messages,
                                      hideSideBar,
                                  }) => {
    // const [cachedMsg, setCachedMsg] = useState('');
    const isMsgSending = useSelector(state => state.sharedMessages.session.loading.isMsgSending);
    const selectedModel = useSelector(state => state.models.selectedModel);
    const [searchSource, setSearchSource] = useState("Thinking...");
    const [isInputFocused, setInputFocused] = useState(false);
    const [rabbitChatId, setRabbitChatId] = useState(false);
    const [currentChatId, setCurrentChatId] = useState(info.chat_id);
    const [isMultiline, setIsMultiline] = useState(false);
    const {isMobileDevice} = useDeviceDetect();
    const [localCount, setCount] = useState(sendSignal.count);
    let sourcesTimer = null;
    const dispatch = useDispatch();
    const [inputLength, setInputLength] = useState(0);

    const questionRef = useRef(null);
    const setMessageLoadState = (loading = undefined, stage = undefined) => {

        let params = {
            loading,
            stage
        }
        dispatch(setMessageLoading(params));
    }
    useEffect(() => {
      if (sendSignal.count !== localCount && sendSignal.msg != "") {
          sendMessage(null, sendSignal.msg);
      }

      setCount(sendSignal.count)
  }, [sendSignal.count]);


    const cleanChunkSources = (initial = "") => {
        clearInterval(sourcesTimer);
        setSearchSource(initial)
    }

    const contentSearchAnimation = (sources = []) => {
        sources = Object.values(contentSources);

        let typeIndex = 0,
            maxTypeLength = sources.length;
        clearInterval(sourcesTimer);

        sourcesTimer = setInterval(function () {
            if (maxTypeLength <= typeIndex) {
                clearInterval(sourcesTimer);
                typeIndex = 0;
                return;
            }

            let uiMsg = sources[typeIndex] + '...';

            setSearchSource(uiMsg);

            typeIndex++;
        }, 1000);
    };

    const saveAiResponse = async (msg = "") => {
        let emptyError = false;
        emptyError = msg.trim() == "";

        if (emptyError) {
            return;
        }
        const chatId = rabbitChatId || currentChatId;

        let msgParams = {
            message: encodeData(msg ?? messages),
            type: "response",
            chatId,
        };

        if (!getToken('token')) {
            msgParams.guestId = info.guest_id;
        }

        await controlledAPI({ignoreUser: true})
            .post("message", msgParams)
            .then((r) => {
                if (r) {
                    const {message} = r;
                    dispatch(addMessage({type, message}))
                    dispatch(addTempMessage({type, refresh: true}));
                }
            })
            .catch((err) => {
                dispatch(addTempMessage({type, refresh: true}));
            });
    };

    const handleAbortRequest = () => {
        try {
            abortController.abort();
        } catch (error) {
            console.error("An error occurred while aborting:", error.message);
        }

        saveAiResponse(messages);
        dispatch(addTempMessage({type, refresh: true}));
        setMessageLoadState(false, 1);
        dispatch(cleanStack());
    };

    const sendMessage = async (e, newMessage = null) => {
        setInputFocused(true);

        if (e) {
            e.preventDefault();
        }

        let chatId = rabbitChatId || currentChatId;

        let question = questionRef.current.value,
            ref = questionRef,
            emptyError = false,
            maxWordsAllowed = 0,
            firstChunkArrived = false,
            cachedMsg = '';

        emptyError = newMessage ? newMessage.trim() == "" : question.trim() == "";

        if (emptyError) {
            dispatch(
                interactWithUser({
                    message: "Message is empty",
                    type: "info",
                })
            );
            return;
        }

        maxWordsAllowed = newMessage
            ? newMessage.split(" ").length
            : question.split(" ").length;

        if (maxWordsAllowed > 1500) {
            dispatch(
                interactWithUser({
                    message: "The message is exceeded maximum words allowed of 1500",
                    type: "info",
                })
            );
            return;
        }

        const message = newMessage ?? question;
        let instance =
            typeof message == "object"
                ? message
                : {
                    type: "prompt",
                    message,
                };
        dispatch(addTempMessage({type, refresh: true}));
        dispatch(addMessage({type, message: instance}));
        if (!hideSideBar) {
          contentSearchAnimation();
        }
        setMessageLoadState(true, 3);

        // if (!chatId) {
        //   // setIsNewChat(true);
        //   let name = getWordsFromString(newMessage ?? question, 4);
        //   await controlledAPI({ignoreUser: true})
        //     .post("/chat", {
        //       name: name,
        //       modelId: info.modelId,
        //     })
        //     .then((resp) => {
        //       // dispatch(pushChat(resp.chat));
        //       if (resp?.chat) {
        //         // savedChat = resp.chat;
        //         setCurrentChatId(resp?.chat?.id);
        //         chatId = resp?.chat?.id;
        //       }
        //       // dispatch(setStatus({status: "new"}));
        //     })
        //     .catch((error) => {
        //       // setMsgLoadState(false)
        //       setMessageLoadState(false, 1);
        //     });
        // }

        ref.current.value = "";

        let msgParams = {
            message: encodeData(newMessage ?? question),
            type: "prompt",
            // guestId: info.guest_id,
        };

        if (rabbitChatId) {
            msgParams.chatId = rabbitChatId;
            delete msgParams.modelId;
        };

        if (chatId) {
            msgParams.chatId = chatId;
        }else {
            msgParams.modelId = info.modelId;
        }

        // if (type === 'rabbit' && !rabbitChatId) {
        //     msgParams.modelId = 78;
        // }

        // if (type !== 'rabbit') {
            msgParams.chatId = currentChatId || chatId;
        // }
        if (hideSideBar) {
          msgParams.isProduct = true;
        }

        if (!getToken('token')) {
            msgParams.guestId = info.guest_id;
        }

        cachedMsg = '';
        // setCachedMsg('');
        dispatch(addTempMessage({type, text: ''}));

        const errorCallback = (response = {message: []}) => {
            let messages = response.message;
            let errMsg =
                messages.length > 0 && messages[0] == "guest.chat.over_limit"
                    ? "You reached a maximum of 2 AI interactions. Register to get more. "
                    : "Something went wrong.. Please, try again later..";

            dispatch(
                interactWithUser({
                    message: errMsg,
                    type: "error",
                })
            );

            dispatch(cleanStack());
            cleanChunkSources("Thinking...");
            dispatch(addTempMessage({type, refresh: true}));
            setMessageLoadState(false, 1);
        };

        const bufferCallback = (resp) => {
            if (resp.error) {
                if (resp.data != "") {
                    // setCachedMsg(cachedMsg + resp.data);
                    cachedMsg += resp.data;
                }

                dispatch(cleanStack());
                abortController.abort();
                saveAiResponse(cachedMsg);
                cleanChunkSources("Thinking...");
                dispatch(addTempMessage({type, refresh: true}));
                ref.current.focus();

                return;
            }

            if (resp.data) {
                let data = resp.data;
                // setCachedMsg(cachedMsg + data);
                cachedMsg += resp.data;

                dispatch(addTempMessage({type, text: data}));

                if (!firstChunkArrived && data != "") {
                    firstChunkArrived = true;
                    cleanChunkSources("Typing...");
                }

                if (!resp.response) {
                    return;
                }
            }

            if(resp.tool_call) {

                if(resp.tool_data) {
                    dispatch(popFromToolCallStack(resp.tool_call));
                    return;
                }

                dispatch(addToolCallToStack(resp.tool_call));

                return;
            }

            if (resp.response) {
                const {response} = resp;
                ref.current.value = "";
                cleanChunkSources("");
                setMessageLoadState(false, 1);
                setRabbitChatId(resp.response.chat_id);
                dispatch(cleanStack());
                if (Boolean(selectedModel?.data?.model)) {
                  dispatch(addModel({ model: { ...selectedModel.data.model, access: ['read'] } }))
                }

                if (response?.chat_id) {
                    if (chatId == null) {
                        setCurrentChatId(response.chat_id);
                        chatId = response.chat_id;
                    }
                }     

                const message = {
                    message: response.message,
                    type: response.type,
                };
                if (response.message && response.type) {
                    dispatch(addTempMessage({type, refresh: true}));
                    dispatch(addMessage({type, message}));
                }
                ref.current.disabled = false;
                ref.current.focus();
                return;
            }
        };

        abortController = new AbortController();
        let signal = abortController.signal;

        createStream("/message", bufferCallback, msgParams, signal, errorCallback);
    };

    // useEffect(() => {

    //     if (sendSignal.count !== 0 && sendSignal.msg != "") {
    //         sendMessage(null, sendSignal.msg);
    //     }
    // }, [sendSignal.count]);

    const handleInputFocus = (e) => {
        e.preventDefault();

        if (questionRef.current?.value?.length === 0) {
            setMessageLoadState(undefined, 1)
            return;
        }
        setMessageLoadState(undefined, 2)
    }

    const handleInputBlur = () => {
        setMessageLoadState(undefined, 1)
    }

    const brainTick = (e) => {
        // global for now, later will put it in the same scope with the chat input
        const value = e.target?.value || '';

        if (value?.length === 0) {
            setMessageLoadState(undefined, 1)
            return;
        } else {
            setMessageLoadState(undefined, 2)
        }


        dispatch(brainTickUpdate())

    }

    const handleKeyInput = (e) => {
      const { value } = e.target;
      setInputLength(value.length);
      if (Boolean(value.length)) {
        setIsMultiline(true);
      } else {
        setIsMultiline(false);
      }
      brainTick(e);
    }

    const talkToModelLabel = `Talk to ${info.modelName} model`;
    const placeholder = isMsgSending && searchSource !== "" ? searchSource : talkToModelLabel;


    return (
        <CustomTextField
            id="shared-message-input"
            multiline={true}
            maxRows={5}
            minRows={1}
            disabled={isMsgSending}
            sx={{
                width: "100%",
                // "& .MuiInputBase-input": {
                //     display: '-webkit-box',
                //     WebkitLineClamp: 1,
                //     WebkitBoxOrient: 'vertical',
                //     whiteSpace: 'nowrap',
                // }
            }}
            // label={
            //   isMsgSending && searchSource !== '' ? (
            //     <div className={["dots-loading"]}>{searchSource}</div>
            //   ) : (
            //     `Talk to "${info.modelName}"`
            //   )
            // }
            // isinputfocused={isInputFocused.toString()}
            ismultiline={isMultiline.toString()}
            onChange={handleKeyInput}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder={placeholder}
            variant="outlined"
            onKeyDown={(e) => {
                if (e.key == "Enter" && !isMobileDevice) {
                    if (!e.shiftKey) {
                        sendMessage(e);
                    }
                }
            }}
            fullWidth
            inputRef={questionRef}
            InputLabelProps={{shrink: true}}
            FormHelperTextProps={{ component: 'div' }}
            helperText={
                <div className={styles.helperTextWrapper}>
                    <div>{/* existing helper text */}</div>
                    {inputLength > 0 && (
                        <div className={classnames(
                            styles.symbols,
                            isMsgSending && styles.error,
                            inputLength > 1500 && styles.exceeded
                        )}>
                            {`${inputLength}/1500`}
                        </div>
                    )}
                </div>
            }
            InputProps={{
                style: {paddingRight: "5px"},
                endAdornment: (
                    <IconButton
                        sx={{
                            borderRadius: "5px",
                            alignSelf: "flex-end",
                        }}
                        onClick={(e) => {
                            if (isMsgSending) {
                                handleAbortRequest();
                            } else {
                                sendMessage(e);
                            }
                        }}
                    >
                        {isMsgSending ? (
                            <StopIcon sx={{color: "#5f6368"}}/>
                        ) : (
                            <SendIcon color="primary"/>
                        )}
                    </IconButton>
                ),
            }}
        />
    );
});

export default MessageSender;
